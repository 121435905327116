// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false ,
  firebase_realtime : 'ambiente_testes',
  caminhoRealtimeMotoristas : '/ambiente_testes/cadastros_caminhoneiros/',
  caminhoRealtimeEmpresa : '/ambiente_testes/cadastros_empresas/',
  url_fluig : 'https://portaltst.bialog.com.br',//url_fluig : 'https://frutebtst.fluig.com',
  url_transportador : 'https://transportadorbialog-teste.web.app/',
  url_app_transportador : 'https://bialogfretes.page.link/app/?link=https://transportadorbialog-teste.web.app?%26acao=LOGAR&apn=br.com.bialogfretes',
  firebaseConfig : {
    apiKey: "AIzaSyAN263vZKj87ZvzkpBYy50q1t4Gj2Q9q_E",
    authDomain: "monitoramento-viagem.firebaseapp.com",
    databaseURL: "https://monitoramento-viagem.firebaseio.com",
    projectId: "monitoramento-viagem",
    storageBucket: "monitoramento-viagem.appspot.com",
    messagingSenderId: "520148977608",
    appId: "1:520148977608:web:1e16ab144e96064d0e22fd",
    measurementId: "G-TQ13WHEYSH"
  },
  sufix : '@cadastrobialog.com',
  sufix2 : 'authBia301*092-031',
  TOKEN_sintegraws : '24A7F21C-9E44-4BB4-A084-57AF203E3F6C',
  URL_API_NODE_GOOGLEAPP : 'https://monitoramento-viagem.rj.r.appspot.com',
  URL_WSDL_FLUIG :'https://portaltst.bialog.com.br/webdesk/ECMDatasetService?wsdl',
  URL_WS_PROTHEUS : 'http://bialogtransporte130496.protheus.cloudtotvs.com.br:4050/REST',
  URL_API_BACKEND : 'https://backend-develop.bialog.com.br/v1/',
  URL_PORTAL_CLI : 'https://portal-tomador-develop.bialog.com.br',
  URL_PORTAL_MOT : 'https://transportadorbialog-teste.web.app'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
