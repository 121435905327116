import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.css'],
})

export class IframeDialogComponent  {

  urlForIframe: SafeHtml | undefined;
  descripitonButtonClose : String  = 'Fechar'
  dataPar : any
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<IframeDialogComponent>) {
      if(data){
        this.dataPar = data
      }
  }

  ngOnInit(){
    let dataT = this.dataPar
    this.urlForIframe = this.sanitizer.bypassSecurityTrustResourceUrl( dataT.urlForIframe )
    this.descripitonButtonClose = dataT.descripitonButtonClose
  }


}
