import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '../helpers/config.service';
import { MatIcon } from '@angular/material/icon';
import { environment } from './../../environments/environment';


declare global {
  interface Window {
    portalBialog:any;
    fnVoltarPeloApp: any;
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  isloading = true
  isApp = false
  linkLogin = ""
  linkInicial = ""
  linkCadastroCli = ""
  linkCadastroMot = ""

  constructor(  @Inject(DOCUMENT) private document: any , private router : Router ,private configService : ConfigService ) {
    this.isApp =  this.configService.isAppCall();
    //** Funçao para voltar no botão de voltar APP
    window['fnVoltarPeloApp'] = function(){
      window.location.href = environment.URL_PORTAL_CLI + '/login'
    }

    this.linkLogin = environment.URL_PORTAL_CLI + '/login';
    this.linkInicial = 'https://bialog.com.br/';
    this.linkCadastroCli = environment.URL_PORTAL_CLI + '/cadastro';
    this.linkCadastroMot = environment.URL_PORTAL_MOT + '/signup';
  }

  ngOnInit(): void {  }

  goToLink(url: string){
    window.open(url);
    return false;
  }

  goToHome(url: string){
    this.document.location.href = url
    return false;
  }

  routerAcess(router: any){
    this.isloading = false
    this.router.navigate([router]);
  }

  voltaTelaApp(){
    //window.portalBialog.funcaoAppMinimizaAplicativo();
    this.document.location.href = environment.url_fluig + '/portal/1/wgPortalPublico'
  }


}





