import { IframeDialogComponent } from './iframe-dialog/iframe-dialog/iframe-dialog.component';
import { CaminhoeiroService } from './../services/caminhoeiro.service';
import { Component, OnInit, ViewChild, Inject, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, ValidatorFn, AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar , MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { cnpj,cpf } from 'cpf-cnpj-validator';
import { PipeTransform, Pipe } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from '../helpers/config.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { environment } from './../../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthService } from '../services/auth.service';
import temporario from '../models/temporario';
import { AngularFireDatabase, AngularFireList  } from '@angular/fire/compat/database';


@Component({
  selector: 'app-caminhoneiro',
  templateUrl: './caminhoneiro.component.html',
  styleUrls: ['./caminhoneiro.component.css']
})
export class CaminhoneiroComponent implements OnInit {

  public mask = {
    guide: true,
    showMask : true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/,/\d/, /\d/]
  };

  maskConfig = {
    mask: [
      new RegExp('\\d'),
      new RegExp('\\d'),
      '/',
      new RegExp('\\d'),
      new RegExp('\\d'),
      '/',
      new RegExp('\\d'),
      new RegExp('\\d'),
      new RegExp('\\d'),
      new RegExp('\\d')
    ],
    showMask: false,
    guide: false,
    placeholderChar: '_'
  };

  public innerWidthCam: any;

  matcher = new MyErrorStateMatcher();

  isloading = true;
  loadingCidades = false;
  loadingBuscaCEP = false;
  isOnChanged_cpfCnpj = false;
  isCpf = false;
  isCpfProrprietario = false;
  isDataTempFirebase = false
  retornoMunicip: any;
  retornoPaises: any;
  hiddenFavorecido = true
  isOkFormsReboques = true
  hidePss = true
  hidePss2 = true
  clickTermosLink = false

  ishiddenDadosMotorista = true;

  isCompleted_step_cpfCnjp     = false;
  isCompleted_step_PropVeiculo = false;
  isCompleted_step_Motorista   = false;
  isCompleted_step_Veiculo     = false;
  isCompleted_step_DadosAcesso = false;

  cpf_cnpj_FormGroup: FormGroup | any;
  dadosProprietarioVeiculo_FormGroup : FormGroup | any;
  dadosMotorista_FormGroup: FormGroup | any;
  dadosVeiculo_FormGroup: FormGroup | any;
  dadosAcesso_FormGroup: FormGroup | any;
  aceitePoliticaUso_FormGroup: FormGroup | any;
  isOptional = false;

  cores = new Array;
  marcas = new Array;
  ufEstados = new Array;
  municipioAll = new Array;
  filtradinho = new Array;
  paises = new Array;
  tiposVeiculos = new Array;
  tiposVeiculosSite = new Array;
  carrocerias = new Array;
  origemCads = new Array;


  cidades = new Array;
  cidadesMotorista = new Array;
  cidadesMotoristaCNH = new Array;
  cidadesPlaca= new Array;
  //reboques
  cidadesPlacaReboque1 = new Array;
  cidadesPlacaReboque2 = new Array;
  cidadesPlacaReboque3 = new Array;
  cidadesProprietarioReboque1 = new Array;
  cidadesProprietarioReboque2 = new Array;
  cidadesProprietarioReboque3 = new Array;

  tipoDoctoSL: string = '';
  toHighlight: string = '';
  toHighlightMotorista: string = '';
  toHighlightMotoristaCNH: string = '';
  toHighlightPlaca: string = '';
  toHighlightNomeFantasia: string = '';
  //reboques
  toHighlightPlacaReboque1 : string = '';
  toHighlightPlacaReboque2 : string = '';
  toHighlightPlacaReboque3 : string = '';
  toHighlightProprietarioReboque1 : string = '';
  toHighlightProprietarioReboque2 : string = '';
  toHighlightProprietarioReboque3 : string = '';

  filteredCidades: Observable<any[]>| any;
  filteredCidadesMotorista: Observable<any[]>| any;
  filteredCidadesMotoristaCNH: Observable<any[]>| any;
  filteredCidadesPlaca: Observable<any[]>| any;
  filteredCidadesPlacaReboque1: Observable<any[]>| any;
  filteredCidadesPlacaReboque2: Observable<any[]>| any;
  filteredCidadesPlacaReboque3: Observable<any[]>| any;
  filteredCidadesProprietarioReboque1: Observable<any[]>| any;
  filteredCidadesProprietarioReboque2: Observable<any[]>| any;
  filteredCidadesProprietarioReboque3: Observable<any[]>| any;

  isLoginCriado = false

  //*  dados para logica de reboque */
  isExistReboque1 = false
  isExistReboque2 = false
  isExistReboque3 = false
  isTerceiroReboque1 = false
  isTerceiroReboque2 = false
  isTerceiroReboque3 = false

  loadingValidRNTRC = false;
  isValid_ANTT_Proprietario = true
  isValid_ANTT_Proprietario_reboque1 = true
  isValid_ANTT_Proprietario_reboque2 = true
  isValid_ANTT_Proprietario_reboque3 = true

  loadingValidPAMCARD = false;
  isValid_PAMCARD = true
  isCodDoctoFluig = false;

  isApp

  isTipoReboques1 = false
  isTipoReboques2 = false
  isTipoReboques3 = false

  noTemporario: any;
  retornoTemporario: any;
  public temporarioObjeto: any;
  key: any;
  lfirst = true

  @ViewChild('stepper' ,  { static: false } ) stepper: MatStepper| any;
  @ViewChild('Reboques' ,  { static: false }) Reboques : any;

  constructor(   @Inject(DOCUMENT) private document: any ,
                private _formBuilder: FormBuilder ,
                private caminhoeiroService : CaminhoeiroService ,
                public snackBar: MatSnackBar ,
                private router : Router,
                private dialog: MatDialog,
                private configService : ConfigService,
                private aut: AngularFireAuthModule,
                private authService: AuthService,
                private firebaseRealtime: AngularFireDatabase,
                 ) {
    //Pega Estados

    this.ufEstados = caminhoeiroService.getEstados();
    this.origemCads = caminhoeiroService.getOrigemCad();

    this.isApp =  this.configService.isAppCall();
    //** Funçao para voltar no botão de voltar APP
    window['fnVoltarPeloApp'] = function(){
      //this.router.navigate(['inicio']);
      window.location.href = 'https://'+ window.location.host  + '/inicio'
    }


  }

  //*** ngOnInit ****
  ngOnInit() {


    this.innerWidthCam = window.innerWidth;

    this.loading('show')

    this.cpf_cnpj_FormGroup = new FormGroup({
      cpfOrCpnj: new FormControl(null, {
        validators: [Validators.required , Validators.maxLength(14) ]
      }),
      nome_RazaoSocial: new FormControl(null, {
        validators: [],
      }),
      tipo_cadastro: new FormControl('proprietarioEmotorista', {
        validators: [Validators.required ],
      }),
      dddcontato: new FormControl(null, {
        validators: [Validators.required ],
      }),
      telefonecontato: new FormControl(null, {
        validators: [Validators.required ],
      }),
      placa_veiculo: new FormControl(null, {
        validators: [Validators.required ],
      }),
      antt_veiculo: new FormControl(null, {
        validators: [Validators.required ],
      }),
      tipo_DadosVeiculo: new FormControl(null, {
        validators: [Validators.required ],
      }),
      placa_reboque1: new FormControl(null, {
        validators: [],
      }),
      placa_reboque2: new FormControl(null, {
        validators: [],
      }),
      placa_reboque3: new FormControl(null, {
        validators: [],
      }),
    });

    this.dadosProprietarioVeiculo_FormGroup = this._formBuilder.group({
      cpfOrCpnj_ProprietarioVeiculo : new FormControl({value: null, disabled: false}, {
        validators: [ Validators.required ],
      }),
      ie_ProprietarioVeiculo : new FormControl(null, {
        validators: []
      }),
      nome_RazaoSocial_ProprietarioVeiculo : new FormControl(null, {
        validators: [Validators.required ]
      }),
      nome_NomeFantasia_ProprietarioVeiculo : new FormControl(null, {
        validators: []
      }),
      cep_ProprietarioVeiculo : new FormControl(null, {
        validators: [Validators.required]
      }),
      endereco_ProprietarioVeiculo : new FormControl(null, {
        validators: [Validators.required]
      }),
      bairro_ProprietarioVeiculo : new FormControl(null, {
        validators: [Validators.required]
      }),
      UF_ProprietarioVeiculo : new FormControl(null, {
        validators: [Validators.required]
      }),
      cidade_ProprietarioVeiculo : new FormControl(null, {
        validators: [this.forbiddenNamesValidator()]
      }),
      pais_ProprietarioVeiculo : new FormControl('Brasil', {
        validators: []
      }),
      email_ProprietarioVeiculo : new FormControl('bialog.monitoramento@gmail.com', {
        validators: []
      }),
      DDI_ProprietarioVeiculo : new FormControl('55', {
        validators: []
      }),
      DDD_ProprietarioVeiculo : new FormControl(null, {
        validators: [Validators.required]
      }),
      telefone_ProprietarioVeiculo : new FormControl(null, {
        validators: [Validators.required]
      }),
      RNTRC_ProprietarioVeiculo : new FormControl(null, {
        validators: []
      }),
      PIS_ProprietarioVeiculo : new FormControl(null, {
        validators: []
      }),
      IMGCNH_ProprietarioVeiculo : new FormControl("", {
        validators: []
      })
    });

    this.dadosMotorista_FormGroup = this._formBuilder.group({
      cpfOrCpnj_DadosMotorista : new FormControl({value: null, disabled: false }, {
        validators: [ Validators.required ],
      }),
      ie_DadosMotorista : new FormControl({value: 'ISENTO', disabled: true }, {
        validators: []
      }),
      nome_RazaoSocial_DadosMotorista : new FormControl(null, {
        validators: []
      }),
      nome_NomeFantasia_DadosMotorista : new FormControl(null, {
        validators: []
      }),
      dataNascimento_DadosMotorista : new FormControl(null, {
        validators: [Validators.required ]
      }),
      estadoCivil_DadosMotorista : new FormControl(null, {
        validators: []
      }),
      RG_DadosMotorista : new FormControl(null, {
        validators: []
      }),
      emissor_RG_DadosMotorista : new FormControl(null, {
        validators: []
      }),
      UF_RG_DadosMotorista : new FormControl(null, {
        validators: []
      }),
      data_emissao_RG_DadosMotorista : new FormControl(null, {
        validators: []
      }),
      nomePai_DadosMotorista : new FormControl(null, {
        validators: []
      }),
      nomeMae_DadosMotorista : new FormControl(null, {
        validators: []
      }),
      cep_DadosMotorista : new FormControl(null, {
        validators: [Validators.required]
      }),
      endereco_DadosMotorista : new FormControl(null, {
        validators: [Validators.required]
      }),
      bairro_DadosMotorista : new FormControl(null, {
        validators: [Validators.required]
      }),
      UF_DadosMotorista : new FormControl(null, {
        validators: [Validators.required]
      }),
      cidade_DadosMotorista : new FormControl(null, {
        validators: [this.forbiddenNamesMotoristaValidator()]
      }),
      cargaPerigosa_DadosMotorista : new FormControl('2', {
        validators: []
      }),
      pais_DadosMotorista : new FormControl('Brasil', {
        validators: [Validators.required]
      }),
      email_DadosMotorista : new FormControl('bialog.monitoramento@gmail.com', {
        validators: []
      }),
      DDI_DadosMotorista : new FormControl('55', {
        validators: [Validators.required]
      }),
      DDD_DadosMotorista : new FormControl(null, {
        validators: [Validators.required]
      }),
      telefone_DadosMotorista : new FormControl(null, {
        validators: [Validators.required]
      }),
      numero_PAMCARD_DadosMotorista : new FormControl(null, {
        validators: []
      }),
      IMGCNH_DadosMotorista : new FormControl("", {
        validators: [Validators.required]
      })
    });

    this.dadosVeiculo_FormGroup = this._formBuilder.group({
      placa_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      tipo_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      IMGCRLV_DadosVeiculo : new FormControl("", {
        validators: [Validators.required]
      }),
      carroceria_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      pagFavorecido_DadosVeiculo : new FormControl(false, {
        validators: []
      }),
      capacidade_nominal_DadosVeiculo : new FormControl(null, {
        validators: []
      }),

      //************ Dados - reboque 1 *************/
      reboque1_DadosVeiculo : new FormControl(false, {
        validators: []
      }),
      reboque1_Propriedade_DadosVeiculo : new FormControl('Proprio', {
        validators: []
      }),
      reboque1_Proprietario_CpfCnpj_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_Proprietario_ie_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_Proprietario_razaosocial_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_Proprietario_nomefantasia_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_Proprietario_cep_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_Proprietario_endereco_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_Proprietario_bairro_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_Proprietario_estado_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_Proprietario_cidade_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_Proprietario_pais_DadosVeiculo : new FormControl('Brasil', {
        validators: []
      }),
      reboque1_Proprietario_email_DadosVeiculo : new FormControl('bialog.monitoramento@gmail.com', {
        validators: []
      }),
      reboque1_Proprietario_DDI_DadosVeiculo : new FormControl('55', {
        validators: []
      }),
      reboque1_Proprietario_DDD_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_Proprietario_telefone_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_Proprietario_RNTRC_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_placa_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque1_tipoVeiculo_DadosVeiculo : new FormControl("SR", {
        validators: []
      }),
      reboque1_IMGCRLV_DadosVeiculo : new FormControl("", {
        validators: []
      }),
      carrocReboque1_DadosVeiculo : new FormControl("", {
        validators: []
      }),
      reboque1_capacidade_nominal_DadosVeiculo : new FormControl(null, {
        validators: []
      }),/*

      //************ Dados - reboque 2 *************/
      reboque2_DadosVeiculo : new FormControl(false, {
        validators: []
      }),
      reboque2_Propriedade_DadosVeiculo : new FormControl('Proprio', {
        validators: []
      }),
      reboque2_Proprietario_CpfCnpj_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_Proprietario_ie_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_Proprietario_razaosocial_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_Proprietario_nomefantasia_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_Proprietario_cep_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_Proprietario_endereco_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_Proprietario_bairro_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_Proprietario_estado_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_Proprietario_cidade_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_Proprietario_pais_DadosVeiculo : new FormControl('Brasil', {
        validators: []
      }),
      reboque2_Proprietario_email_DadosVeiculo : new FormControl('bialog.monitoramento@gmail.com', {
        validators: []
      }),
      reboque2_Proprietario_DDI_DadosVeiculo : new FormControl('55', {
        validators: []
      }),
      reboque2_Proprietario_RNTRC_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_Proprietario_DDD_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_Proprietario_telefone_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_placa_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque2_tipoVeiculo_DadosVeiculo : new FormControl("SR", {
        validators: []
      }),
      reboque2_IMGCRLV_DadosVeiculo : new FormControl("", {
        validators: []
      }),
      carrocReboque2_DadosVeiculo : new FormControl("", {
        validators: []
      }),
      reboque2_capacidade_nominal_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      //************ Dados - reboque 3 *************/
      reboque3_DadosVeiculo : new FormControl(false, {
        validators: []
      }),
      reboque3_Propriedade_DadosVeiculo : new FormControl('Proprio', {
        validators: []
      }),
      reboque3_Proprietario_CpfCnpj_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_Proprietario_ie_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_Proprietario_razaosocial_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_Proprietario_nomefantasia_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_Proprietario_cep_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_Proprietario_endereco_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_Proprietario_bairro_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_Proprietario_estado_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_Proprietario_cidade_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_Proprietario_pais_DadosVeiculo : new FormControl('Brasil', {
        validators: []
      }),
      reboque3_Proprietario_email_DadosVeiculo : new FormControl('bialog.monitoramento@gmail.com', {
        validators: []
      }),
      reboque3_Proprietario_DDI_DadosVeiculo : new FormControl('55', {
        validators: []
      }),
      reboque3_Proprietario_DDD_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_Proprietario_telefone_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_Proprietario_RNTRC_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_placa_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      reboque3_tipoVeiculo_DadosVeiculo : new FormControl("SR", {
        validators: []
      }),
      reboque3_IMGCRLV_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
      carrocReboque3_DadosVeiculo : new FormControl("", {
        validators: []
      }),
      reboque3_capacidade_nominal_DadosVeiculo : new FormControl(null, {
        validators: []
      }),
    });

    this.dadosAcesso_FormGroup = this._formBuilder.group({
      passoword_DadosAcesso : new FormControl(null, {
        validators: [Validators.required]
      }),
      confirm_passoword_DadosAcesso : new FormControl(null),
    }, { validator: this.checkPasswords });

    this.aceitePoliticaUso_FormGroup = this._formBuilder.group({
      aceitePoliticaUso : new FormControl(null, {
        validators: [Validators.required]
      }),
      aceiteNotificacaoMensagem : new FormControl(null, {
        validators: [Validators.required]
      }),
      data_aceitePoliticaUso : new FormControl(null, {
        validators: []
      }),
      data_aceiteNotificacaoMensagem : new FormControl(null, {
        validators: []
      }),
      origemCadastro : new FormControl(null, {
        validators: [Validators.required]
      }),
    });

    this.filteredCidades = this.dadosProprietarioVeiculo_FormGroup.get('cidade_ProprietarioVeiculo').valueChanges
    .pipe(
      startWith(''),
      map((cidades: { name: string; }) => cidades ? this.filterCidades(cidades.name) : this.cidades.slice())
    );

    this.filteredCidadesMotorista = this.dadosMotorista_FormGroup.get('cidade_DadosMotorista').valueChanges
    .pipe(
      startWith(''),
      map( (cidadesMotorista: { name: string; }) => cidadesMotorista ? this.filterCidadesMotorista(cidadesMotorista.name) : this.cidadesMotorista.slice())
    );


    this.filteredCidadesProprietarioReboque1 = this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_cidade_DadosVeiculo').valueChanges
    .pipe(
      startWith(''),
      map( (cidadesProprietarioReboque1: { name: string; }) => cidadesProprietarioReboque1 ? this.filterCidadesProprietarioReboque1(cidadesProprietarioReboque1.name) : this.cidadesProprietarioReboque1.slice())
    );

    this.filteredCidadesProprietarioReboque2 = this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_cidade_DadosVeiculo').valueChanges
    .pipe(
      startWith(''),
      map( (cidadesProprietarioReboque2: { name: string; }) => cidadesProprietarioReboque2 ? this.filterCidadesProprietarioReboque2(cidadesProprietarioReboque2.name) : this.cidadesProprietarioReboque2.slice())
    );

    this.filteredCidadesProprietarioReboque3 = this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_cidade_DadosVeiculo').valueChanges
    .pipe(
      startWith(''),
      map( (cidadesProprietarioReboque3: { name: string; }) => cidadesProprietarioReboque3 ? this.filterCidadesProprietarioReboque3(cidadesProprietarioReboque3.name) : this.cidadesProprietarioReboque3.slice())
    );

    this.loading('hide')


  }
  //*** FIM - ngOnInit ****

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group?.get('passoword_DadosAcesso')?.value;
    let confirmPass = group?.get('confirm_passoword_DadosAcesso')?.value;

    return pass === confirmPass ? null : { notSame: true }
  }


  filterCidades(name: string) {
    this.toHighlight = name;
    return this.cidades.filter(cidades =>
      cidades.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  filterCidadesMotorista(name: string) {
    this.toHighlightMotorista = name;
    return this.cidadesMotorista.filter(cidadesMotorista =>
      cidadesMotorista.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  filterCidadesMotoristaCNH(name: string) {
    this.toHighlightMotoristaCNH = name;
    return this.cidadesMotoristaCNH.filter(cidadesMotoristaCNH =>
      cidadesMotoristaCNH.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  filterCidadesPlaca(name: string) {
    this.toHighlightPlaca = name;
    return this.cidadesPlaca.filter(cidadesPlaca =>
      cidadesPlaca.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  filterCidadesPlacaReboque1(name: string) {
    this.toHighlightPlacaReboque1 = name;
    return this.cidadesPlacaReboque1.filter(cidadesPlacaReboque1 =>
      cidadesPlacaReboque1.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  filterCidadesPlacaReboque2(name: string) {
    this.toHighlightPlacaReboque2 = name;
    return this.cidadesPlacaReboque2.filter(cidadesPlacaReboque2 =>
      cidadesPlacaReboque2.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  filterCidadesPlacaReboque3(name: string) {
    this.toHighlightPlacaReboque3 = name;
    return this.cidadesPlacaReboque3.filter(cidadesPlacaReboque3 =>
      cidadesPlacaReboque3.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  filterCidadesProprietarioReboque1(name: string) {
    this.toHighlightProprietarioReboque1 = name;
    return this.cidadesProprietarioReboque1.filter(cidadesProprietarioReboque1 =>
      cidadesProprietarioReboque1.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  filterCidadesProprietarioReboque2(name: string) {
    this.toHighlightProprietarioReboque2 = name;
    return this.cidadesProprietarioReboque2.filter(cidadesProprietarioReboque2 =>
      cidadesProprietarioReboque2.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  filterCidadesProprietarioReboque3(name: string) {
    this.toHighlightProprietarioReboque3 = name;
    return this.cidadesProprietarioReboque3.filter(cidadesProprietarioReboque3 =>
      cidadesProprietarioReboque3.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }


  async onChangeCPF_CNPJ(){

      let datasetReturn
      let datasetReturnC
      let cpfcnpj = this.cpf_cnpj_FormGroup.get("cpfOrCpnj").value


      //this.loadCores()
      //this.loadMarcas();
      //this.loadCarroceria();

      // *** Nota: Efetuado alteração para logica de promise e await
      // pois devido a latencia de tempo da API, o mesmo se perdia
      // no tempo e a logica de iniciação de forms era afetada

      this.caminhoeiroService.getConsultCPFCNPJ(cpfcnpj).
        subscribe(async ( response : any )=> {
          datasetReturn = response
          if( datasetReturn.status === "success" ){

            if( datasetReturn.retorno.values === undefined ){

              //se tem login prossegue
              if(this.isLoginCriado){
                console.log('Login OK: '+this.isLoginCriado);
              }

              //Alimento a variavel que direciona o caminho onde salvará
              this.configService.dbPath = environment.caminhoRealtimeMotoristas;
              this.configService.nofirebaseRef = this.firebaseRealtime.list(environment.caminhoRealtimeMotoristas);

              //VERIFICO SE TEM DADOS NA BASE TEMPORARIA E PREENCHO OS CAMPOS
              this.configService.getUnico().snapshotChanges().pipe(
                map(( changes : any) =>
                  changes.map((c: { payload: { key: any; val: () => any; }; }) =>
                    ({ key: c.payload.key, ...c.payload.val() })
                  )
                )
              ).subscribe((data: any) => {

                this.retornoTemporario = data;

                if(this.retornoTemporario.length > 0){

                  for (let index1 = 0; index1 < this.retornoTemporario.length ; index1++) {

                    if (this.retornoTemporario[index1].key == cpfcnpj){

                        this.isOnChanged_cpfCnpj = true;
                        this.isDataTempFirebase = true;

                        if(  cpfcnpj.length === 11 ){
                          this.isCpf = true;
                        }else if( cpfcnpj.length === 14){
                          this.isCpf = false;
                        }

                        this.isCpfProrprietario = true;

                        //Valido reboques
                        const tipoValue  = this.retornoTemporario[index1].tipo_DadosVeiculo;
                        if ( tipoValue != null ){
                          this.cpf_cnpj_FormGroup.get('tipo_DadosVeiculo').setValue(this.retornoTemporario[index1].tipo_DadosVeiculo);
                          let index = this.tiposVeiculos.findIndex(codigo => {
                            return (new RegExp('\^' + codigo.codigo + '\$')).test(tipoValue);
                          });

                          if(index > -1){
                            if(this.tiposVeiculos[index].categoria > '0'){

                              this.onChangeTipoVeiculo();
                          }
                          }else{
                            this.isTipoReboques1 = false;
                            this.isTipoReboques2 = false;
                            this.isTipoReboques3 = false;

                            this.cpf_cnpj_FormGroup.get('placa_reboque1').setValidators([]);
                            this.cpf_cnpj_FormGroup.get('placa_reboque2').setValidators([]);
                            this.cpf_cnpj_FormGroup.get('placa_reboque3').setValidators([]);
                          }
                        }
                        this.isCompleted_step_cpfCnjp  = true;

                        //COMECO A PREENCHER OS DADOS NA TELA
                        this.cpf_cnpj_FormGroup.get('nome_RazaoSocial').setValue(this.retornoTemporario[index1].nome_RazaoSocial);
                        this.cpf_cnpj_FormGroup.get('tipo_cadastro').setValue(this.retornoTemporario[index1].tipo_cadastro);
                        this.cpf_cnpj_FormGroup.get('dddcontato').setValue(this.retornoTemporario[index1].dddcontato);
                        this.cpf_cnpj_FormGroup.get('telefonecontato').setValue(this.retornoTemporario[index1].telefonecontato);
                        this.cpf_cnpj_FormGroup.get('placa_veiculo').setValue(this.retornoTemporario[index1].placa_veiculo);
                        this.cpf_cnpj_FormGroup.get('antt_veiculo').setValue(this.retornoTemporario[index1].antt_veiculo);
                        this.cpf_cnpj_FormGroup.get('tipo_DadosVeiculo').setValue(this.retornoTemporario[index1].tipo_DadosVeiculo);
                        this.cpf_cnpj_FormGroup.get('placa_reboque1').setValue(this.retornoTemporario[index1].placa_reboque1);
                        this.cpf_cnpj_FormGroup.get('placa_reboque2').setValue(this.retornoTemporario[index1].placa_reboque2);
                        this.cpf_cnpj_FormGroup.get('placa_reboque3').setValue(this.retornoTemporario[index1].placa_reboque3);
                        this.dadosAcesso_FormGroup.get("passoword_DadosAcesso").setValue(this.retornoTemporario[index1].passoword_DadosAcesso);
                        this.dadosAcesso_FormGroup.get("confirm_passoword_DadosAcesso").setValue(this.retornoTemporario[index1].confirm_passoword_DadosAcesso);
                        this.aceitePoliticaUso_FormGroup.get("aceitePoliticaUso").setValue(this.retornoTemporario[index1].aceitePoliticaUso);
                        this.aceitePoliticaUso_FormGroup.get("aceiteNotificacaoMensagem").setValue(this.retornoTemporario[index1].aceiteNotificacaoMensagem);
                        this.aceitePoliticaUso_FormGroup.get("data_aceitePoliticaUso").setValue(this.retornoTemporario[index1].data_aceitePoliticaUso);
                        this.aceitePoliticaUso_FormGroup.get("data_aceiteNotificacaoMensagem").setValue(this.retornoTemporario[index1].data_aceiteNotificacaoMensagem);
                        this.aceitePoliticaUso_FormGroup.get("origemCadastro").setValue(this.retornoTemporario[index1].origemCadastro);

                        //this.aceitePoliticaUso_FormGroup.get("aceitePoliticaUso").value  ? this.clickTermosLink = true : this.clickTermosLink = false

                        //if (this.lfirst){
                        //  this.openSnackBar('Vimos que você possui um cadastro em andamento...' , 'x' ,  'msgSucesso' )
                        //  this.lfirst = false;
                        //}

                        this.stepper.linear = false;
                        this.stepper.selectedIndex = this.retornoTemporario[index1].stepperNextIndex;
                        this.stepper.linear = true;
                    }
                  }
                }else{ //SE NÃO TIVER EM BASE DE DADOS FAZ O FLUXO NORMAL

                  if(  cpfcnpj.length === 11 ){
                    this.isOnChanged_cpfCnpj = true;

                    this.isCpf = true;
                  }else if( cpfcnpj.length === 14){
                    this.isOnChanged_cpfCnpj = true;
                    //this.cpf_cnpj_FormGroup.get("data_nascimento").setValue(new Date("2999-03-25"));

                    this.isCpf = false;
                  }

                }

                this.loading('hide')

              }, (erro: any) => {
                console.log('Erro carregar getformsfirebase ', erro)
                this.isOnChanged_cpfCnpj = false;
                this.openSnackBar('Ops! Tivemos problemas ao resgatar formulário salvo em nossa base de dados temporária... ' + erro , 'x' ,  'msgErro' )
                this.loading('hide')
              });

             // }else{
             //   this.loading('hide');
             //   console.log('Não busca no firebase pois nao tem login');
             // }

            }else{
              if(datasetReturn.retorno.values.value[0].$value !== 'ERRO'){
                this.isCpf = false;
                this.isOnChanged_cpfCnpj = false;

                this.caminhoeiroService.getConsultZLO(cpfcnpj).
                subscribe(( responseC : any)=> {
                    datasetReturnC = responseC
                    if( datasetReturnC.status === "success" ){
                      if( datasetReturnC.retorno.values === undefined ){
                        this.openSnackBar('O seu cadastro está sendo analisado pela nossa equipe, favor aguardar o contato pela Bialog.', 'x' ,  'msgAlerta' )
                        this.loading('hide')
                        this.cpf_cnpj_FormGroup.get("cpfOrCpnj").setValue("");
                      }else{
                        this.openSnackBar('Você já é cadastrado e será direcionado para tela de login...', 'x' ,  'msgErro' )
                        this.loading('hide')
                        setTimeout(() => {
                          this.document.location.href = environment.url_transportador
                        }, 3000);
                      }
                    }
                });
              }else{
                console.log('Erro carregar getConsultCPFCNPJ ', datasetReturn.retorno.values.value[1].$value)
                this.isOnChanged_cpfCnpj = false;
                this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + datasetReturn.retorno.values.value[1].$value , 'x' ,  'msgErro' )
                this.loading('hide')
              }

            }

          }else{
            console.log('Erro carregar getConsultCPFCNPJ'  , datasetReturn.retorno )
            this.isOnChanged_cpfCnpj = false;
            this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ', 'x' ,  'msgErro' )
            this.loading('hide')
          }

        }, (erro) => {
          console.log('Erro carregar getConsultCPFCNPJ ', erro)
          this.isOnChanged_cpfCnpj = false;
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
          this.loading('hide')
        });



  }

  async signInWithEmail(login: string,pwd: string) {

    let cpfcnpj2 = this.cpf_cnpj_FormGroup.get("cpfOrCpnj").value
    let loginTemp = this.removeCaracCPFandCNJP(cpfcnpj2)+environment.sufix;
    let psTemp = this.removeCaracCPFandCNJP(cpfcnpj2)+environment.sufix2;

    if (login == ''){
      login = loginTemp;
      pwd = psTemp;
    }

    this.loading('show');

    await this.loadTipoRodado();

    this.authService.signInRegular(login, pwd)
       .then((res) => {


          this.isLoginCriado = true;
          console.log('Login Firebase');

          this.onChangeCPF_CNPJ();
          return res;
       })
       .catch((err) => {

        console.log('No login firebase: '+err.message);
        this.signUpWithEmail(login, pwd)

        this.isLoginCriado = false;

      });
  }

  async signUpWithEmail(login: string,pwd: string){

    this.authService.signUp(login,pwd)
      .then((res) => {
        this.isLoginCriado = true;
        console.log('Logou com cadastro');
        this.onChangeCPF_CNPJ();

        return res;
      })
      .catch((err) => {

        this.isLoginCriado = false;
        console.log('Algo deu errado:', err.message);

      });
  }

  onChangeCPF_Data_Nascimento_Motorista(){

    this.loading('show')

    let datasetReturn
    let cpfMot = this.dadosMotorista_FormGroup.get("cpfOrCpnj_DadosMotorista").value
    let dtNascimento = new Date( this.dadosMotorista_FormGroup.get("dataNascimento_DadosMotorista").value )

    if (cpf.isValid(cpfMot)){

      if( ( this.dadosMotorista_FormGroup.get("cpfOrCpnj_DadosMotorista").valid  && this.dadosMotorista_FormGroup.get("dataNascimento_DadosMotorista").valid  )  ){
        this.caminhoeiroService.getConsultCPFCNPJ(cpfMot).
        subscribe(( response : any) => {
          datasetReturn = response
          if( datasetReturn.status === "success" ){

            if( datasetReturn.retorno.values === undefined ){

              var dd = dtNascimento.getDate();
              var mm = dtNascimento.getMonth() + 1;
              var yyyy = dtNascimento.getFullYear();
              if (dd < 10) {
                dd = parseFloat( '0' + dd ) ;
              }
              if (mm < 10) {
                mm = parseFloat( '0' + mm ) ;
              }
              let brDataNascimento = dd + '/' + mm + '/' + yyyy;

              this.loading('hide')
            }else{
              if(datasetReturn.retorno.values.value[0].$value !== 'ERRO'){
                this.ishiddenDadosMotorista = true;
                this.openSnackBar('Vimos em nossa base de dados, que já existe cadastro com CPF Informado', 'x' ,  'msgErro' )
                this.loading('hide')
              }else{
                console.log('Erro carregar getConsultCPFCNPJ ', datasetReturn.retorno.values.value[1].$value)
                this.ishiddenDadosMotorista = true;
                this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + datasetReturn.retorno.values.value[1].$value , 'x' ,  'msgErro' )
                this.loading('hide')
              }

            }

          }else{
            console.log('Erro carregar getConsultCPFCNPJ'  , datasetReturn.retorno )
            this.ishiddenDadosMotorista = true;
            //this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ', 'x' ,  'msgErro' )
            this.loading('hide')
          }

        }, (erro) => {
          console.log('Erro carregar getConsultCPFCNPJ ', erro)
          this.ishiddenDadosMotorista = true;
          //this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
          this.loading('hide')
        });

      }else{
        this.loading('hide')
      }
    }else{
      console.log('CPF não é valido ')
      this.loading('hide')
      this.openSnackBar('Ops! CPF digitado não é valido... ' , 'x' ,  'msgErro' )
      this.dadosMotorista_FormGroup.get("cpfOrCpnj_DadosMotorista").setValue("")
    }

  }


  onChangeEstado( Municip : any ){

    this.dadosProprietarioVeiculo_FormGroup.get("cidade_ProprietarioVeiculo").setValue(null);
    let UF = this.dadosProprietarioVeiculo_FormGroup.get("UF_ProprietarioVeiculo").value
    this.loadingCidades = true
    this.cidades = []

    this.caminhoeiroService.getMunicipios(UF).
      subscribe(( response) => {
        this.loadingCidades = false
        this.retornoMunicip = response
        if(  this.retornoMunicip.status === "error"){
          console.log('Erro carregar getMunicipios ',  this.retornoMunicip.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + this.retornoMunicip.msg , 'x' ,  'msgErro' )
          return
        }

        if(this.retornoMunicip.retorno.values.length === undefined ){
          let objTemp = {
                          "estado" : this.retornoMunicip.retorno.values.value[1].$value ,//estado
                          "name": this.retornoMunicip.retorno.values.value[0].$value ,//nome cidade
                          "codigo": this.retornoMunicip.retorno.values.value[2].$value //cod munici
                        }
          this.cidades.push(objTemp)
        }else{
          for (let index = 0; index < this.retornoMunicip.retorno.values.length; index++) {
            let objTemp = {
                            "estado" : this.retornoMunicip.retorno.values[index].value[1].$value ,//estado
                            "name": this.retornoMunicip.retorno.values[index].value[0].$value ,//nome cidade
                            "codigo": this.retornoMunicip.retorno.values[index].value[2].$value //cod munici
                          }
            this.cidades.push(objTemp)
          }
        }

        if( Municip != undefined){
          this.dadosProprietarioVeiculo_FormGroup.get("cidade_ProprietarioVeiculo").setValue( Municip  )
        }

    }, (erro) => {
      console.log('Erro carregar getMunicipios ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingCidades = false
    });

  }

  onChangeEstadoMotorista( Municip : any ){

    this.dadosMotorista_FormGroup.get("cidade_DadosMotorista").setValue(null);
    let UF = this.dadosMotorista_FormGroup.get("UF_DadosMotorista").value
    this.loadingCidades = true
    this.cidadesMotorista = []

    this.caminhoeiroService.getMunicipios(UF).
      subscribe(( response : any ) => {
        this.loadingCidades = false
        if( response.status === "error"){
          console.log('Erro carregar getMunicipios ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }

        if(response.retorno.values.length === undefined ){
          let objTemp = {
                          "estado" : response.retorno.values.value[1].$value ,//estado
                          "name": response.retorno.values.value[0].$value ,//nome cidade
                          "codigo": response.retorno.values.value[2].$value //cod munici
                        }
          this.cidadesMotorista.push(objTemp)
        }else{
          for (let index = 0; index < response.retorno.values.length; index++) {
            let objTemp = {
                            "estado" : response.retorno.values[index].value[1].$value ,//estado
                            "name": response.retorno.values[index].value[0].$value ,//nome cidade
                            "codigo": response.retorno.values[index].value[2].$value //cod munici
                          }
            this.cidadesMotorista.push(objTemp)
          }
        }

        if( Municip != undefined){
          this.dadosMotorista_FormGroup.get("cidade_DadosMotorista").setValue( Municip  )
        }

    }, (erro) => {
      console.log('Erro carregar getMunicipios ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingCidades = false
    });

  }

  onChangeEstadoMotoristaCNH( Municip : any ){

    this.dadosMotorista_FormGroup.get("municipio_CNH_DadosMotorista").setValue(null);
    let UF = this.dadosMotorista_FormGroup.get("UF_CNH_DadosMotorista").value
    this.loadingCidades = true
    this.cidadesMotoristaCNH = []

    this.caminhoeiroService.getMunicipios(UF).
      subscribe(( response : any ) => {
        this.loadingCidades = false
        if( response.status === "error"){
          console.log('Erro carregar getMunicipios ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }

        if(response.retorno.values.length === undefined ){
          let objTemp = {
                          "estado" : response.retorno.values.value[1].$value ,//estado
                          "name": response.retorno.values.value[0].$value ,//nome cidade
                          "codigo": response.retorno.values.value[2].$value //cod munici
                        }
          this.cidadesMotoristaCNH.push(objTemp)
        }else{
          for (let index = 0; index < response.retorno.values.length; index++) {
            let objTemp = {
                            "estado" : response.retorno.values[index].value[1].$value ,//estado
                            "name": response.retorno.values[index].value[0].$value ,//nome cidade
                            "codigo": response.retorno.values[index].value[2].$value //cod munici
                          }
            this.cidadesMotoristaCNH.push(objTemp)
          }
        }

        if( Municip != undefined){
          this.dadosMotorista_FormGroup.get("municipio_CNH_DadosMotorista").setValue( Municip  )
        }

    }, (erro) => {
      console.log('Erro carregar getMunicipios ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingCidades = false
    });

  }

  onChangeEstadoPlaca( Municip : any ){

    this.dadosVeiculo_FormGroup.get("municipioPlaca_DadosVeiculo").setValue(null);
    let UF = this.dadosVeiculo_FormGroup.get("UFPlaca_DadosVeiculo").value
    this.loadingCidades = true
    this.cidadesPlaca = []

    this.caminhoeiroService.getMunicipios(UF).
      subscribe(( response : any ) => {
        this.loadingCidades = false
        if( response.status === "error"){
          console.log('Erro carregar getMunicipios ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }

        if(response.retorno.values.length === undefined ){
          let objTemp = {
                          "estado" : response.retorno.values.value[1].$value ,//estado
                          "name": response.retorno.values.value[0].$value ,//nome cidade
                          "codigo": response.retorno.values.value[2].$value //cod munici
                        }
          this.cidadesPlaca.push(objTemp)
        }else{
          for (let index = 0; index < response.retorno.values.length; index++) {
            let objTemp = {
                            "estado" : response.retorno.values[index].value[1].$value ,//estado
                            "name": response.retorno.values[index].value[0].$value ,//nome cidade
                            "codigo": response.retorno.values[index].value[2].$value //cod munici
                          }
            this.cidadesPlaca.push(objTemp)
          }
        }

        if( Municip != undefined){
          this.dadosVeiculo_FormGroup.get("municipioPlaca_DadosVeiculo").setValue( Municip  )
        }

    }, (erro) => {
      console.log('Erro carregar getMunicipios ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingCidades = false
    });

  }

  onChangeEstadoProprietarioReboque1( Municip : any ){

    this.dadosVeiculo_FormGroup.get("reboque1_Proprietario_cidade_DadosVeiculo").setValue(null);
    let UF = this.dadosVeiculo_FormGroup.get("reboque1_Proprietario_estado_DadosVeiculo").value
    this.loadingCidades = true
    this.cidadesProprietarioReboque1 = []

    this.caminhoeiroService.getMunicipios(UF).
      subscribe(( response : any ) => {
        this.loadingCidades = false
        if( response.status === "error"){
          console.log('Erro carregar getMunicipios ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }

        if(response.retorno.values.length === undefined ){
          let objTemp = {
                          "estado" : response.retorno.values.value[1].$value ,//estado
                          "name": response.retorno.values.value[0].$value ,//nome cidade
                          "codigo": response.retorno.values.value[2].$value //cod munici
                        }
          this.cidadesProprietarioReboque1.push(objTemp)
        }else{
          for (let index = 0; index < response.retorno.values.length; index++) {
            let objTemp = {
                            "estado" : response.retorno.values[index].value[1].$value ,//estado
                            "name": response.retorno.values[index].value[0].$value ,//nome cidade
                            "codigo": response.retorno.values[index].value[2].$value //cod munici
                          }
            this.cidadesProprietarioReboque1.push(objTemp)
          }
        }

        if( Municip != undefined){
          this.dadosVeiculo_FormGroup.get("reboque1_Proprietario_cidade_DadosVeiculo").setValue( Municip  )
        }

    }, (erro) => {
      console.log('Erro carregar getMunicipios ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingCidades = false
    });

  }

  onChangeEstadoProprietarioReboque2( Municip : any ){

    this.dadosVeiculo_FormGroup.get("reboque2_Proprietario_cidade_DadosVeiculo").setValue(null);
    let UF = this.dadosVeiculo_FormGroup.get("reboque2_Proprietario_estado_DadosVeiculo").value
    this.loadingCidades = true
    this.cidadesProprietarioReboque2 = []

    this.caminhoeiroService.getMunicipios(UF).
      subscribe(( response : any ) => {
        this.loadingCidades = false
        if( response.status === "error"){
          console.log('Erro carregar getMunicipios ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }

        if(response.retorno.values.length === undefined ){
          let objTemp = {
                          "estado" : response.retorno.values.value[1].$value ,//estado
                          "name": response.retorno.values.value[0].$value ,//nome cidade
                          "codigo": response.retorno.values.value[2].$value //cod munici
                        }
          this.cidadesProprietarioReboque2.push(objTemp)
        }else{
          for (let index = 0; index < response.retorno.values.length; index++) {
            let objTemp = {
                            "estado" : response.retorno.values[index].value[1].$value ,//estado
                            "name": response.retorno.values[index].value[0].$value ,//nome cidade
                            "codigo": response.retorno.values[index].value[2].$value //cod munici
                          }
            this.cidadesProprietarioReboque2.push(objTemp)
          }
        }


        if( Municip != undefined){
          this.dadosVeiculo_FormGroup.get("reboque2_Proprietario_cidade_DadosVeiculo").setValue( Municip  )
        }

    }, (erro) => {
      console.log('Erro carregar getMunicipios ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingCidades = false
    });

  }

  onChangeEstadoProprietarioReboque3( Municip : any ){

    this.dadosVeiculo_FormGroup.get("reboque3_Proprietario_cidade_DadosVeiculo").setValue(null);
    let UF = this.dadosVeiculo_FormGroup.get("reboque3_Proprietario_estado_DadosVeiculo").value
    this.loadingCidades = true
    this.cidadesProprietarioReboque3 = []

    this.caminhoeiroService.getMunicipios(UF).
      subscribe(( response : any ) => {
        this.loadingCidades = false
        if( response.status === "error"){
          console.log('Erro carregar getMunicipios ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }


        if(response.retorno.values.length === undefined ){
          let objTemp = {
                          "estado" : response.retorno.values.value[1].$value ,//estado
                          "name": response.retorno.values.value[0].$value ,//nome cidade
                          "codigo": response.retorno.values.value[2].$value //cod munici
                        }
          this.cidadesProprietarioReboque3.push(objTemp)
        }else{
          for (let index = 0; index < response.retorno.values.length; index++) {
            let objTemp = {
                            "estado" : response.retorno.values[index].value[1].$value ,//estado
                            "name": response.retorno.values[index].value[0].$value ,//nome cidade
                            "codigo": response.retorno.values[index].value[2].$value //cod munici
                          }
            this.cidadesProprietarioReboque3.push(objTemp)
          }
        }

        if( Municip != undefined){
          this.dadosVeiculo_FormGroup.get("reboque3_Proprietario_cidade_DadosVeiculo").setValue( Municip  )
        }

    }, (erro) => {
      console.log('Erro carregar getMunicipios ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingCidades = false
    });

  }

  onChangeEstadoPlacaReboque1( Municip : any ){

    this.dadosVeiculo_FormGroup.get("reboque1_municipioPlaca_DadosVeiculo").setValue(null);
    let UF = this.dadosVeiculo_FormGroup.get("reboque1_estadoPlaca_DadosVeiculo").value
    this.loadingCidades = true
    this.cidadesPlacaReboque1 = []

    this.caminhoeiroService.getMunicipios(UF).
      subscribe(( response : any ) => {
        this.loadingCidades = false
        if( response.status === "error"){
          console.log('Erro carregar getMunicipios ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }

        if(response.retorno.values.length === undefined ){
          let objTemp = {
                          "estado" : response.retorno.values.value[1].$value ,//estado
                          "name": response.retorno.values.value[0].$value ,//nome cidade
                          "codigo": response.retorno.values.value[2].$value //cod munici
                        }
          this.cidadesPlacaReboque1.push(objTemp)
        }else{
          for (let index = 0; index < response.retorno.values.length; index++) {
            let objTemp = {
                            "estado" : response.retorno.values[index].value[1].$value ,//estado
                            "name": response.retorno.values[index].value[0].$value ,//nome cidade
                            "codigo": response.retorno.values[index].value[2].$value //cod munici
                          }
            this.cidadesPlacaReboque1.push(objTemp)
          }
        }

        if( Municip != undefined){
          this.dadosVeiculo_FormGroup.get("reboque1_municipioPlaca_DadosVeiculo").setValue( Municip  )
        }

    }, (erro) => {
      console.log('Erro carregar getMunicipios ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingCidades = false
    });

  }

  onChangeEstadoPlacaReboque2( Municip : any ){

    this.dadosVeiculo_FormGroup.get("reboque2_municipioPlaca_DadosVeiculo").setValue(null);
    let UF = this.dadosVeiculo_FormGroup.get("reboque2_estadoPlaca_DadosVeiculo").value
    this.loadingCidades = true
    this.cidadesPlacaReboque2 = []

    this.caminhoeiroService.getMunicipios(UF).
      subscribe(( response : any ) => {
        this.loadingCidades = false
        if( response.status === "error"){
          console.log('Erro carregar getMunicipios ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }


        if(response.retorno.values.length === undefined ){
          let objTemp = {
                          "estado" : response.retorno.values.value[1].$value ,//estado
                          "name": response.retorno.values.value[0].$value ,//nome cidade
                          "codigo": response.retorno.values.value[2].$value //cod munici
                        }
          this.cidadesPlacaReboque2.push(objTemp)
        }else{
          for (let index = 0; index < response.retorno.values.length; index++) {
            let objTemp = {
                            "estado" : response.retorno.values[index].value[1].$value ,//estado
                            "name": response.retorno.values[index].value[0].$value ,//nome cidade
                            "codigo": response.retorno.values[index].value[2].$value //cod munici
                          }
            this.cidadesPlacaReboque2.push(objTemp)
          }
        }

        if( Municip != undefined){
          this.dadosVeiculo_FormGroup.get("reboque2_municipioPlaca_DadosVeiculo").setValue( Municip  )
        }

    }, (erro) => {
      console.log('Erro carregar getMunicipios ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingCidades = false
    });

  }

  onChangeEstadoPlacaReboque3( Municip : any ){

    this.dadosVeiculo_FormGroup.get("reboque3_municipioPlaca_DadosVeiculo").setValue(null);
    let UF = this.dadosVeiculo_FormGroup.get("reboque3_estadoPlaca_DadosVeiculo").value
    this.loadingCidades = true
    this.cidadesPlacaReboque3 = []

    this.caminhoeiroService.getMunicipios(UF).
      subscribe(( response : any ) => {
        this.loadingCidades = false
        if( response.status === "error"){
          console.log('Erro carregar getMunicipios ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }

        if(response.retorno.values.length === undefined ){
          let objTemp = {
                          "estado" : response.retorno.values.value[1].$value ,//estado
                          "name": response.retorno.values.value[0].$value ,//nome cidade
                          "codigo": response.retorno.values.value[2].$value //cod munici
                        }
          this.cidadesPlacaReboque3.push(objTemp)
        }else{
          for (let index = 0; index < response.retorno.values.length; index++) {
            let objTemp = {
                            "estado" : response.retorno.values[index].value[1].$value ,//estado
                            "name": response.retorno.values[index].value[0].$value ,//nome cidade
                            "codigo": response.retorno.values[index].value[2].$value //cod munici
                          }
            this.cidadesPlacaReboque3.push(objTemp)
          }
        }

        if( Municip != undefined){
          this.dadosVeiculo_FormGroup.get("reboque3_municipioPlaca_DadosVeiculo").setValue( Municip  )
        }

    }, (erro) => {
      console.log('Erro carregar getMunicipios ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingCidades = false
    });

  }

  onChangeNomeReduzido(){


        this.cpf_cnpj_FormGroup.get("nome_RazaoSocial").setValue(  this.removeCharsEspecial(this.cpf_cnpj_FormGroup.get("nome_RazaoSocial").value , false , true , false) );


  }

  onChangeCEP(){

    this.loadingBuscaCEP = true
    let CEP = this.dadosProprietarioVeiculo_FormGroup.get("cep_ProprietarioVeiculo").value

    this.caminhoeiroService.getCEP(CEP).
      subscribe(( response : any ) => {

        this.loadingBuscaCEP = false

        this.dadosProprietarioVeiculo_FormGroup.get("nome_RazaoSocial_ProprietarioVeiculo").setValue(  this.removeCharsEspecial(  this.dadosProprietarioVeiculo_FormGroup.get("nome_RazaoSocial_ProprietarioVeiculo").value , false , true , false)   );
        this.dadosProprietarioVeiculo_FormGroup.get("nome_NomeFantasia_ProprietarioVeiculo").setValue(  this.removeCharsEspecial(  this.dadosProprietarioVeiculo_FormGroup.get("nome_NomeFantasia_ProprietarioVeiculo").value , false , true , false)   );

        this.dadosProprietarioVeiculo_FormGroup.get("endereco_ProprietarioVeiculo").setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)   );
        this.dadosProprietarioVeiculo_FormGroup.get("bairro_ProprietarioVeiculo").setValue(   this.removeCharsEspecial(   response.bairro  , false , true , false).substr(0 , 20) );
        this.dadosProprietarioVeiculo_FormGroup.get("UF_ProprietarioVeiculo").setValue(   response.uf  );
        let  cidade = this.removeCharsEspecial( response.localidade , false , true , true)
        this.onChangeEstado( cidade )


    }, (erro) => {
      console.log('Erro carregar getCEP ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingBuscaCEP = false
    });

  }

  onChangeCEPMotorista(){

    this.loadingBuscaCEP = true
    let CEP = this.dadosMotorista_FormGroup.get("cep_DadosMotorista").value

    this.caminhoeiroService.getCEP(CEP).
      subscribe(( response : any ) => {

        this.loadingBuscaCEP = false

        this.dadosMotorista_FormGroup.get("nome_RazaoSocial_DadosMotorista").setValue(  this.removeCharsEspecial(  this.dadosMotorista_FormGroup.get("nome_RazaoSocial_DadosMotorista").value , false , true , false)   );
        this.dadosMotorista_FormGroup.get("nome_NomeFantasia_DadosMotorista").setValue(  this.removeCharsEspecial(  this.dadosMotorista_FormGroup.get("nome_NomeFantasia_DadosMotorista").value , false , true , false)   );


        this.dadosMotorista_FormGroup.get("endereco_DadosMotorista").setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)  );
        this.dadosMotorista_FormGroup.get("bairro_DadosMotorista").setValue(  this.removeCharsEspecial(   response.bairro  , false , true , false).substr(0 , 20) );
        this.dadosMotorista_FormGroup.get("UF_DadosMotorista").setValue( response.uf  );
        let  cidade = this.removeCharsEspecial( response.localidade , false , true, true)
        this.onChangeEstadoMotorista( cidade )


    }, (erro) => {
      console.log('Erro carregar getCEP ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingBuscaCEP = false
    });

  }

  onChangeCEPProprietarioReboque1(){

    this.loadingBuscaCEP = true
    let CEP = this.dadosVeiculo_FormGroup.get("reboque1_Proprietario_cep_DadosVeiculo").value

    this.caminhoeiroService.getCEP(CEP).
      subscribe(( response : any ) => {

        this.loadingBuscaCEP = false

        this.dadosVeiculo_FormGroup.get("reboque1_Proprietario_endereco_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)   );
        this.dadosVeiculo_FormGroup.get("reboque1_Proprietario_bairro_DadosVeiculo").setValue(   this.removeCharsEspecial(   response.bairro  , false , true , false).substr(0 , 20) );
        this.dadosVeiculo_FormGroup.get("reboque1_Proprietario_estado_DadosVeiculo").setValue(   response.uf  );
        let  cidade = this.removeCharsEspecial( response.localidade , false , true , true)
        this.onChangeEstadoProprietarioReboque1( cidade )


    }, (erro) => {
      console.log('Erro carregar getCEP ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingBuscaCEP = false
    });

  }

  onChangeCEPProprietarioReboque2(){

    this.loadingBuscaCEP = true
    let CEP = this.dadosVeiculo_FormGroup.get("reboque2_Proprietario_cep_DadosVeiculo").value

    this.caminhoeiroService.getCEP(CEP).
      subscribe(( response : any ) => {

        this.loadingBuscaCEP = false

        this.dadosVeiculo_FormGroup.get("reboque2_Proprietario_endereco_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)   );
        this.dadosVeiculo_FormGroup.get("reboque2_Proprietario_bairro_DadosVeiculo").setValue(   this.removeCharsEspecial(   response.bairro  , false , true , false).substr(0 , 20) );
        this.dadosVeiculo_FormGroup.get("reboque2_Proprietario_estado_DadosVeiculo").setValue(   response.uf  );
        let  cidade = this.removeCharsEspecial( response.localidade , false , true , true)
        this.onChangeEstadoProprietarioReboque2( cidade )


    }, (erro) => {
      console.log('Erro carregar getCEP ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingBuscaCEP = false
    });

  }

  onChangeCEPProprietarioReboque3(){

    this.loadingBuscaCEP = true
    let CEP = this.dadosVeiculo_FormGroup.get("reboque3_Proprietario_cep_DadosVeiculo").value

    this.caminhoeiroService.getCEP(CEP).
      subscribe(( response : any ) => {

        this.loadingBuscaCEP = false

        this.dadosVeiculo_FormGroup.get("reboque3_Proprietario_endereco_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)   );
        this.dadosVeiculo_FormGroup.get("reboque3_Proprietario_bairro_DadosVeiculo").setValue(   this.removeCharsEspecial(   response.bairro  , false , true , false).substr(0 , 20) );
        this.dadosVeiculo_FormGroup.get("reboque3_Proprietario_estado_DadosVeiculo").setValue(   response.uf  );
        let  cidade = this.removeCharsEspecial( response.localidade , false , true , true)
        this.onChangeEstadoProprietarioReboque3( cidade )


    }, (erro) => {
      console.log('Erro carregar getCEP ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingBuscaCEP = false
    });

  }

  onChangeTipoVeiculo(){

    const tipoValue = this.cpf_cnpj_FormGroup.get("tipo_DadosVeiculo").value

    let index = this.tiposVeiculos.findIndex(codigo => {
      return (new RegExp('\^' + codigo.codigo + '\$')).test(tipoValue);
    });

    if(this.tiposVeiculos[index].categoria > '0'){

       if(this.tiposVeiculos[index].codigo == '05'){
          this.isTipoReboques1 = true;
          this.isTipoReboques2 = false;
          this.isTipoReboques3 = false;

          this.cpf_cnpj_FormGroup.get('placa_reboque1').setValidators([Validators.required]);
          this.cpf_cnpj_FormGroup.get('placa_reboque2').setValidators([]);
          this.cpf_cnpj_FormGroup.get('placa_reboque3').setValidators([]);
          this.cpf_cnpj_FormGroup.get('placa_reboque2').setValue('');
          this.cpf_cnpj_FormGroup.get('placa_reboque3').setValue('');

       }

       if(this.tiposVeiculos[index].codigo == '10'){
          this.isTipoReboques1 = true;
          this.isTipoReboques2 = false;
          this.isTipoReboques3 = false;
          this.cpf_cnpj_FormGroup.get('placa_reboque1').setValidators([Validators.required]);
          this.cpf_cnpj_FormGroup.get('placa_reboque2').setValidators([]);
          this.cpf_cnpj_FormGroup.get('placa_reboque3').setValidators([]);
          this.cpf_cnpj_FormGroup.get('placa_reboque2').setValue('');
          this.cpf_cnpj_FormGroup.get('placa_reboque3').setValue('');
       }

       if(this.tiposVeiculos[index].codigo == '06'){
          this.isTipoReboques1 = true;
          this.isTipoReboques2 = true;
          this.isTipoReboques3 = true;

          this.cpf_cnpj_FormGroup.get('placa_reboque1').setValidators([Validators.required]);
          this.cpf_cnpj_FormGroup.get('placa_reboque2').setValidators([Validators.required]);
          this.cpf_cnpj_FormGroup.get('placa_reboque3').setValidators([Validators.required]);

       }

       if(this.tiposVeiculos[index].codigo == '07'){
          this.isTipoReboques1 = true;
          this.isTipoReboques2 = true;
          this.isTipoReboques3 = false;

          this.cpf_cnpj_FormGroup.get('placa_reboque1').setValidators([Validators.required]);
          this.cpf_cnpj_FormGroup.get('placa_reboque2').setValidators([Validators.required]);
          this.cpf_cnpj_FormGroup.get('placa_reboque3').setValidators([]);
          this.cpf_cnpj_FormGroup.get('placa_reboque3').setValue('');

       }


    }else{
      this.isTipoReboques1 = false;
      this.isTipoReboques2 = false;
      this.isTipoReboques3 = false;

      this.cpf_cnpj_FormGroup.get('placa_reboque1').setValidators([]);
      this.cpf_cnpj_FormGroup.get('placa_reboque2').setValidators([]);
      this.cpf_cnpj_FormGroup.get('placa_reboque3').setValidators([]);
      this.cpf_cnpj_FormGroup.get('placa_reboque1').setValue('');
      this.cpf_cnpj_FormGroup.get('placa_reboque2').setValue('');
      this.cpf_cnpj_FormGroup.get('placa_reboque3').setValue('');
    }


  }

  autorizaFavorecido(evento: { checked: any; }){
    evento.checked ? this.hiddenFavorecido = false : this.hiddenFavorecido = true
  }

  marcaPoliticaUso(evento: { checked: any; }){
    if(evento.checked){
      this.aceitePoliticaUso_FormGroup.get("data_aceitePoliticaUso").setValue( new Date())
    }else{
      this.aceitePoliticaUso_FormGroup.get("data_aceitePoliticaUso").setValue( null )
    }
  }

  marcaAceiteNotificacao(evento: { checked: any; }){
    if(evento.checked){
      this.aceitePoliticaUso_FormGroup.get("data_aceiteNotificacaoMensagem").setValue( new Date())
    }else{
      this.aceitePoliticaUso_FormGroup.get("data_aceiteNotificacaoMensagem").setValue( null )
    }
  }

  click_Proximo_CpfCnpj_PropMotorista(){

    let tipoCadastro = this.cpf_cnpj_FormGroup.get("tipo_cadastro").value

    if( tipoCadastro !== 'motorista'){
      this.dadosProprietarioVeiculo_FormGroup.get("cpfOrCpnj_ProprietarioVeiculo").setValue(  this.cpf_cnpj_FormGroup.get("cpfOrCpnj").value  );
      if( this.isCpf){
        this.dadosProprietarioVeiculo_FormGroup.get("ie_ProprietarioVeiculo").setValue('ISENTO');
        this.isCpfProrprietario = true
      }else{
        this.dadosProprietarioVeiculo_FormGroup.get("ie_ProprietarioVeiculo").setValue( null );
        this.isCpfProrprietario = false
      }
    }else{
      this.dadosProprietarioVeiculo_FormGroup.get("cpfOrCpnj_ProprietarioVeiculo").setValue(null );
      this.dadosProprietarioVeiculo_FormGroup.get("ie_ProprietarioVeiculo").setValue( null );
    }

    if(this.cpf_cnpj_FormGroup.valid){

      this.loading('show')

      if(this.isOnChanged_cpfCnpj){

        if( !this.isDataTempFirebase ){


          if(this.isCpf){
            //não alimentou a data nascimento
            this.isCompleted_step_cpfCnjp = true
            this.loading('hide')
            if( this.paises.length === 0 ){
              this.loadPaises()
            }
            this.SalvarForms('3')
            return
          }


        }else{
          this.isCompleted_step_cpfCnjp = true
          this.SalvarForms('3')
          this.loading('hide')
        }

      }else{
        this.onChangeCPF_CNPJ()
        this.loading('hide')
      }

    }

  }

  formataDatas(date: string | number | Date){
    var sDate = "";

    let dtNascimento = new Date( date )
    let dd = dtNascimento.getDate();
    let mm = dtNascimento.getMonth() + 1;
    let yyyy = dtNascimento.getFullYear();
    let ddNew = '';
    let mmNew = '';
    if (dd < 10) {
      ddNew = dd.toString().padStart(2,"0");
    }
    if (mm < 10) {
      mmNew = mm.toString().padStart(2,"0");
    }

    if(ddNew != '' && mmNew != ''){
      sDate = ddNew + '/' + mmNew + '/' + yyyy;
    }else if(ddNew != '' && mmNew == ''){
      sDate = ddNew + '/' + mm + '/' + yyyy;
    }else if(ddNew == '' && mmNew != ''){
      sDate = dd + '/' + mmNew + '/' + yyyy;
    }else{
      sDate = dd + '/' + mm + '/' + yyyy;
    }

    return sDate
  }
  click_Proximo_PropMotorista_Motorista(){

    let tipoCadastro = this.cpf_cnpj_FormGroup.get("tipo_cadastro").value

    if(this.isCpf){

      if( tipoCadastro === 'proprietarioEmotorista'){
        this.dadosMotorista_FormGroup.get("cpfOrCpnj_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("cpfOrCpnj_ProprietarioVeiculo").value  );
        this.dadosMotorista_FormGroup.get("nome_RazaoSocial_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("nome_RazaoSocial_ProprietarioVeiculo").value  );
        this.dadosMotorista_FormGroup.get("nome_NomeFantasia_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("nome_NomeFantasia_ProprietarioVeiculo").value  );
        this.dadosMotorista_FormGroup.get("dataNascimento_DadosMotorista").setValue(  this.cpf_cnpj_FormGroup.get("data_nascimento").value  );
        this.dadosMotorista_FormGroup.get("cep_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("cep_ProprietarioVeiculo").value  );
        this.dadosMotorista_FormGroup.get("endereco_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("endereco_ProprietarioVeiculo").value  );
        this.dadosMotorista_FormGroup.get("bairro_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("bairro_ProprietarioVeiculo").value  );
        this.dadosMotorista_FormGroup.get("UF_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("UF_ProprietarioVeiculo").value  );
        this.dadosMotorista_FormGroup.get("cidade_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("cidade_ProprietarioVeiculo").value  );
        this.onChangeEstadoMotorista( this.dadosProprietarioVeiculo_FormGroup.get("cidade_ProprietarioVeiculo").value )
        this.dadosMotorista_FormGroup.get("pais_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("pais_ProprietarioVeiculo").value  );
        this.dadosMotorista_FormGroup.get("email_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("email_ProprietarioVeiculo").value  );
        this.dadosMotorista_FormGroup.get("DDI_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("DDI_ProprietarioVeiculo").value  );
        this.dadosMotorista_FormGroup.get("DDD_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("DDD_ProprietarioVeiculo").value  );
        this.dadosMotorista_FormGroup.get("telefone_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("telefone_ProprietarioVeiculo").value  );
        //this.dadosMotorista_FormGroup.get("IMGCNH_DadosMotorista").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("IMGCNH_ProprietarioVeiculo").value  );
        this.ishiddenDadosMotorista = false
      }
      this.isCompleted_step_Motorista = true
    }else{
      this.isCompleted_step_Motorista = true
    }


  }

  onChangeCPFCNPJProprietario( idForms: string , cpfOrCnpj: any ){

    let cpfOrCpnj =  eval('this.' + idForms).get(cpfOrCnpj).value
    let tipoConsulta   = 'RF'

    if( cpfOrCpnj.length === 11 ){

      if(!cpf.isValid(cpfOrCpnj)){
        console.log('CPF não é valido ')
        this.loading('hide')
        this.openSnackBar('Ops! CPF digitado não é valido... ' , 'x' ,  'msgErro' )
        eval('this.' + idForms).get(cpfOrCnpj).setValue("")
      }
      //CPF - NÃO BUSCA
      this.isCpfProrprietario = true
      this.dadosProprietarioVeiculo_FormGroup.get("ie_ProprietarioVeiculo").setValue('ISENTO');
      return
    }else{
      this.isCpfProrprietario = false
      this.dadosProprietarioVeiculo_FormGroup.get("ie_ProprietarioVeiculo").setValue(null);
    }



    if(!this.isCpfProrprietario){

      if(cnpj.isValid(cpfOrCpnj)){

        this.loading('show')
        this.caminhoeiroService.getCpfReceitaWS( cpfOrCpnj ,  '' ,  tipoConsulta ).subscribe(( response : any ) => {

            if( response.code === "0") {
              switch(cpfOrCnpj ) {
                case 'cpfOrCpnj_ProprietarioVeiculo':
                  eval('this.' + idForms).get("nome_RazaoSocial_ProprietarioVeiculo").setValue(  this.removeCharsEspecial(  response.nome , false , true , false).substr(0 , 40)   );
                  eval('this.' + idForms).get("nome_NomeFantasia_ProprietarioVeiculo").setValue(  this.removeCharsEspecial(  response.fantasia , false , true , false).substr(0 , 20)  );
                  eval('this.' + idForms).get("cep_ProprietarioVeiculo").setValue( response.cep  );
                  eval('this.' + idForms).get("endereco_ProprietarioVeiculo").setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)   );
                  eval('this.' + idForms).get("bairro_ProprietarioVeiculo").setValue(  this.removeCharsEspecial(  response.bairro , false , true , false).substr(0 , 20)   );
                  eval('this.' + idForms).get("UF_ProprietarioVeiculo").setValue( response.uf  );
                  this.onChangeEstado( this.removeCharsEspecial(  response.municipio , false , true , false) )
                  break;
                case 'reboque1_Proprietario_CpfCnpj_DadosVeiculo':
                  eval('this.' + idForms).get("reboque1_Proprietario_razaosocial_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.nome , false , true , false).substr(0 , 40)   );
                  eval('this.' + idForms).get("reboque1_Proprietario_nomefantasia_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.fantasia , false , true , false).substr(0 , 20)  );
                  eval('this.' + idForms).get("reboque1_Proprietario_cep_DadosVeiculo").setValue( response.cep  );
                  eval('this.' + idForms).get("reboque1_Proprietario_endereco_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)   );
                  eval('this.' + idForms).get("reboque1_Proprietario_bairro_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.bairro , false , true , false).substr(0 , 20)   );
                  eval('this.' + idForms).get("reboque1_Proprietario_estado_DadosVeiculo").setValue( response.uf  );
                  this.onChangeEstadoProprietarioReboque1( this.removeCharsEspecial(  response.municipio , false , true , false) )
                  break;
                case 'reboque2_Proprietario_CpfCnpj_DadosVeiculo':
                  eval('this.' + idForms).get("reboque2_Proprietario_razaosocial_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.nome , false , true , false).substr(0 , 40)   );
                  eval('this.' + idForms).get("reboque2_Proprietario_nomefantasia_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.fantasia , false , true , false).substr(0 , 20)  );
                  eval('this.' + idForms).get("reboque2_Proprietario_cep_DadosVeiculo").setValue( response.cep  );
                  eval('this.' + idForms).get("reboque2_Proprietario_endereco_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)   );
                  eval('this.' + idForms).get("reboque2_Proprietario_bairro_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.bairro , false , true , false).substr(0 , 20)   );
                  eval('this.' + idForms).get("reboque2_Proprietario_estado_DadosVeiculo").setValue( response.uf  );
                  this.onChangeEstadoProprietarioReboque2( this.removeCharsEspecial(  response.municipio , false , true , false) )
                  break;
                case 'reboque3_Proprietario_CpfCnpj_DadosVeiculo':
                  eval('this.' + idForms).get("reboque3_Proprietario_razaosocial_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.nome , false , true , false).substr(0 , 40)   );
                  eval('this.' + idForms).get("reboque3_Proprietario_nomefantasia_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.fantasia , false , true , false).substr(0 , 20)  );
                  eval('this.' + idForms).get("reboque3_Proprietario_cep_DadosVeiculo").setValue( response.cep  );
                  eval('this.' + idForms).get("reboque3_Proprietario_endereco_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)   );
                  eval('this.' + idForms).get("reboque3_Proprietario_bairro_DadosVeiculo").setValue(  this.removeCharsEspecial(  response.bairro , false , true , false).substr(0 , 20)   );
                  eval('this.' + idForms).get("reboque3_Proprietario_estado_DadosVeiculo").setValue( response.uf  );
                  this.onChangeEstadoProprietarioReboque3( this.removeCharsEspecial(  response.municipio , false , true , false) )
                  break;
              }
              this.loading('hide')
            }else if(response.code === "1"){
              console.log("getCpfReceitaWS - sintegraws:" , "Nenhum estabelecimento encontrado no Sintegra.")
              this.loading('hide')
            }else{
              if( response.code != "0") {

                console.log("getCpfReceitaWS - sintegraws:" , "*** Os créditos contratados acabaram.")
                this.loading('hide')
                this.caminhoeiroService.getDSFaltaCreditoSintegraWS(response.code,response.message).
                subscribe(( response : any) => {
                  let datasetReturn = response
                }, (erro) => {
                  console.log('Erro carregar getDSFaltaCreditoSintegraWS ', erro)
                  this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
                  this.loading('hide')
                });

              }else{
                this.openSnackBar('Ops! ' + response.message , 'x' ,  'msgErro' )
                this.loading('hide')
              }

            }

        }, (erro) => {
          console.log('Erro carregar getCpfReceitaWS ', erro)
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
          this.loading('hide')
        });

      }else{
      console.log('CNPJ não é valido ')
      this.loading('hide')
      this.openSnackBar('Ops! CNPJ digitado não é valido... ' , 'x' ,  'msgErro' )
      eval('this.' + idForms).get(cpfOrCnpj).setValue("")
      }
    }
  }

  onChangePais(formGroup: string , nameCampoPais: any , nameCampoDestino: any ){
    eval('this.' + formGroup).get( nameCampoDestino ).setValue( eval('this.' + formGroup).get(  nameCampoPais  ).value  );
  }
/*
  verImagemCNH(urlImagem: any){

    this.dialog.open(CaminhoneiroDialogImagensCnhComponent,{
      data:{
        urlImagem: urlImagem,
      }
    });

  }
  */

  alteraEtapa(evento: { selectedIndex: any; }){

    switch(evento.selectedIndex) {
      case 0:
          //this.changeTelContato()
          this.SalvarForms('0')
          break;
      case 1:
          this.SalvarForms('1')
          break;
      case 2:
          this.SalvarForms('2')
          break;
      case 3:
          this.SalvarForms('3')
          break;
    }

  }

  SalvarForms(cEtapa: string){

    this.loading('show')
    let objSalve = {}
    let cpforcpnj =  this.cpf_cnpj_FormGroup.get("cpfOrCpnj").value

    //this.signInAnonymously()

    console.log("****** Salvando Forms - Firebase ******* " , new Date() )

    objSalve = {
        "dataForms" : {
          "cpf_cnpj_FormGroup" : this.cpf_cnpj_FormGroup.value  ,
          "dadosProprietarioVeiculo_FormGroup" : this.dadosProprietarioVeiculo_FormGroup.value ,
          "dadosMotorista_FormGroup" : this.dadosMotorista_FormGroup.value ,
          "dadosVeiculo_FormGroup" : this.dadosVeiculo_FormGroup.value ,
          "dadosAcesso_FormGroup" : this.dadosAcesso_FormGroup.value,
          "aceitePoliticaUso_FormGroup" : this.aceitePoliticaUso_FormGroup.value
        } ,
      "stepperNextIndex" : this.stepper.selectedIndex === 0 ? 1 : this.stepper.selectedIndex
    }

    let temp = cEtapa;

    this.temporarioObjeto = {
      cpfOrCpnj: this.cpf_cnpj_FormGroup.get("cpfOrCpnj").value,
      nome_RazaoSocial: this.cpf_cnpj_FormGroup.get("nome_RazaoSocial").value,
      tipo_cadastro: this.cpf_cnpj_FormGroup.get("tipo_cadastro").value,
      dddcontato: this.cpf_cnpj_FormGroup.get("dddcontato").value,
      telefonecontato: this.cpf_cnpj_FormGroup.get("telefonecontato").value,
      placa_veiculo: this.cpf_cnpj_FormGroup.get("placa_veiculo").value,
      antt_veiculo: this.cpf_cnpj_FormGroup.get("antt_veiculo").value,
      tipo_DadosVeiculo: this.cpf_cnpj_FormGroup.get("tipo_DadosVeiculo").value,
      placa_reboque1: this.cpf_cnpj_FormGroup.get("placa_reboque1").value ? this.cpf_cnpj_FormGroup.get("placa_reboque1").value:"",
      placa_reboque2: this.cpf_cnpj_FormGroup.get("placa_reboque2").value ? this.cpf_cnpj_FormGroup.get("placa_reboque2").value:"",
      placa_reboque3: this.cpf_cnpj_FormGroup.get("placa_reboque3").value ? this.cpf_cnpj_FormGroup.get("placa_reboque3").value:"",
      passoword_DadosAcesso: this.dadosAcesso_FormGroup.get("passoword_DadosAcesso").value ? this.dadosAcesso_FormGroup.get("passoword_DadosAcesso").value:"",
      confirm_passoword_DadosAcesso: this.dadosAcesso_FormGroup.get("confirm_passoword_DadosAcesso").value ? this.dadosAcesso_FormGroup.get("confirm_passoword_DadosAcesso").value:"",
      aceitePoliticaUso: this.aceitePoliticaUso_FormGroup.get("aceitePoliticaUso").value ? this.aceitePoliticaUso_FormGroup.get("aceitePoliticaUso").value:"",
      aceiteNotificacaoMensagem: this.aceitePoliticaUso_FormGroup.get("aceiteNotificacaoMensagem").value ? this.aceitePoliticaUso_FormGroup.get("aceiteNotificacaoMensagem").value:"",
      data_aceitePoliticaUso: this.aceitePoliticaUso_FormGroup.get("data_aceitePoliticaUso").value ? this.aceitePoliticaUso_FormGroup.get("data_aceitePoliticaUso").value:"",
      data_aceiteNotificacaoMensagem: this.aceitePoliticaUso_FormGroup.get("data_aceiteNotificacaoMensagem").value ? this.aceitePoliticaUso_FormGroup.get("data_aceiteNotificacaoMensagem").value :"",
      origemCadastro: this.aceitePoliticaUso_FormGroup.get("origemCadastro").value ? this.aceitePoliticaUso_FormGroup.get("origemCadastro").value:"",
      stepperNextIndex: temp+'',
      published: false
    }

    //Alimento a variavel que direciona o caminho onde salvará
    this.configService.dbPath = environment.caminhoRealtimeMotoristas;
    this.configService.nofirebaseRef = this.firebaseRealtime.list(environment.caminhoRealtimeMotoristas);

    this.configService.update(this.cpf_cnpj_FormGroup.get("cpfOrCpnj").value, this.temporarioObjeto)
      .then(() => {
      console.log('Atualizado no firebase' , new Date())
      this.noTemporario = true;
      this.loading('hide')
      })
      .catch(err => console.log(err));




  }

  marcaReboque( evento: { checked: any; } , reboque: any ){

    switch(reboque) {
      case 'reboque1':
        if(evento.checked){
          this.isExistReboque1 = true
          this.dadosVeiculo_FormGroup.get('reboque1_Propriedade_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_placa_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_tipoVeiculo_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('carrocReboque1_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_IMGCRLV_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_capacidade_nominal_DadosVeiculo').setValidators([Validators.required])

        }else{
          this.isExistReboque1 = false;
          this.dadosVeiculo_FormGroup.get('reboque1_Propriedade_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_placa_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_tipoVeiculo_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('carrocReboque1_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_IMGCRLV_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_capacidade_nominal_DadosVeiculo').setValidators([])

        }
        break;
      case 'reboque2':
        if(evento.checked){
          this.isExistReboque2 = true
          this.dadosVeiculo_FormGroup.get('reboque2_Propriedade_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_placa_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_tipoVeiculo_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('carrocReboque2_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_IMGCRLV_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_capacidade_nominal_DadosVeiculo').setValidators([Validators.required])

        }else{
          this.isExistReboque2 = false;
          this.dadosVeiculo_FormGroup.get('reboque2_Propriedade_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_placa_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_tipoVeiculo_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('carrocReboque2_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_IMGCRLV_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_capacidade_nominal_DadosVeiculo').setValidators([])

        }
        break;
      case 'reboque3':
        if(evento.checked){
          this.isExistReboque3 = true
          this.dadosVeiculo_FormGroup.get('reboque3_Propriedade_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_placa_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_tipoVeiculo_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('carrocReboque3_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_IMGCRLV_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_capacidade_nominal_DadosVeiculo').setValidators([Validators.required])

        }else{
          this.isExistReboque3 = false;
          this.dadosVeiculo_FormGroup.get('reboque3_Propriedade_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_placa_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_tipoVeiculo_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('carrocReboque2_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_IMGCRLV_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_capacidade_nominal_DadosVeiculo').setValidators([])

        }
        break;
    }

  }

  onChangePropriedadeReboque(reboque: any){

    switch(reboque) {
      case 'reboque1':
        if(this.dadosVeiculo_FormGroup.get("reboque1_Propriedade_DadosVeiculo").value === "Terceiro"){
          //this.isTerceiroReboque1 = true
          //this.habilitaDesabilitaValidPropReboques( reboque , 'habilita' )
        }else{
          //this.isTerceiroReboque1  = false;
          //this.habilitaDesabilitaValidPropReboques( reboque , 'desabilita' )
        }
        break;
      case 'reboque2':
        if(this.dadosVeiculo_FormGroup.get("reboque2_Propriedade_DadosVeiculo").value === "Terceiro"){
          //this.isTerceiroReboque2 = true
          //this.habilitaDesabilitaValidPropReboques( reboque , 'habilita' )
        }else{
          //this.isTerceiroReboque2  = false;
          //this.habilitaDesabilitaValidPropReboques( reboque , 'desabilita' )
        }
        break;
      case 'reboque3':
        if(this.dadosVeiculo_FormGroup.get("reboque3_Propriedade_DadosVeiculo").value === "Terceiro"){
          //this.isTerceiroReboque3 = true
          //this.habilitaDesabilitaValidPropReboques( reboque , 'habilita' )
        }else{
          //this.isTerceiroReboque3 = false;
          //this.habilitaDesabilitaValidPropReboques( reboque , 'desabilita' )
        }
        break;
    }

  }

  habilitaDesabilitaValidPropReboques(reboque: any , acao: string ){
    switch(reboque) {
      case 'reboque1':
        if(acao === "habilita"){
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_CpfCnpj_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_ie_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_razaosocial_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_nomefantasia_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_cep_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_endereco_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_bairro_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_estado_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_cidade_DadosVeiculo').setValidators([ this.forbiddenNamesPropReboque1Validator() ])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_pais_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_email_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_DDI_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_DDD_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_telefone_DadosVeiculo').setValidators([Validators.required])
          //this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_RNTRC_DadosVeiculo').setValidators([Validators.required])
        }else if(acao === "desabilita"){
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_CpfCnpj_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_ie_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_razaosocial_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_nomefantasia_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_cep_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_endereco_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_bairro_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_estado_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_cidade_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_pais_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_email_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_DDI_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_DDD_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_telefone_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_RNTRC_DadosVeiculo').setValidators([])
        }
        break;
      case 'reboque2':
        if(acao === "habilita"){
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_CpfCnpj_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_ie_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_razaosocial_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_nomefantasia_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_cep_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_endereco_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_bairro_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_estado_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_cidade_DadosVeiculo').setValidators([ this.forbiddenNamesPropReboque2Validator() ])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_pais_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_email_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_DDI_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_DDD_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_telefone_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_RNTRC_DadosVeiculo').setValidators([Validators.required])
        }else if(acao === "desabilita"){
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_CpfCnpj_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_ie_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_razaosocial_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_nomefantasia_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_cep_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_endereco_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_bairro_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_estado_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_cidade_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_pais_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_email_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_DDI_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_DDD_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_telefone_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_RNTRC_DadosVeiculo').setValidators([])
        }
        break;
      case 'reboque3':
        if(acao === "habilita"){
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_CpfCnpj_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_ie_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_razaosocial_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_nomefantasia_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_cep_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_endereco_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_bairro_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_estado_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_cidade_DadosVeiculo').setValidators([ this.forbiddenNamesPropReboque3Validator() ])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_pais_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_email_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_DDI_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_DDD_DadosVeiculo').setValidators([Validators.required])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_telefone_DadosVeiculo').setValidators([Validators.required])
          //this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_RNTRC_DadosVeiculo').setValidators([Validators.required])
        }else if(acao === "desabilita"){
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_CpfCnpj_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_ie_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_razaosocial_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_nomefantasia_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_cep_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_endereco_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_bairro_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_estado_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_cidade_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_pais_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_email_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_DDI_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_DDD_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_telefone_DadosVeiculo').setValidators([])
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_RNTRC_DadosVeiculo').setValidators([])
        }
        break;
    }
  }

  finalizarCadastro(){

    this.loading('show')

    //*** validações antes de salvar
    this.limpaReboquesNaoMarcados();

    var aBodyTmp = {};
    var aBodyFim = [];
    var fieldCp = "";
    var valueCp = "";
    var dadoTratado;

    this.isOkFormsReboques = true
    const tipoValue = this.dadosVeiculo_FormGroup.get("tipo_DadosVeiculo").value
    let index = this.tiposVeiculos.findIndex(codigo => {
      return (new RegExp('\^' + codigo.codigo + '\$')).test(tipoValue);
    });

    let arr = [];
    let aGravaTudo = [];
    var objTemp;
    let vtemp = "";


    //Pega os dados de todos os Forms
    let objSalve = {
      "cpf_cnpj_FormGroup" : this.cpf_cnpj_FormGroup.value  ,
      "dadosAcesso_FormGroup" : this.dadosAcesso_FormGroup.value,
      "aceitePoliticaUso_FormGroup" : this.aceitePoliticaUso_FormGroup.value
    };

    let objSalveNew = {
      "cpfOrCpnj": this.cpf_cnpj_FormGroup.value.cpfOrCpnj ,
      "Nome_RazaoSocial": this.cpf_cnpj_FormGroup.value.nome_RazaoSocial,
      "tipo_cadastro": "L",
      "dddcontato": this.cpf_cnpj_FormGroup.value.dddcontato,
      "telefonecontato": this.cpf_cnpj_FormGroup.value.telefonecontato,
      "placa_veiculo": this.cpf_cnpj_FormGroup.value.placa_veiculo,
      "antt_veiculo": this.cpf_cnpj_FormGroup.value.antt_veiculo,
      "tipo_DadosVeiculo": this.cpf_cnpj_FormGroup.value.tipo_DadosVeiculo,
      "placa_reboque1": this.cpf_cnpj_FormGroup.value.placa_reboque1,
      "placa_reboque2": this.cpf_cnpj_FormGroup.value.placa_reboque2,
      "placa_reboque3": this.cpf_cnpj_FormGroup.value.placa_reboque3,
      "passoword_DadosAcesso": this.dadosAcesso_FormGroup.value.passoword_DadosAcesso,
      "aceitePoliticaUso": this.aceitePoliticaUso_FormGroup.value.aceitePoliticaUso ,
      "aceiteNotificacaoMensagem": this.aceitePoliticaUso_FormGroup.value.aceiteNotificacaoMensagem,
      "data_aceitePoliticaUso": this.aceitePoliticaUso_FormGroup.value.data_aceitePoliticaUso,
      "data_aceiteNotificacaoMensagem": this.aceitePoliticaUso_FormGroup.value.data_aceiteNotificacaoMensagem,
      "origemCadastro": "SITE BIALOG",
      "origemProspeccao": this.aceitePoliticaUso_FormGroup.value.origemCadastro,
    }

    console.log(objSalveNew);

    let endPoint = 'userApp';
    let metodo = 'POST';

    this.caminhoeiroService.putNewUser( endPoint , metodo  , objSalveNew ).
      subscribe(( response : any) => {

        //console.log(response);

          //let retornoWS = JSON.parse(response);
          if( response.sucessCode === 200 ){

            var msgUserApresent = "";

            msgUserApresent = "Obrigado, seu cadastro foi recebido e encaminhado para um de nossos analistas que entrará em contato."

                //Alimento a variavel que direciona o caminho onde salvará
                this.configService.dbPath = environment.caminhoRealtimeMotoristas;
                this.configService.nofirebaseRef = this.firebaseRealtime.list(environment.caminhoRealtimeMotoristas);

                //this.configService.deleteformsfirebase('cadastros_caminhoneiros' ,  this.removeCaracCPFandCNJP( this.cpf_cnpj_FormGroup.get("cpfOrCpnj").value) ).subscribe(( response : any) => {
                this.configService.delete(this.cpf_cnpj_FormGroup.get("cpfOrCpnj").value)
                .then(() => {
                  console.log('Deletetado do firebase' , new Date())
                  this.noTemporario = true;
                  this.router.navigate(['final']);

                  setTimeout(() => {

                    if (this.isApp){
                      this.document.location.href = environment.url_app_transportador;
                    }else{
                      this.document.location.href = environment.url_transportador;
                    }


                    //this.router.navigate(['final']);
                  }, 3200);

                  this.openSnackBar('Sucesso! ' + msgUserApresent , 'x' ,  'msgSucesso' )

                  })
                  .catch(err => {console.log(err)
                    console.log('Erro carregar deleteformsfirebase ', err)
                    this.router.navigate(['inicio']);
                    this.openSnackBar('Erro! ao excluir firebase' , 'x' ,  'msgErro' )
                    this.loading('hide')
                  });

          }else{

          console.log("Error putAPI" ,  response.message )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.message   , 'x' ,  'msgErro' )
          this.loading('hide')
        }


    }, (erro) => {
      console.log('Erro ao gravar dados no protheus - getAPIProtheus ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.SalvarForms('3');
      this.loading('hide')
    });

  }

  limpaReboquesNaoMarcados(){
    const tipoValue = this.dadosVeiculo_FormGroup.get("tipo_DadosVeiculo").value
    if(tipoValue !== null ){
      let index = this.tiposVeiculos.findIndex(codigo => {
        return (new RegExp('\^' + codigo.codigo + '\$')).test(tipoValue);
      });
      if(this.tiposVeiculos[index].categoria === '2'){
        if(!this.dadosVeiculo_FormGroup.get("reboque1_DadosVeiculo").value){
          this.dadosVeiculo_FormGroup.get('reboque1_Propriedade_DadosVeiculo').setValue( 'Proprio' )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_CpfCnpj_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_ie_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_razaosocial_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_nomefantasia_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_cep_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_endereco_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_bairro_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_estado_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_cidade_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_pais_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_email_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_DDI_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_DDD_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_telefone_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_Proprietario_RNTRC_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_placa_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('carrocReboque1_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_tipoVeiculo_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque1_IMGCRLV_DadosVeiculo').setValue(null)

        }
        if(!this.dadosVeiculo_FormGroup.get("reboque2_DadosVeiculo").value){
          this.dadosVeiculo_FormGroup.get('reboque2_Propriedade_DadosVeiculo').setValue( 'Proprio' )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_CpfCnpj_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_ie_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_razaosocial_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_nomefantasia_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_cep_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_endereco_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_bairro_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_estado_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_cidade_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_pais_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_email_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_DDI_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_DDD_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_telefone_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_Proprietario_RNTRC_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_placa_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_tipoVeiculo_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('carrocReboque2_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque2_IMGCRLV_DadosVeiculo').setValue(null)
        }
        if(!this.dadosVeiculo_FormGroup.get("reboque3_DadosVeiculo").value){
          this.dadosVeiculo_FormGroup.get('reboque3_Propriedade_DadosVeiculo').setValue( 'Proprio' )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_CpfCnpj_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_ie_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_razaosocial_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_nomefantasia_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_cep_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_endereco_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_bairro_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_estado_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_cidade_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_pais_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_email_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_DDI_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_DDD_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_telefone_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_Proprietario_RNTRC_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_placa_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('reboque3_IMGCRLV_DadosVeiculo').setValue(null)
          this.dadosVeiculo_FormGroup.get('reboque3_tipoVeiculo_DadosVeiculo').setValue( null )
          this.dadosVeiculo_FormGroup.get('carrocReboque3_DadosVeiculo').setValue( null )
        }
      }
    }
  }

  validPlaca( campo: any ){

    this.loading('show');

    const placa = this.dadosVeiculo_FormGroup.get(campo).value

    if( placa === null || placa === '' ){
      this.loading('hide')
      return
    }

    this.caminhoeiroService.getConsultaPlaca(placa).
    subscribe(( response : any) => {
      let datasetReturn = response

      if( datasetReturn.status === "success" ){

        if( datasetReturn.retorno.values !== undefined ){

          let valuesReturn = datasetReturn.retorno.values.length === undefined ? datasetReturn.retorno.values : datasetReturn.retorno.values[0]

          //existe veiculo
          if(valuesReturn.value[0].$value !== 'ERRO Dataset' || valuesReturn.value[0].$value !== 'Erro' ){
            this.openSnackBar('Veiculo já esta em uso e cadastrado na Bialog. Contate nosso suporte para maiores detalhes', 'x' ,  'msgErro' )
            this.dadosVeiculo_FormGroup.get(campo).setValue( null )
            this.loading('hide')
          }else{
            console.log('Erro carregar getConsultaPlaca ', valuesReturn.value[1].$value)
            this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + valuesReturn.value[1].$value , 'x' ,  'msgErro' )
            this.dadosVeiculo_FormGroup.get(campo).setValue( null )
            this.loading('hide')
          }
        }else{
          //validado Não Existe
          this.loading('hide')
        }

      }else{
        console.log('Erro carregar getConsultaPlaca'  , datasetReturn.retorno )
        this.isOnChanged_cpfCnpj = false;
        this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ', 'x' ,  'msgErro' )
        this.loading('hide')
      }

    }, (erro) => {
       console.log('Erro carregar getConsultaPlaca ', erro)
       this.isOnChanged_cpfCnpj = false;
       this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
       this.loading('hide')
    });

  }

  validANTT() {

    this.loadingValidRNTRC = true ;

    let cgc = this.dadosProprietarioVeiculo_FormGroup.get("cpfOrCpnj_ProprietarioVeiculo").value
    let RNTRC = this.dadosProprietarioVeiculo_FormGroup.get("RNTRC_ProprietarioVeiculo").value ;

    if( RNTRC === null || RNTRC.length === 0 || RNTRC === "" ){
      this.isValid_ANTT_Proprietario = true
      this.loadingValidRNTRC = false ;
      return
    }

    let endPoint = 'WSBIALOG_TMS_CADSITE/ANTT/'
    let metodo = 'POST'
    let body = {
                  "A2_CGC": cgc ,
                  "A2_RNTRC": RNTRC
              }


    this.caminhoeiroService.getAPIProtheus( endPoint , metodo  , body ).
      subscribe(( response : any) => {

        if(response.status === "success" ){

          if( response.retorno.values.value[0].$value === "SUCCESS" ){

            let retornoJSON = JSON.parse(response.retorno.values.value[1].$value)

            if(retornoJSON.success && retornoJSON.sucessCode === 200 ){
              this.isValid_ANTT_Proprietario = true;
              this.loadingValidRNTRC = false ;
            }else{
              this.openSnackBar('Atenção! ' +  retornoJSON.sucessMessage  , 'x' ,  'msgErro' )
              this.loading('hide')
              this.isValid_ANTT_Proprietario = false;
              this.loadingValidRNTRC = false ;
            }

          }else{
            console.log("Error getAPIProtheus" ,  response.retorno.values.value[1].$value  )
            this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.retorno.values.value[1].$value   , 'x' ,  'msgErro' )
            this.isValid_ANTT_Proprietario = false;
            this.loadingValidRNTRC = false ;
          }
        }else{
          console.log("Error getAPIProtheus" ,  response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.msg   , 'x' ,  'msgErro' )
          this.isValid_ANTT_Proprietario = false;
          this.loadingValidRNTRC = false ;
        }


    }, (erro) => {
      console.log('Erro ao gravar dados no protheus - getAPIProtheus ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.isValid_ANTT_Proprietario = false;
      this.loadingValidRNTRC = false ;
    });


  }

  validANTTReboque1() {

    this.loadingValidRNTRC = true ;

    let cgc = this.dadosVeiculo_FormGroup.get("reboque1_Proprietario_CpfCnpj_DadosVeiculo").value
    let RNTRC = this.dadosVeiculo_FormGroup.get("reboque1_Proprietario_RNTRC_DadosVeiculo").value ;

    if( RNTRC === null  || RNTRC.length === 0 || RNTRC === ""  ){
      this.isValid_ANTT_Proprietario_reboque1 = true
      this.loadingValidRNTRC = false ;
      return
    }

    let endPoint = 'WSBIALOG_TMS_CADSITE/ANTT/'
    let metodo = 'POST'
    let body = {
                  "A2_CGC": cgc ,
                  "A2_RNTRC": RNTRC
              }


    this.caminhoeiroService.getAPIProtheus( endPoint , metodo  , body ).
      subscribe(( response : any) => {

        if(response.status === "success" ){

          if( response.retorno.values.value[0].$value === "SUCCESS" ){

            let retornoJSON = JSON.parse(response.retorno.values.value[1].$value)

            if(retornoJSON.success && retornoJSON.sucessCode === 200 ){
              this.isValid_ANTT_Proprietario_reboque1 = true;
              this.loadingValidRNTRC = false ;
            }else{
              this.openSnackBar('Atenção! ' +  retornoJSON.sucessMessage  , 'x' ,  'msgErro' )
              this.loading('hide')
              this.isValid_ANTT_Proprietario_reboque1 = false;
              this.loadingValidRNTRC = false ;
            }

          }else{
            console.log("Error getAPIProtheus" ,  response.retorno.values.value[1].$value  )
            this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.retorno.values.value[1].$value   , 'x' ,  'msgErro' )
            this.isValid_ANTT_Proprietario_reboque1 = false;
            this.loadingValidRNTRC = false ;
          }
        }else{
          console.log("Error getAPIProtheus" ,  response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.msg   , 'x' ,  'msgErro' )
          this.isValid_ANTT_Proprietario_reboque1 = false;
          this.loadingValidRNTRC = false ;
        }


    }, (erro) => {
      console.log('Erro ao gravar dados no protheus - getAPIProtheus ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.isValid_ANTT_Proprietario_reboque1 = false;
      this.loadingValidRNTRC = false ;
    });


  }


  validANTTReboque2() {

    this.loadingValidRNTRC = true ;

    let cgc = this.dadosVeiculo_FormGroup.get("reboque2_Proprietario_CpfCnpj_DadosVeiculo").value
    let RNTRC = this.dadosVeiculo_FormGroup.get("reboque2_Proprietario_RNTRC_DadosVeiculo").value ;

    if( RNTRC === null || RNTRC.length === 0 || RNTRC === ""  ){
      this.isValid_ANTT_Proprietario_reboque2 = true
      this.loadingValidRNTRC = false ;
      return
    }

    let endPoint = 'WSBIALOG_TMS_CADSITE/ANTT/'
    let metodo = 'POST'
    let body = {
                  "A2_CGC": cgc ,
                  "A2_RNTRC": RNTRC
              }


    this.caminhoeiroService.getAPIProtheus( endPoint , metodo  , body ).
      subscribe(( response : any) => {

        if(response.status === "success" ){

          if( response.retorno.values.value[0].$value === "SUCCESS" ){

            let retornoJSON = JSON.parse(response.retorno.values.value[1].$value)

            if(retornoJSON.success && retornoJSON.sucessCode === 200 ){
              this.isValid_ANTT_Proprietario_reboque2 = true;
              this.loadingValidRNTRC = false ;
            }else{
              this.openSnackBar('Atenção! ' +  retornoJSON.sucessMessage  , 'x' ,  'msgErro' )
              this.loading('hide')
              this.isValid_ANTT_Proprietario_reboque2 = false;
              this.loadingValidRNTRC = false ;
            }

          }else{
            console.log("Error getAPIProtheus" ,  response.retorno.values.value[1].$value  )
            this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.retorno.values.value[1].$value   , 'x' ,  'msgErro' )
            this.isValid_ANTT_Proprietario_reboque2 = false;
            this.loadingValidRNTRC = false ;
          }
        }else{
          console.log("Error getAPIProtheus" ,  response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.msg   , 'x' ,  'msgErro' )
          this.isValid_ANTT_Proprietario_reboque2 = false;
          this.loadingValidRNTRC = false ;
        }


    }, (erro) => {
      console.log('Erro ao gravar dados no protheus - getAPIProtheus ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.isValid_ANTT_Proprietario_reboque2 = false;
      this.loadingValidRNTRC = false ;
    });


  }


  validANTTReboque3() {

    this.loadingValidRNTRC = true ;

    let cgc = this.dadosVeiculo_FormGroup.get("reboque3_Proprietario_CpfCnpj_DadosVeiculo").value
    let RNTRC = this.dadosVeiculo_FormGroup.get("reboque3_Proprietario_CpfCnpj_DadosVeiculo").value ;

    if( RNTRC === null  || RNTRC.length === 0 || RNTRC === ""  ){
      this.isValid_ANTT_Proprietario_reboque3 = true
      this.loadingValidRNTRC = false ;
      return
    }

    let endPoint = 'WSBIALOG_TMS_CADSITE/ANTT/'
    let metodo = 'POST'
    let body = {
                  "A2_CGC": cgc ,
                  "A2_RNTRC": RNTRC
              }


    this.caminhoeiroService.getAPIProtheus( endPoint , metodo  , body ).
      subscribe(( response : any) => {

        if(response.status === "success" ){

          if( response.retorno.values.value[0].$value === "SUCCESS" ){

            let retornoJSON = JSON.parse(response.retorno.values.value[1].$value)

            if(retornoJSON.success && retornoJSON.sucessCode === 200 ){
              this.isValid_ANTT_Proprietario_reboque3 = true;
              this.loadingValidRNTRC = false ;
            }else{
              this.openSnackBar('Atenção! ' +  retornoJSON.sucessMessage  , 'x' ,  'msgErro' )
              this.loading('hide')
              this.isValid_ANTT_Proprietario_reboque3 = false;
              this.loadingValidRNTRC = false ;
            }

          }else{
            console.log("Error getAPIProtheus" ,  response.retorno.values.value[1].$value  )
            this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.retorno.values.value[1].$value   , 'x' ,  'msgErro' )
            this.isValid_ANTT_Proprietario_reboque3 = false;
            this.loadingValidRNTRC = false ;
          }
        }else{
          console.log("Error getAPIProtheus" ,  response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.msg   , 'x' ,  'msgErro' )
          this.isValid_ANTT_Proprietario_reboque3 = false;
          this.loadingValidRNTRC = false ;
        }


    }, (erro) => {
      console.log('Erro ao gravar dados no protheus - getAPIProtheus ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.isValid_ANTT_Proprietario_reboque3 = false;
      this.loadingValidRNTRC = false ;
    });

  }


  validPAMCARD() {

    this.loadingValidPAMCARD = true ;

    let cgc = this.dadosMotorista_FormGroup.get("cpfOrCpnj_DadosMotorista").value
    let PAMCARD = this.dadosMotorista_FormGroup.get("numero_PAMCARD_DadosMotorista").value ;

    if( PAMCARD === null ||PAMCARD.length === 0 || PAMCARD === ""   ){
      this.isValid_PAMCARD = true
      this.loadingValidPAMCARD = false ;
      return
    }

    let endPoint = 'WSBIALOG_TMS_CADSITE/PAMCARD/'
    let metodo = 'POST'
    let body = {
                  "DA4_CGC": cgc ,
                  "DEL_IDOPE": PAMCARD
              }


    this.caminhoeiroService.getAPIProtheus( endPoint , metodo  , body ).
      subscribe(( response : any) => {

        if(response.status === "success" ){

          if( response.retorno.values.value[0].$value === "SUCCESS" ){

            let retornoJSON = JSON.parse(response.retorno.values.value[1].$value)

            if(retornoJSON.success && retornoJSON.sucessCode === 200 ){
              this.isValid_PAMCARD = true
              this.loadingValidPAMCARD = false ;
            }else{
              this.openSnackBar('Atenção! ' +  retornoJSON.sucessMessage  , 'x' ,  'msgErro' )
              this.loading('hide')
              this.isValid_PAMCARD = false
              this.loadingValidPAMCARD = false ;
            }

          }else{
            console.log("Error getAPIProtheus" ,  response.retorno.values.value[1].$value  )
            this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.retorno.values.value[1].$value   , 'x' ,  'msgErro' )
            this.isValid_PAMCARD = false
              this.loadingValidPAMCARD = false ;
          }
        }else{
          console.log("Error getAPIProtheus" ,  response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.msg   , 'x' ,  'msgErro' )
          this.isValid_PAMCARD = false
          this.loadingValidPAMCARD = false ;
        }


    }, (erro) => {
      console.log('Erro ao gravar dados no protheus - getAPIProtheus ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.isValid_PAMCARD = false
      this.loadingValidPAMCARD = false ;
    });


  }

  validNomesPais(paioumae: string) {

    let meuNome = this.dadosMotorista_FormGroup.get("nome_RazaoSocial_DadosMotorista").value

    if(paioumae === 'PAI'){
      let nomeParente = this.dadosMotorista_FormGroup.get("nomePai_DadosMotorista").value
      if( !this.fnVerificaNome(meuNome, nomeParente) ){
        this.openSnackBar('Atenção! Sobrenome do Pai ou Mãe não conferem com o Sobrenome do Motorista!' , 'x' ,  'msgErro' )
        this.dadosMotorista_FormGroup.get('nomePai_DadosMotorista').setValue( null )
      }
    }else if(paioumae === 'MAE'){
      let nomeParente = this.dadosMotorista_FormGroup.get("nomeMae_DadosMotorista").value
      if( !this.fnVerificaNome(meuNome, nomeParente) ){
        this.openSnackBar('Atenção! Sobrenome do Pai ou Mãe não conferem com o Sobrenome do Motorista!' , 'x' ,  'msgErro' )
        this.dadosMotorista_FormGroup.get('nomeMae_DadosMotorista').setValue( null )
      }
    }else if(paioumae === 'NOME'){
      let nomeParente = this.dadosMotorista_FormGroup.get("nomePai_DadosMotorista").value
      if( !this.fnVerificaNome(meuNome, nomeParente) ){
        this.openSnackBar('Atenção! Sobrenome do Pai ou Mãe não conferem com o Sobrenome do Motorista!' , 'x' ,  'msgErro' )
        this.dadosMotorista_FormGroup.get('nomePai_DadosMotorista').setValue( null )
      }else{
        let nomeParente = this.dadosMotorista_FormGroup.get("nomeMae_DadosMotorista").value
        if( !this.fnVerificaNome(meuNome, nomeParente) ){
          this.openSnackBar('Atenção! Sobrenome do Pai ou Mãe não conferem com o Sobrenome do Motorista!' , 'x' ,  'msgErro' )
          this.dadosMotorista_FormGroup.get('nomeMae_DadosMotorista').setValue( null )
        }
      }

    }

  }


  //*********** Funcoes

  abrirANTT(){


    const dialogRef = this.dialog.open(  IframeDialogComponent ,{
      data:{
        urlForIframe : 'https://consultapublica.antt.gov.br/Site/ConsultaRNTRC.aspx/consultapublica' ,
        descripitonButtonClose : 'Fechar Consulta'
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });

    //window.open('https://consultapublica.antt.gov.br/Site/ConsultaRNTRC.aspx/consultapublica', "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=2600,width=600,height=600" );

    // Funçao para voltar no botão de voltar APP
    window['fnVoltarPeloApp'] = function(){
      this.window.close();
    }

    dialogRef.afterClosed().subscribe( () => {

      // Funçao para voltar no botão de voltar APP
      window['fnVoltarPeloApp'] = function(){
        window.location.href = 'https://'+ window.location.host  + '/inicio'
      }

    });

  }

  changeNomeProp(){
    this.dadosProprietarioVeiculo_FormGroup.get("nome_NomeFantasia_ProprietarioVeiculo").setValue(  this.dadosProprietarioVeiculo_FormGroup.get("nome_RazaoSocial_ProprietarioVeiculo").value.split(' ')[0]   )
  }

  changeNomeFantasia(){
    this.toHighlightNomeFantasia = this.dadosProprietarioVeiculo_FormGroup.get("nome_NomeFantasia_ProprietarioVeiculo").value
    if (this.toHighlightNomeFantasia.search(/[&\/\\#,+()$~%.'":*?<>{}@]/) > 0){
      this.toHighlightNomeFantasia = this.toHighlightNomeFantasia.replace(/[&\/\\#,+()$~%.'":*?<>{}@]/g,'');
    }
    if (this.toHighlightNomeFantasia.search(/\"*"/) < 0){
      this.toHighlightNomeFantasia = this.toHighlightNomeFantasia.replace(/[&\/\\#,+()$~%.'":*?<>{}@]/g,'');
    }
    this.dadosProprietarioVeiculo_FormGroup.get("nome_NomeFantasia_ProprietarioVeiculo").setValue(this.removeCharsEspecial( this.toHighlightNomeFantasia , false , true , false));

    if (this.dadosProprietarioVeiculo_FormGroup.get("nome_NomeFantasia_ProprietarioVeiculo").value == ''){
        this.dadosProprietarioVeiculo_FormGroup.get("nome_NomeFantasia_ProprietarioVeiculo").setValue(this.removeCharsEspecial( this.dadosProprietarioVeiculo_FormGroup.get("nome_RazaoSocial_ProprietarioVeiculo").value.substr(0 , 20), false , true , false));
      //this.dadosMotorista_FormGroup.get("nome_RazaoSocial_DadosMotorista").value.substr(0 , 20);
    }
  }

  changeTelContato(){
    if(this.cpf_cnpj_FormGroup.get("tipo_cadastro").value == 'proprietarioEmotorista'){
      //Gravo o telefone no campo correto
      this.dadosProprietarioVeiculo_FormGroup.get("DDD_ProprietarioVeiculo").setValue(this.cpf_cnpj_FormGroup.get("dddcontato").value);
      this.dadosProprietarioVeiculo_FormGroup.get("telefone_ProprietarioVeiculo").setValue(this.cpf_cnpj_FormGroup.get("telefonecontato").value);
      //Gravo no motorista também, afinal ele é autonomo.
      this.dadosMotorista_FormGroup.get("DDD_DadosMotorista").setValue(this.cpf_cnpj_FormGroup.get("dddcontato").value);
      this.dadosMotorista_FormGroup.get("telefone_DadosMotorista").setValue(this.cpf_cnpj_FormGroup.get("telefonecontato").value);
    }else if(this.cpf_cnpj_FormGroup.get("tipo_cadastro").value == 'motorista'){
      //Gravo o telefone no campo correto de motorista
      this.dadosMotorista_FormGroup.get("DDD_DadosMotorista").setValue(this.cpf_cnpj_FormGroup.get("dddcontato").value);
      this.dadosMotorista_FormGroup.get("telefone_DadosMotorista").setValue(this.cpf_cnpj_FormGroup.get("telefonecontato").value);
      //Apago o campo telefone do proprietário caso ele tenha selecionado essa opção anteriormente
       this.dadosProprietarioVeiculo_FormGroup.get("DDD_ProprietarioVeiculo").setValue('');
       this.dadosProprietarioVeiculo_FormGroup.get("telefone_ProprietarioVeiculo").setValue('');
    }

  }

  changeNomeMotora(){
    this.dadosMotorista_FormGroup.get("nome_NomeFantasia_DadosMotorista").setValue(  this.dadosMotorista_FormGroup.get("nome_RazaoSocial_DadosMotorista").value.split(' ')[0]   )
  }

  abrirTermos(){
    this.clickTermosLink = true
    window.open('https://bialog.com.br/politica-de-privacidade/', "_blank" );
    /*
    const dialogRef = this.dialog.open(  IframeDialogComponent ,{
      data:{
        urlForIframe : 'https://firebasestorage.googleapis.com/v0/b/transportadorbialog.appspot.com/o/politica_uso_app%2Fpolitica_privacidade_app_bialog.pdf?alt=media&token=832d9460-4663-4bf1-b7db-9c7d38c5a8f8' ,
        //'https://fruteb.fluig.com/volume/stream/Rmx1aWc=/P3Q9MSZ2b2w9RGVmYXVsdCZpZD0yMjYwJnZlcj0xMDAwJmZpbGU9UG9sJUMzJUFEdGljYStQcml2YWNpZGFkZStBcHAucG5nJmNyYz0yNTA1MTU3NjYyJnNpemU9MC45NzY1NTcmdUlkPTMmZlNJZD0xJnVTSWQ9MSZkPWZhbHNlJnRrbj0mcHVibGljVXJsPXRydWU=.png' ,
        descripitonButtonClose : 'Fechar Política'
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });*/

    // Funçao para voltar no botão de voltar APP
    window['fnVoltarPeloApp'] = function(){
      this.window.close();
    }

    /*dialogRef.afterClosed().subscribe( () => {

      // Funçao para voltar no botão de voltar APP
      window['fnVoltarPeloApp'] = function(){
        window.location.href = 'https://'+ window.location.host  + '/inicio'
      }

    });*/

  }

  loadPaises(){

    this.caminhoeiroService.getPaises().subscribe(( response) => {
        this.retornoPaises = response
        if(this.retornoPaises.status === "error"){
          console.log('Erro carregar getPaises ', this.retornoPaises.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + this.retornoPaises.msg , 'x' ,  'msgErro' )
          return
        }
        this.paises = []
        for (let index = 0; index < this.retornoPaises.retorno.values.length; index++) {
          let objTemp = {
                          "codigo" : this.retornoPaises.retorno.values[index].value[0].$value ,
                          "name": this.retornoPaises.retorno.values[index].value[1].$value ,
                          "continente": this.retornoPaises.retorno.values[index].value[2].$value ,
                          "DDI": this.retornoPaises.retorno.values[index].value[3].$value ,
                          "codBacen": this.retornoPaises.retorno.values[index].value[4].$value
                        }
          this.paises.push(objTemp)
        }
    }, (erro) => {
      console.log('Erro carregar getPaises ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
    });

  }

  loadCarroceria(){

    this.caminhoeiroService.getTipoCarroceria().subscribe(( response : any ) => {
        if( response.status === "error"){
          console.log('Erro carregar getTipoCarroceria ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }
        this.carrocerias = []
        for (let index = 0; index < response.retorno.values.length; index++) {
          let objTemp = {
                          "codigo" :  response.retorno.values[index].value[0].$value ,
                          "name":  response.retorno.values[index].value[1].$value ,
                        }
          this.carrocerias.push(objTemp)
        }
    }, (erro) => {
      console.log('Erro carregar loadCarroceria ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
    });

  }

  loadCores(){

    this.caminhoeiroService.getCores().subscribe(( response : any ) => {
        if( response.status === "error"){
          console.log('Erro carregar getCores ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }
        this.cores = []
        for (let index = 0; index < response.retorno.values.length; index++) {
          let objTemp = {
                          "codigo" :  response.retorno.values[index].value[0].$value ,
                          "name":  response.retorno.values[index].value[1].$value ,
                        }
          this.cores.push(objTemp)
        }
    }, (erro) => {
      console.log('Erro carregar getCores ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
    });

  }

  loadMarcas(){

    this.caminhoeiroService.getMarcas().subscribe(( response : any ) => {
        if( response.status === "error"){
          console.log('Erro carregar getMarcas ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }
        this.marcas = []
        for (let index = 0; index < response.retorno.values.length; index++) {
          let objTemp = {
                          "codigo" :  response.retorno.values[index].value[0].$value ,
                          "name":  response.retorno.values[index].value[1].$value ,
                        }
          this.marcas.push(objTemp)
        }
    }, (erro) => {
      console.log('Erro carregar getMarcas ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
    });

  }

  async loadTipoRodado(){

    const response : any =  await this.caminhoeiroService.getConsultaTipoVeiculo().toPromise()
    if(response){
      if( response.success === false){
        console.log('Erro carregar loadTipoRodado ', response.msg )
        this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
        return
      }
      this.tiposVeiculos = []
      let tipCategoria;

      for (let index = 0; index < response.content.length; index++) {


        let objTemp = {
                        "codigo" :  response.content[index].X5_CHAVE ,
                        "name":  response.content[index].X5_DESCRI ,
                        "categoria":  response.content[index].X5_DESCENG ,
                      }
        this.tiposVeiculos.push(objTemp)
        tipCategoria = "";
      }

    }else{
      console.log('Erro carregar getTipoRodado ')
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... '  , 'x' ,  'msgErro' )
    }


  }

  removeCaracCPFandCNJP( cpforcnpj : string ){
    return cpforcnpj.replace(new RegExp("[./-]", 'gi'),"")
  }

  loading( action : string ){
    switch (action) {
      case 'show':
        this.isloading = false
        break
      case 'hide':
        this.isloading = true
        break
      default:
        this.isloading = true
    }

    return true
  }

  private delay(ms: number): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, ms);
    });
  }

  openSnackBar(message: string, action: string , classtype: string ) {
    let config = new MatSnackBarConfig();
    config.panelClass = [classtype];
    classtype === 'msgSucesso' ? config.duration = 5000 : config.duration = 15000
    this.snackBar.open(message, action ,config);
  }

 forbiddenNamesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = this.cidades.findIndex(name => {
        return (new RegExp('\^' + name.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
    };
  }

  forbiddenNamesMotoristaValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = this.cidadesMotorista.findIndex(nameCidadeMotorista => {
        return (new RegExp('\^' + nameCidadeMotorista.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNamesMotorista': { value: control.value } } : null;
    };
  }

  forbiddenNamesMotoristaCNHValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = this.cidadesMotoristaCNH.findIndex(nameCidadeMotoristaCNH => {
        return (new RegExp('\^' + nameCidadeMotoristaCNH.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNamesMotoristaCNH': { value: control.value } } : null;
    };
  }

  forbiddenNamesPlacaValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = this.cidadesPlaca.findIndex(filteredCidadesPlaca => {
        return (new RegExp('\^' + filteredCidadesPlaca.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNamesPlaca': { value: control.value } } : null;
    };
  }

  forbiddenNamesPlacaReboque1Validator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = this.cidadesPlacaReboque1.findIndex(filteredCidadesPlacaReboque1 => {
        return (new RegExp('\^' + filteredCidadesPlacaReboque1.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNamesPlacaReboque1': { value: control.value } } : null;
    };
  }

  forbiddenNamesPlacaReboque2Validator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = this.cidadesPlacaReboque2.findIndex(filteredCidadesPlacaReboque2 => {
        return (new RegExp('\^' + filteredCidadesPlacaReboque2.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNamesPlacaReboque2': { value: control.value } } : null;
    };
  }

  forbiddenNamesPlacaReboque3Validator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = this.cidadesPlacaReboque3.findIndex(filteredCidadesPlacaReboque3 => {
        return (new RegExp('\^' + filteredCidadesPlacaReboque3.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNamesPlacaReboque3': { value: control.value } } : null;
    };
  }

  forbiddenNamesPropReboque1Validator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = this.cidadesProprietarioReboque1.findIndex(name => {
        return (new RegExp('\^' + name.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNamesProprietarioReboque1': { value: control.value } } : null;
    };
  }

  forbiddenNamesPropReboque2Validator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = this.cidadesProprietarioReboque2.findIndex(name => {
        return (new RegExp('\^' + name.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNamesProprietarioReboque2': { value: control.value } } : null;
    };
  }

  forbiddenNamesPropReboque3Validator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = this.cidadesProprietarioReboque3.findIndex(name => {
        return (new RegExp('\^' + name.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNamesProprietarioReboque3': { value: control.value } } : null;
    };
  }

  public removeCharsEspecial(especialChar : string , removeSpace : boolean , upperCase : boolean , fristLowerCase : boolean ){
    removeSpace = removeSpace === undefined ? false : removeSpace
    upperCase   = upperCase === undefined ? false : upperCase
    fristLowerCase   = fristLowerCase === undefined ? false : fristLowerCase
    especialChar = fristLowerCase ? especialChar.toLowerCase() : especialChar
    especialChar = especialChar.replace(new RegExp("[àáâãäå]", 'gi'),"a");
    especialChar = especialChar.replace(new RegExp("æ", 'gi'),"ae");
    especialChar = especialChar.replace(new RegExp("ç", 'gi'),"c");
    especialChar = especialChar.replace(new RegExp("[èéêë]", 'gi'),"e");
    especialChar = especialChar.replace(new RegExp("[ìíîï]", 'gi'),"i");
    especialChar = especialChar.replace(new RegExp("ñ", 'gi'),"n");
    especialChar = especialChar.replace(new RegExp("[òóôõö]", 'gi'),"o");
    especialChar = especialChar.replace(new RegExp("œ", 'gi'),"oe");
    especialChar = especialChar.replace(new RegExp("[ùúûü]", 'gi'),"u");
    especialChar = especialChar.replace(new RegExp("[ýÿ]", 'gi'),"y");
    //tratativa para maiusculas
    especialChar = especialChar.replace(new RegExp("[ÀÁÂÃÄÅ]", 'gi'),"A");
    especialChar = especialChar.replace(new RegExp("Æ", 'gi'),"AE");
    especialChar = especialChar.replace(new RegExp("Ç", 'gi'),"C");
    especialChar = especialChar.replace(new RegExp("[ÈÉÊË]", 'gi'),"E");
    especialChar = especialChar.replace(new RegExp("[ÌÍÎÏ]", 'gi'),"I");
    especialChar = especialChar.replace(new RegExp("Ñ", 'gi'),"N");
    especialChar = especialChar.replace(new RegExp("[ÒÓÔÕÖ]", 'gi'),"O");
    especialChar = especialChar.replace(new RegExp("Œ", 'gi'),"OE");
    especialChar = especialChar.replace(new RegExp("[ÙÚÛÜ]", 'gi'),"U");
    especialChar = especialChar.replace(new RegExp("[ÝŸ]", 'gi'),"Y");

    if(removeSpace){
      especialChar = especialChar.replace(new RegExp("\\s", 'gi'),"");
      especialChar = especialChar.replace(new RegExp("\\W", 'gi'),"");
    }
    especialChar = upperCase ? especialChar.toUpperCase() : especialChar
    return especialChar;
  }

  fnVerificaNome(meuNome: string, nomeParente: string){
    var lok = false;
    var tamMeuPare = nomeParente.trim().split(" ");
    var tamMeuNome = meuNome.trim().split(" ");
    for (var i=0; i< tamMeuNome.length; i++) {
      if(tamMeuNome[i].length > 3 && i != 0){
        for (var a=0; a< tamMeuPare.length; a++) {
          if(tamMeuPare[a].length > 3 && a != 0){
            if(tamMeuNome[i] == tamMeuPare[a]){
              lok = true;
            }
          }
        }
      }
    }
    return lok;
  }


  //fim funcoes

}



@Pipe({ name: 'highlight'  })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string): string {
    const pattern = search
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
      .split(' ')
      .filter(t => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');

    return search ? text.replace(regex, match => `<b>${match}</b>`) : text;
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent?.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}


function CaminhoneiroDialogImagensCnhComponent(CaminhoneiroDialogImagensCnhComponent: any, arg1: { data: { urlImagem: any; }; }) {
  throw new Error('Function not implemented.');
}



