export const environment = {
  production: true,
  firebase_realtime : 'ambiente_producao',
  caminhoRealtimeMotoristas : '/ambiente_producao/cadastros_caminhoneiros/',
  caminhoRealtimeEmpresa : '/ambiente_producao/cadastros_empresas/',
  url_fluig : 'https://portal.bialog.com.br',
  url_transportador : 'https://transportadorbialog.web.app/',
  url_app_transportador : 'https://bialogfretes.page.link/app/?link=https://transportadorbialog.web.app?%26acao=LOGAR&apn=br.com.bialogfretes',
  firebaseConfig : {
    apiKey: "AIzaSyAN263vZKj87ZvzkpBYy50q1t4Gj2Q9q_E",
    authDomain: "monitoramento-viagem.firebaseapp.com",
    databaseURL: "https://bialog-cadastro-seguro.firebaseio.com/",
    projectId: "monitoramento-viagem",
    storageBucket: "monitoramento-viagem.appspot.com",
    messagingSenderId: "520148977608",
    appId: "1:520148977608:web:1e16ab144e96064d0e22fd",
    measurementId: "G-TQ13WHEYSH"
  },
  sufix : '@cadastrobialog.com',
  sufix2 : 'authBia301*092-031',
  TOKEN_sintegraws : '24A7F21C-9E44-4BB4-A084-57AF203E3F6C',
  URL_API_NODE_GOOGLEAPP : 'https://monitoramento-viagem.rj.r.appspot.com',
  URL_WSDL_FLUIG :'https://portal.bialog.com.br/webdesk/ECMDatasetService?wsdl',
  URL_WS_PROTHEUS : 'http://bialogtransporte130495.protheus.cloudtotvs.com.br:4050/REST',
  URL_API_BACKEND : 'https://backend.bialog.com.br/v1/',
  URL_PORTAL_CLI : 'https://portal-tomador.bialog.com.br',
  URL_PORTAL_MOT : 'https://transportadorbialog.web.app'
};
