import { Component } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from './helpers/config.service';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  linkInicial = ""

 itensMenu = [];

  title = 'cadastros-bialog-angular';
  isApp = false;
  isEnvTeste = false ;
  linkLoginTst = "https://portaltst.bialog.com.br/portal/1/wgPortalPublico";

  constructor(  private configService : ConfigService ) {
    this.isApp = this.configService.isAppCall();
    this.isEnvTeste = environment.firebase_realtime === 'ambiente_testes' ? true : false
    this.linkInicial = 'https://bialog.com.br/';
  }

  goToLink(url: string){
    window.open(url);
    return false;
  }

  goToHome(url: string){
    location.href = url
    return false;
  }

}
