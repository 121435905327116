<html lang="pt-br">
  <head>

    <!-- Global site tag (gtag.js) - Google Analytics -->

    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-156363140-2"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-156363140-2');

    </script>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet">
  </head>
  <body>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
<div fxLayout="row" fxLayoutAlign="center center">
  <h2 class="titleHome" fxLayoutAlign="center center">Faça seu cadastro</h2>
</div>

<div
  fxLayout="column"
  fxLayout.gt-sm="row"
  fxLayoutAlign="start center"
  fxLayoutAlign.gt-sm="center start"
>
  <div fxFlex="50%" fxFlex.gt-sm="30%" style="text-align: center;">
    <h1 class="subtitle">Como Empresa</h1>
    <h3 class="subtitle">Encontre o motorista ideal para transportar sua carga.</h3>
    <button href="#" (click)="goToLink(this.linkCadastroCli)"  class="butcadastr" mat-raised-button>Cadastre-se <mat-icon class="buticon" >emoji_transportation_outlined</mat-icon></button>
  </div>
  <div class="linha-vertical" fxHide.lt-md></div>
  <div class="linha-horizontal" fxHide.gt-sm></div>
  <div fxFlex="50%" fxFlex.gt-sm="30%" style="text-align: center;">
    <h1 class="subtitle">Como Transportador</h1>
    <h3 class="subtitle">Encontre a carga ideal para ser transportada por você.</h3>
    <button href="#" (click)="goToLink(this.linkCadastroMot)" class="butcadastr"  mat-raised-button>Cadastre-se<mat-icon class="buticon"   >local_shipping_outlined</mat-icon></button>
  </div>

</div>


<div fxLayout="row" fxLayoutAlign="center center"   *ngIf="isApp === false" >

  <button  href="#" (click)="goToHome(this.linkInicial)" class="butLogin" mat-raised-button><mat-icon class="buticonLogin"  >west</mat-icon> Voltar</button>
  <button  href="#" (click)="goToLink(this.linkLogin)" class="butLogin" mat-raised-button>Já tem cadastro? Faça seu Login<mat-icon class="buticonLogin"  >east</mat-icon></button>

</div>

<div fxLayout="row" fxLayoutAlign="center center"  *ngIf="isApp === true" >
  <button  (click)="voltaTelaApp()" class="butLogin" mat-raised-button><mat-icon class="buticonLogin"  >arrow_back</mat-icon> Voltar para tela de Login</button>
</div>

<div class="loading-indicator" [hidden]='isloading'>
  <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
</div>
</body>
</html>
