import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService {

  //private user: Observable<firebase.default.User>;
  email: string = '';
  password: string = '';

  constructor(private _firebaseAuth: AngularFireAuth, private router: Router) {
      //this.user = _firebaseAuth.authState;
  }
  /* Sign up */
  signUp(email: string, password: string) {

    //const credential = firebase.default.auth.EmailAuthProvider.credential( email, password );
  return this._firebaseAuth.createUserWithEmailAndPassword(email, password)
  }

  signInRegular(email: string, password: string) {
   // const credential = firebase.default.auth.EmailAuthProvider.credential( email, password );


    return this._firebaseAuth.signInWithEmailAndPassword(email, password)
  }

}


