

import { Component, OnInit, ViewChild, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, ValidatorFn, AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar , MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { cnpj,cpf } from 'cpf-cnpj-validator';

import { PipeTransform, Pipe } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from '../helpers/config.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { EmpresaService } from './../services/empresa.service';

import { environment } from './../../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthService } from '../services/auth.service';
import { AngularFireDatabase, AngularFireList  } from '@angular/fire/compat/database';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {

  public mask = {
    guide: true,
    showMask : true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/,/\d/, /\d/]
  };

  maskConfig = {
    mask: [
      new RegExp('\\d'),
      new RegExp('\\d'),
      '/',
      new RegExp('\\d'),
      new RegExp('\\d'),
      '/',
      new RegExp('\\d'),
      new RegExp('\\d'),
      new RegExp('\\d'),
      new RegExp('\\d')
    ],
    showMask: false,
    guide: false,
    placeholderChar: '_'
  };

  matcher = new MyErrorStateMatcher();

  isCompleted_step_cnjp = false

  paises = new Array;
  ufEstados = new Array;
  cidades = new Array;
  origemCads = new Array;

  isloading = true;
  loadingCidades = false;
  loadingBuscaCEP = false;
  loadingPesquisa = false;

  hidePss = true
  clickTermosLink = false

  dadosComerciais_FormGroup : FormGroup | any;
  dadosAcesso_FormGroup: FormGroup| any;
  aceitePoliticaUso_FormGroup: FormGroup| any;

  toHighlight: string = '';
  filteredCidades: Observable<any[]> | any;
  isApp = false

  noTemporario: any;
  retornoTemporario: any;
  public temporarioObjeto: any;
  key: any;
  lfirst = true

  @ViewChild('stepper' ,  { static: false } ) stepper: MatStepper | any;
  @ViewChild('Reboques' ,  { static: false }) Reboques : any;

  constructor(   @Inject(DOCUMENT) private document: any ,
                private _formBuilder: FormBuilder ,
                private empresaService : EmpresaService ,
                public snackBar: MatSnackBar ,
                private router : Router,
                private dialog: MatDialog,
                private configService : ConfigService,
                private aut: AngularFireAuthModule,
                private authService: AuthService,
                private firebaseRealtime: AngularFireDatabase,
                 ) {

    //Pega Estados
    this.ufEstados = empresaService.getEstados();
    this.origemCads = empresaService.getOrigemCad();

    this.isApp =  this.configService.isAppCall();
    //** Funçao para voltar no botão de voltar APP
    window['fnVoltarPeloApp'] = function(){
      //this.router.navigate(['inicio']);
      window.location.href  = 'https://'+ window.location.host  + '/inicio'
    }


  }

  //*** Inicio - ngOnInit ****
  ngOnInit(): void {

    this.loading('show')

    this.dadosComerciais_FormGroup = this._formBuilder.group({
      cnpj_DadosComerciais: new FormControl(null, {
        validators: [Validators.required , Validators.minLength(14) , Validators.maxLength(14) ]
      }),
      ie_DadosComerciais : new FormControl(null, {
        validators: [ Validators.required ]
      }),
      nome_RazaoSocial_DadosComerciais : new FormControl(null, {
        validators: [Validators.required ]
      }),
      nome_NomeFantasia_DadosComerciais : new FormControl(null, {
        validators: [Validators.required ]
      }),
      cep_DadosComerciais : new FormControl(null, {
        validators: [Validators.required]
      }),
      endereco_DadosComerciais : new FormControl(null, {
        validators: [Validators.required]
      }),
      bairro_DadosComerciais : new FormControl(null, {
        validators: [Validators.required]
      }),
      UF_DadosComerciais : new FormControl(null, {
        validators: [Validators.required]
      }),
      cidade_DadosComerciais : new FormControl(null, {
        validators: [this.forbiddenNamesValidator()]
      }),
      pais_DadosComerciais : new FormControl('55', {
        validators: [Validators.required]
      }),
      email_DadosComerciais : new FormControl(null, {
        validators: [Validators.required ,Validators.email ]
      }),
      DDI_DadosComerciais : new FormControl('55', {
        validators: [Validators.required]
      }),
      DDD_DadosComerciais : new FormControl(null, {
        validators: [Validators.required]
      }),
      telefone_DadosComerciais : new FormControl(null, {
        validators: [Validators.required]
      }),
    });

    this.dadosAcesso_FormGroup = this._formBuilder.group({
      passoword_DadosAcesso : new FormControl(null, {
        validators: [Validators.required]
      }),
      confirm_passoword_DadosAcesso : new FormControl(null),
    }, { validator: this.checkPasswords });

    this.aceitePoliticaUso_FormGroup = this._formBuilder.group({
      aceitePoliticaUso : new FormControl(null, {
        validators: [Validators.required]
      }),
      aceiteNotificacaoMensagem : new FormControl(null, {
        validators: [Validators.required]
      }),
      data_aceitePoliticaUso : new FormControl(null, {
        validators: []
      }),
      data_aceiteNotificacaoMensagem : new FormControl(null, {
        validators: []
      }),
      origemCadastro : new FormControl(null, {
        validators: [Validators.required]
      })
    });

    this.filteredCidades = this.dadosComerciais_FormGroup.get('cidade_DadosComerciais')?.valueChanges
    .pipe(
      startWith(''),
      map((cidades: { name: string; }) => cidades ? this.filterCidades(cidades.name) : this.cidades.slice())
    );

    this.loadPaises();

    this.loading('hide')

  }
  //*** FIM - ngOnInit ****


  filterCidades(name: string) {
    this.toHighlight = name;
    return this.cidades.filter((cidades: { name: string; }) =>
      cidades.name.toLowerCase().indexOf(name) === 0);
  }


  forbiddenNamesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = this.cidades.findIndex(name => {
        return (new RegExp('\^' + name.name + '\$')).test(control.value);
      });
      return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
    };
  }

  onChangeNomeEmp(){

    this.dadosComerciais_FormGroup?.get("nome_RazaoSocial_DadosComerciais")?.setValue(this.removeCharsEspecial(  this.dadosComerciais_FormGroup?.get("nome_RazaoSocial_DadosComerciais")?.value , false , true , false));
    this.dadosComerciais_FormGroup?.get("nome_NomeFantasia_DadosComerciais")?.setValue(this.removeCharsEspecial(  this.dadosComerciais_FormGroup?.get("nome_NomeFantasia_DadosComerciais")?.value , false , true , false));

  }

  onChangeEstado( Municip : any ){

    this.dadosComerciais_FormGroup?.get("cidade_DadosComerciais")?.setValue(null);
    let UF = this.dadosComerciais_FormGroup?.get("UF_DadosComerciais")?.value
    this.loadingCidades = true
    this.cidades = []

    this.empresaService.getMunicipios(UF).
      subscribe(( response : any) => {
        this.loadingCidades = false
        if(  response.status === "error"){
          console.log('Erro carregar getMunicipios ',  response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }

        if(response.retorno.values.length === undefined ){
          let objTemp = {
                          "estado" : response.retorno.values.value[1].$value ,//estado
                          "name": response.retorno.values.value[0].$value ,//nome cidade
                          "codigo": response.retorno.values.value[2].$value //cod munici
                        }
          this.cidades.push(objTemp)
        }else{
          for (let index = 0; index < response.retorno.values.length; index++) {
            let objTemp = {
                            "estado" : response.retorno.values[index].value[1].$value ,//estado
                            "name": response.retorno.values[index].value[0].$value ,//nome cidade
                            "codigo": response.retorno.values[index].value[2].$value //cod munici
                          }
            this.cidades.push(objTemp)
          }
        }
        if( Municip != undefined){
          this.dadosComerciais_FormGroup?.get("cidade_DadosComerciais")?.setValue( Municip  )
        }

    }, (erro) => {
      console.log('Erro carregar getMunicipios ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingCidades = false
    });


  }

  onChangeCEP(){

    this.loadingBuscaCEP = true
    let CEP = this.dadosComerciais_FormGroup?.get("cep_DadosComerciais")?.value

    this.empresaService.getCEP(CEP).
      subscribe(( response : any ) => {

        this.loadingBuscaCEP = false

        this.dadosComerciais_FormGroup?.get("endereco_DadosComerciais")?.setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)   );
        this.dadosComerciais_FormGroup?.get("bairro_DadosComerciais")?.setValue(   this.removeCharsEspecial(   response.bairro  , false , true , false).substr(0 , 20) );
        this.dadosComerciais_FormGroup?.get("UF_DadosComerciais")?.setValue(   response.uf  );
        let  cidade = this.removeCharsEspecial( response.localidade , false , true , true)
        this.onChangeEstado( cidade )


    }, (erro) => {
      console.log('Erro carregar getCEP ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.loadingBuscaCEP = false
    });

  }

  async loadPaises(){

    await this.configService.forceinitvar();

    this.empresaService.getPaises().subscribe(( response : any) => {

        if(response.status === "error"){
          console.log('Erro carregar getPaises ', response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + response.msg , 'x' ,  'msgErro' )
          return
        }
        this.paises = []
        for (let index = 0; index < response.retorno.values.length; index++) {
          let objTemp = {
                          "codigo" : response.retorno.values[index].value[0].$value ,
                          "name": response.retorno.values[index].value[1].$value ,
                          "continente": response.retorno.values[index].value[2].$value ,
                          "DDI": response.retorno.values[index].value[3].$value ,
                          "codBacen": response.retorno.values[index].value[4].$value
                        }
          this.paises.push(objTemp)
        }
    }, (erro) => {
      console.log('Erro carregar getPaises ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
    });

  }


  checkPasswords(group: AbstractControl) { // here we have the 'passwords' group
    let pass = group?.get('passoword_DadosAcesso')?.value;
    let confirmPass = group?.get('confirm_passoword_DadosAcesso')?.value;

    return pass === confirmPass ? null : { notSame: true }
  }


  abrirTermos(){

    this.clickTermosLink = true
    window.open('https://bialog.com.br/politica-de-privacidade/', "_blank" );


    // Funçao para voltar no botão de voltar APP
    window['fnVoltarPeloApp'] = function(){
      this.window.close();
    }

    /*dialogRef.afterClosed().subscribe( () => {

      // Funçao para voltar no botão de voltar APP
      window['fnVoltarPeloApp'] = function(){
        window.location.href = 'https://'+ window.location.host  + '/inicio'
      }

    });*/

  }

  async signInWithEmail(login: string,pwd: string) {

    let cpfcnpj2 = this.dadosComerciais_FormGroup?.get("cnpj_DadosComerciais")?.value
    let loginTemp = this.removeCaracCPFandCNJP(cpfcnpj2)+environment.sufix;
    let psTemp = this.removeCaracCPFandCNJP(cpfcnpj2)+environment.sufix2;

    if (login == ''){
      login = loginTemp;
      pwd = psTemp;
    }

    this.loading('show');

    this.authService.signInRegular(login, pwd)
       .then((res: any) => {

          console.log('Logou Firebase');

          this.onChangeCPF_CNPJ();
          return res;
       })
       .catch((err: any) => {

        this.signUpWithEmail(login, pwd)

        //console.log('Algo deu errado:', err.message);
        console.log('sem firebase');
      });
  }

  async signUpWithEmail(login: string,pwd: string){

    this.authService.signUp(login,pwd)
      .then((res: any) => {

        console.log('Logou com cadastro');
        this.onChangeCPF_CNPJ();

        return res;
      })
      .catch((err: { message: any; }) => {

        console.log('Algo deu errado:', err.message);

      });
  }

  marcaPoliticaUso(evento: { checked: any; }){
    if(evento.checked){
      this.aceitePoliticaUso_FormGroup?.get("data_aceitePoliticaUso")?.setValue( new Date())
    }else{
      this.aceitePoliticaUso_FormGroup?.get("data_aceitePoliticaUso")?.setValue( null )
    }
  }

  marcaAceiteNotificacao(evento: { checked: any; }){
    if(evento.checked){
      this.aceitePoliticaUso_FormGroup?.get("data_aceiteNotificacaoMensagem")?.setValue( new Date())
    }else{
      this.aceitePoliticaUso_FormGroup?.get("data_aceiteNotificacaoMensagem")?.setValue( null )
    }
  }


  alteraEtapa(evento: { selectedIndex: any; }){

    switch(evento.selectedIndex) {
      case 0:
        this.SalvarForms('0')
        break;
      case 1:
        this.SalvarForms('1')
        break;
      case 2:
        this.SalvarForms('2')
        break;
      case 3:
        this.SalvarForms('3')
        break;
    }

  }



  SalvarForms(cEtapa: string){

    this.loading('show')
    let objSalve = {}
    let cpforcpnj =  this.dadosComerciais_FormGroup?.get("cnpj_DadosComerciais")?.value

    console.log("****** Salvando Forms - Firebase ******* " , new Date() )

    let temp = cEtapa;

    this.temporarioObjeto = {
      cnpj_DadosComerciais: this.dadosComerciais_FormGroup?.get('cnpj_DadosComerciais')?.value,
      ie_DadosComerciais: this.dadosComerciais_FormGroup?.get('ie_DadosComerciais')?.value,
      nome_RazaoSocial_DadosComerciais: this.dadosComerciais_FormGroup?.get('nome_RazaoSocial_DadosComerciais')?.value,
      nome_NomeFantasia_DadosComerciais: this.dadosComerciais_FormGroup?.get('nome_NomeFantasia_DadosComerciais')?.value,
      cep_DadosComerciais: this.dadosComerciais_FormGroup?.get('cep_DadosComerciais')?.value,
      endereco_DadosComerciais: this.dadosComerciais_FormGroup?.get('endereco_DadosComerciais')?.value,
      bairro_DadosComerciais: this.dadosComerciais_FormGroup?.get('bairro_DadosComerciais')?.value,
      UF_DadosComerciais: this.dadosComerciais_FormGroup?.get('UF_DadosComerciais')?.value,
      cidade_DadosComerciais: this.dadosComerciais_FormGroup?.get('cidade_DadosComerciais')?.value,
      pais_DadosComerciais: this.dadosComerciais_FormGroup?.get('pais_DadosComerciais')?.value,
      email_DadosComerciais: this.dadosComerciais_FormGroup?.get('email_DadosComerciais')?.value,
      DDI_DadosComerciais: this.dadosComerciais_FormGroup?.get('DDI_DadosComerciais')?.value,
      DDD_DadosComerciais: this.dadosComerciais_FormGroup?.get('DDD_DadosComerciais')?.value,
      telefone_DadosComerciais: this.dadosComerciais_FormGroup?.get('telefone_DadosComerciais')?.value,
      passoword_DadosAcesso: this.dadosAcesso_FormGroup?.get('passoword_DadosAcesso')?.value ? this.dadosAcesso_FormGroup?.get('passoword_DadosAcesso')?.value:"",
      confirm_passoword_DadosAcesso: this.dadosAcesso_FormGroup?.get('confirm_passoword_DadosAcesso')?.value ? this.dadosAcesso_FormGroup?.get('confirm_passoword_DadosAcesso')?.value:"",
      aceitePoliticaUso: this.aceitePoliticaUso_FormGroup?.get('aceitePoliticaUso')?.value ? this.aceitePoliticaUso_FormGroup?.get('aceitePoliticaUso')?.value:"",
      aceiteNotificacaoMensagem: this.aceitePoliticaUso_FormGroup?.get('aceiteNotificacaoMensagem')?.value ? this.aceitePoliticaUso_FormGroup?.get('aceiteNotificacaoMensagem')?.value:"",
      data_aceitePoliticaUso: this.aceitePoliticaUso_FormGroup?.get('data_aceitePoliticaUso')?.value ? this.aceitePoliticaUso_FormGroup?.get('data_aceitePoliticaUso')?.value:"",
      data_aceiteNotificacaoMensagem: this.aceitePoliticaUso_FormGroup?.get('data_aceiteNotificacaoMensagem')?.value ? this.aceitePoliticaUso_FormGroup?.get('data_aceiteNotificacaoMensagem')?.value:"",
      stepperNextIndex: temp+'',
      key: this.dadosComerciais_FormGroup?.get('cnpj_DadosComerciais')?.value,
      published: false
    }

    //Alimento a variavel que direciona o caminho onde salvará
    this.configService.dbPath = environment.caminhoRealtimeEmpresa;
    this.configService.nofirebaseRef = this.firebaseRealtime.list(environment.caminhoRealtimeEmpresa);

    this.configService.update(this.dadosComerciais_FormGroup?.get('cnpj_DadosComerciais')?.value, this.temporarioObjeto)
      .then(() => {
      console.log('Atualizado no firebase' , new Date())
      this.noTemporario = true;
      this.loading('hide')
      })
      .catch(err => console.log(err));

  }


  onChangeCPF_CNPJ(){

    this.loading('show')

    let datasetReturn
    let cpfcnpj = this.dadosComerciais_FormGroup?.get("cnpj_DadosComerciais")?.value

    if(( cpfcnpj.length !== 14) || (!cnpj.isValid(cpfcnpj))){
      this.openSnackBar('Ops... CNPJ inválido, favor verificar valor informado... ', 'x' ,  'msgErro' )
      this.loading('hide')
      this.dadosComerciais_FormGroup?.get("cnpj_DadosComerciais")?.setValue("");
      return
    }

    this.empresaService.getConsultCPFCNPJ(cpfcnpj).
      subscribe(( response : any) => {
        datasetReturn = response

        if( datasetReturn.status === "success" ){

          if( datasetReturn.retorno.values === undefined ){

            //Alimento a variavel que direciona o caminho onde salvará
            this.configService.dbPath = environment.caminhoRealtimeEmpresa;
            this.configService.nofirebaseRef = this.firebaseRealtime.list(environment.caminhoRealtimeEmpresa);

            //*** VERIFICA SE CPF/CPNJ JA EXISTE EM BASE DE DADOS TEMPORARIOA
            this.configService.getUnico().snapshotChanges().pipe(
              map(( changes : any) =>
                changes.map((c: { payload: { key: any; val: () => any; }; }) =>
                  ({ key: c.payload.key, ...c.payload.val() })
                )
              )
            ).subscribe((data: any) => {

              this.retornoTemporario = data;
              if(this.retornoTemporario.length > 0){

                for (let prop = 0; prop < this.retornoTemporario.length ; prop++) {


                  //var objProp = eval('response.dataForms.' + prop )
                  //eval('this.'+prop).patchValue(objProp);

                  if (this.retornoTemporario[prop].key == cpfcnpj){
                  //*********** gatilha dados para carregamento correto de cidades - Filtros cidades
                  this.onChangeEstado( this.retornoTemporario[prop].cidade_DadosComerciais )

                  // ** FIM

                    this.dadosComerciais_FormGroup?.get('cnpj_DadosComerciais')?.setValue(this.retornoTemporario[prop].cnpj_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('ie_DadosComerciais')?.setValue(this.retornoTemporario[prop].ie_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('nome_RazaoSocial_DadosComerciais')?.setValue(this.retornoTemporario[prop].nome_RazaoSocial_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('nome_NomeFantasia_DadosComerciais')?.setValue(this.retornoTemporario[prop].nome_NomeFantasia_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('cep_DadosComerciais')?.setValue(this.retornoTemporario[prop].cep_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('endereco_DadosComerciais')?.setValue(this.retornoTemporario[prop].endereco_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('bairro_DadosComerciais')?.setValue(this.retornoTemporario[prop].bairro_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('UF_DadosComerciais')?.setValue(this.retornoTemporario[prop].UF_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('cidade_DadosComerciais')?.setValue(this.retornoTemporario[prop].cidade_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('pais_DadosComerciais')?.setValue(this.retornoTemporario[prop].pais_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('email_DadosComerciais')?.setValue(this.retornoTemporario[prop].email_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('DDI_DadosComerciais')?.setValue(this.retornoTemporario[prop].DDI_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('DDD_DadosComerciais')?.setValue(this.retornoTemporario[prop].DDD_DadosComerciais);
                    this.dadosComerciais_FormGroup?.get('telefone_DadosComerciais')?.setValue(this.retornoTemporario[prop].telefone_DadosComerciais);
                    this.dadosAcesso_FormGroup?.get('passoword_DadosAcesso')?.setValue(this.retornoTemporario[prop].passoword_DadosAcesso);
                    this.dadosAcesso_FormGroup?.get('confirm_passoword_DadosAcesso')?.setValue(this.retornoTemporario[prop].confirm_passoword_DadosAcesso);
                    this.aceitePoliticaUso_FormGroup?.get('aceitePoliticaUso')?.setValue(this.retornoTemporario[prop].aceitePoliticaUso);
                    this.aceitePoliticaUso_FormGroup?.get('aceiteNotificacaoMensagem')?.setValue(this.retornoTemporario[prop].aceiteNotificacaoMensagem);
                    this.aceitePoliticaUso_FormGroup?.get('data_aceitePoliticaUso')?.setValue(this.retornoTemporario[prop].data_aceitePoliticaUso);
                    this.aceitePoliticaUso_FormGroup?.get('data_aceiteNotificacaoMensagem')?.setValue(this.retornoTemporario[prop].data_aceiteNotificacaoMensagem);

                   // if (this.lfirst){
                   //   this.openSnackBar('Vimos que você possui um cadastro em andamento...' , 'x' ,  'msgSucesso' )
                   //   this.lfirst = false;
                   // }

                    this.stepper.linear = false;
                    if(this.retornoTemporario[prop].stepperNextIndex == '3'){
                      this.stepper.selectedIndex = 2;
                    }else{
                      this.stepper.selectedIndex = this.retornoTemporario[prop].stepperNextIndex
                    }
                    this.stepper.linear = true;

                    this.loading('hide')
                  }

                }

                if(this.dadosComerciais_FormGroup?.get('nome_RazaoSocial_DadosComerciais')?.value == "" || this.dadosComerciais_FormGroup?.get('nome_RazaoSocial_DadosComerciais')?.value == null){

                  //Para ele buscar na receita mesmo assim
                  this.loadingBuscaCEP = true;
                this.loadingPesquisa = true;

                //BUSCA DADOS CPNJ DA RECEITA WS
                this.empresaService.getCpfReceitaWS( cpfcnpj , '' ,  'RF' ).subscribe(( response : any ) => {

                    if( response.code === "0") {

                      this.dadosComerciais_FormGroup?.get("nome_RazaoSocial_DadosComerciais")?.setValue(  this.removeCharsEspecial(  response.nome , false , true , false).substr(0 , 40)   );
                      this.dadosComerciais_FormGroup?.get("nome_NomeFantasia_DadosComerciais")?.setValue(  this.removeCharsEspecial(  response.fantasia , false , true , false).substr(0 , 20)  );
                      this.dadosComerciais_FormGroup?.get("cep_DadosComerciais")?.setValue( response.cep  );
                      this.dadosComerciais_FormGroup?.get("endereco_DadosComerciais")?.setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)   );
                      this.dadosComerciais_FormGroup?.get("bairro_DadosComerciais")?.setValue(  this.removeCharsEspecial(  response.bairro , false , true , false).substr(0 , 20)   );
                      this.dadosComerciais_FormGroup?.get("UF_DadosComerciais")?.setValue( response.uf  );
                      this.onChangeEstado( this.removeCharsEspecial(  response.municipio , false , true , false) )

                      this.loadingBuscaCEP = false;
                      this.loadingPesquisa = false;

                      this.loading('hide')

                    }else{

                      if( response.code === "5") {

                        console.log("getCpfReceitaWS - sintegraws:" , "*** Os créditos contratados acabaram.")
                        this.loading('hide')
                        this.empresaService.getDSFaltaCreditoSintegraWS().
                        subscribe(( response : any) => {
                          let datasetReturn = response
                        }, (erro) => {
                          console.log('Erro carregar getDSFaltaCreditoSintegraWS ', erro)
                          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
                          this.loading('hide')
                        });

                      }else{
                        this.openSnackBar('Ops! ' + response.message , 'x' ,  'msgErro' )
                        this.stepper.linear = false;
                        this.stepper.selectedIndex = 0
                        this.stepper.linear = true;
                        this.loading('hide')
                        this.loadingBuscaCEP = false;
                        this.loadingPesquisa = false;
                      }



                    }

                }, (erro) => {
                  console.log('Erro carregar getCpfReceitaWS ', erro)
                  this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
                  this.stepper.linear = false;
                  this.stepper.selectedIndex = 0
                  this.stepper.linear = true;
                  this.loading('hide')
                  this.loadingBuscaCEP = false;
                  this.loadingPesquisa = false;

                });
                }

                this.aceitePoliticaUso_FormGroup?.get("aceitePoliticaUso")?.value  ? this.clickTermosLink = true : this.clickTermosLink = false

              }else{ //SE NÃO TIVER EM BASE DE DADOS FAZ O FLUXO NORMAL

                this.loadingBuscaCEP = true;
                this.loadingPesquisa = true;

                //BUSCA DADOS CPNJ DA RECEITA WS
                this.empresaService.getCpfReceitaWS( cpfcnpj , '' ,  'RF' ).subscribe(( response : any ) => {

                    if( response.code === "0") {

                      this.dadosComerciais_FormGroup?.get("nome_RazaoSocial_DadosComerciais")?.setValue(  this.removeCharsEspecial(  response.nome , false , true , false).substr(0 , 40)   );
                      this.dadosComerciais_FormGroup?.get("nome_NomeFantasia_DadosComerciais")?.setValue(  this.removeCharsEspecial(  response.fantasia , false , true , false).substr(0 , 20)  );
                      this.dadosComerciais_FormGroup?.get("cep_DadosComerciais")?.setValue( response.cep  );
                      this.dadosComerciais_FormGroup?.get("endereco_DadosComerciais")?.setValue(  this.removeCharsEspecial(  response.logradouro , false , true , false).substr(0 , 30)   );
                      this.dadosComerciais_FormGroup?.get("bairro_DadosComerciais")?.setValue(  this.removeCharsEspecial(  response.bairro , false , true , false).substr(0 , 20)   );
                      this.dadosComerciais_FormGroup?.get("UF_DadosComerciais")?.setValue( response.uf  );
                      this.onChangeEstado( this.removeCharsEspecial(  response.municipio , false , true , false) )

                      this.loadingBuscaCEP = false;
                      this.loadingPesquisa = false;

                      this.loading('hide')

                    }else{

                      if( response.code === "5") {

                        console.log("getCpfReceitaWS - sintegraws:" , "*** Os créditos contratados acabaram.")
                        this.loading('hide')
                        this.empresaService.getDSFaltaCreditoSintegraWS().
                        subscribe(( response : any) => {
                          let datasetReturn = response
                        }, (erro) => {
                          console.log('Erro carregar getDSFaltaCreditoSintegraWS ', erro)
                          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
                          this.loading('hide')
                        });

                      }else{
                        this.openSnackBar('Ops! ' + response.message , 'x' ,  'msgErro' )
                        this.stepper.linear = false;
                        this.stepper.selectedIndex = 0
                        this.stepper.linear = true;
                        this.loading('hide')
                        this.loadingBuscaCEP = false;
                        this.loadingPesquisa = false;
                      }



                    }

                }, (erro) => {
                  console.log('Erro carregar getCpfReceitaWS ', erro)
                  this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
                  this.stepper.linear = false;
                  this.stepper.selectedIndex = 0
                  this.stepper.linear = true;
                  this.loading('hide')
                  this.loadingBuscaCEP = false;
                  this.loadingPesquisa = false;

                });

              }

              this.loading('hide')

            }, (erro: string) => {
              console.log('Erro carregar getformsfirebase ', erro)
              this.openSnackBar('Ops! Tivemos problemas ao resgatar formulário salvo em nossa base de dados temporária... ' + erro , 'x' ,  'msgErro' )
              this.loading('hide')
            });



          }else{
            if(datasetReturn.retorno.values.value[0].$value !== 'ERRO'){
              this.openSnackBar('Você já é cadastrado e será direcionado para tela de login...', 'x' ,  'msgErro' )
              this.loading('hide')
              setTimeout(() => {
                this.document.location.href =  environment.url_fluig + '/portal/1/wgPortalPublico'//this.router.navigate(["/"]).then(result=>{window.location.href = 'https://fruteb.fluig.com/portal/1/wgPortalPublico';});
              }, 6000);
            }else{
              console.log('Erro carregar getConsultCPFCNPJ ', datasetReturn.retorno.values.value[1].$value)
              this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + datasetReturn.retorno.values.value[1].$value , 'x' ,  'msgErro' )
              this.loading('hide')
            }

          }

        }else{
          console.log('Erro carregar getConsultCPFCNPJ'  , datasetReturn.retorno )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ', 'x' ,  'msgErro' )
          this.loading('hide')
        }

      }, (erro) => {
         console.log('Erro carregar getConsultCPFCNPJ ', erro)
         this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
         this.loading('hide')
      });

  }

  onChangePais(formGroup: string , nameCampoPais: any , nameCampoDestino: any ){
    eval('this.' + formGroup).get( nameCampoDestino ).setValue( eval('this.' + formGroup).get(  nameCampoPais  ).value  );
  }



  finalizarCadastro(){

    this.loading('show')

    if(this.dadosComerciais_FormGroup?.valid){
        if(this.dadosAcesso_FormGroup?.valid){
          if(!this.aceitePoliticaUso_FormGroup?.valid){
            this.openSnackBar('Atenção! Preencher corretamente a etapa atual'  , 'x' ,  'msgErro' )
            this.loading('hide')
            return
          }
        }else{
          this.stepper.linear = false;
          this.stepper.selectedIndex = 1
          this.stepper.linear = true;
          this.openSnackBar('Atenção! Preencher corretamente a etapa Dados do Veiculo'  , 'x' ,  'msgErro' )
          this.loading('hide')
          return
        }
    }else{
      this.stepper.linear = false;
      this.stepper.selectedIndex = 0
      this.stepper.linear = true;
      this.openSnackBar('Atenção! Preencher corretamente a etapa Proprietário do Veiculo '  , 'x' ,  'msgErro' )
      this.loading('hide')
      return
    }

    let objSalve = {
                    "dataForms" : {
                                    "dadosComerciais_FormGroup" : this.dadosComerciais_FormGroup?.value  ,
                                    "dadosAcesso_FormGroup" : this.dadosAcesso_FormGroup?.value,
                                    "aceitePoliticaUso_FormGroup" : this.aceitePoliticaUso_FormGroup?.value
                                  } ,
                    "stepperNextIndex" : this.stepper.selectedIndex
                  }

                  console.log(objSalve);

    let endPoint = 'WSBIALOG_TMS_CADSITE/CLIENTE/'
    let metodo = 'POST'

    this.empresaService.getAPIProtheus( endPoint , metodo  , objSalve ).
      subscribe(( response : any) => {

        if(response.status === "success" ){

          if( response.retorno.values.value[0].$value === "SUCCESS" ){

            let retornoJSON = JSON.parse(response.retorno.values.value[1].$value)

            if(retornoJSON.success && retornoJSON.sucessCode === 200 ){

              var msgUserApresent = ""

              if(retornoJSON.userblocker){
                msgUserApresent = "Obrigado, seu cadastro foi recebido e encaminhado para um de nossos analistas."
                this.router.navigate(['final']);
              }else{
                msgUserApresent = "Obrigado, seu cadastro foi recebido e encaminhado para um de nossos analistas. Entraremos em contato para fornecer mais informações."
                this.router.navigate(['final']);
              }



              //Alimento a variavel que direciona o caminho onde salvará
              this.configService.dbPath = environment.caminhoRealtimeEmpresa;
              this.configService.nofirebaseRef = this.firebaseRealtime.list(environment.caminhoRealtimeEmpresa);

              this.configService.delete(this.dadosComerciais_FormGroup?.get("cnpj_DadosComerciais")?.value)
                .then(() => {
                  console.log('Deletetado do firebase' , new Date())

                  setTimeout(() => {
                    //this.document.location.href = environment.url_fluig + '/portal/1/wgPortalPublico'
                    this.router.navigate(['final']);
                  }, 6000);

                  this.openSnackBar('Sucesso! ' + msgUserApresent , 'x' ,  'msgSucesso' )

                  })
                  .catch(err => {console.log(err)
                    console.log('Erro carregar deleteformsfirebase ', err)
                    this.router.navigate(['inicio']);
                    this.openSnackBar('Sucesso! ' + msgUserApresent , 'x' ,  'msgSucesso' )
                    this.loading('hide')
                  });

            }else{
              this.openSnackBar('Atenção! ' +  retornoJSON.sucessMessage  , 'x' ,  'msgErro' )
              this.loading('hide')
            }

          }else{
            console.log("Error getAPIProtheus" ,  response.retorno.values.value[1].$value  )
            this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.retorno.values.value[1].$value   , 'x' ,  'msgErro' )
            this.loading('hide')
          }

        }else{
          console.log("Error getAPIProtheus" ,  response.msg )
          this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' +  response.msg   , 'x' ,  'msgErro' )
          this.loading('hide')
        }


    }, (erro) => {
      console.log('Erro ao gravar dados no protheus - getAPIProtheus ', erro)
      this.openSnackBar('Ops! Tivemos problemas ao processar alguma informação... ' + erro , 'x' ,  'msgErro' )
      this.SalvarForms('3');
      this.loading('hide')
    });


  }


  //*********** Funcoes Aux

  removeCaracCPFandCNJP( cpforcnpj : string ){
    return cpforcnpj.replace(new RegExp("[./-]", 'gi'),"")
  }

  private loading( action : string ){
    switch (action) {
      case 'show':
        this.isloading = false
        break
      case 'hide':
        this.isloading = true
        break
      default:
        this.isloading = true
    }

    return true
  }

  private delay(ms: number): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, ms);
    });
  }

  openSnackBar(message: string, action: string , classtype: string ) {
    let config = new MatSnackBarConfig();
    config.panelClass = [classtype];
    classtype === 'msgSucesso' ? config.duration = 10000 : config.duration = 30000
    this.snackBar.open(message, action ,config);
  }

  removeCharsEspecial(especialChar : string , removeSpace : boolean , upperCase : boolean , fristLowerCase : boolean ){
    removeSpace = removeSpace === undefined ? false : removeSpace
    upperCase   = upperCase === undefined ? false : upperCase
    fristLowerCase   = fristLowerCase === undefined ? false : fristLowerCase
    especialChar = fristLowerCase ? especialChar.toLowerCase() : especialChar
    especialChar = especialChar.replace(new RegExp("[àáâãäå]", 'gi'),"a");
    especialChar = especialChar.replace(new RegExp("æ", 'gi'),"ae");
    especialChar = especialChar.replace(new RegExp("ç", 'gi'),"c");
    especialChar = especialChar.replace(new RegExp("[èéêë]", 'gi'),"e");
    especialChar = especialChar.replace(new RegExp("[ìíîï]", 'gi'),"i");
    especialChar = especialChar.replace(new RegExp("ñ", 'gi'),"n");
    especialChar = especialChar.replace(new RegExp("[òóôõö]", 'gi'),"o");
    especialChar = especialChar.replace(new RegExp("œ", 'gi'),"oe");
    especialChar = especialChar.replace(new RegExp("[ùúûü]", 'gi'),"u");
    especialChar = especialChar.replace(new RegExp("[ýÿ]", 'gi'),"y");
    //tratativa para maiusculas
    especialChar = especialChar.replace(new RegExp("[ÀÁÂÃÄÅ]", 'gi'),"A");
    especialChar = especialChar.replace(new RegExp("Æ", 'gi'),"AE");
    especialChar = especialChar.replace(new RegExp("Ç", 'gi'),"C");
    especialChar = especialChar.replace(new RegExp("[ÈÉÊË]", 'gi'),"E");
    especialChar = especialChar.replace(new RegExp("[ÌÍÎÏ]", 'gi'),"I");
    especialChar = especialChar.replace(new RegExp("Ñ", 'gi'),"N");
    especialChar = especialChar.replace(new RegExp("[ÒÓÔÕÖ]", 'gi'),"O");
    especialChar = especialChar.replace(new RegExp("Œ", 'gi'),"OE");
    especialChar = especialChar.replace(new RegExp("[ÙÚÛÜ]", 'gi'),"U");
    especialChar = especialChar.replace(new RegExp("[ÝŸ]", 'gi'),"Y");

    if(removeSpace){
      especialChar = especialChar.replace(new RegExp("\\s", 'gi'),"");
      especialChar = especialChar.replace(new RegExp("\\W", 'gi'),"");
    }
    especialChar = upperCase ? especialChar.toUpperCase() : especialChar
    return especialChar;
  }



}


/** PIPES e Tratativas erros */
@Pipe({ name: 'highlight'  })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string): string {
    const pattern = search
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
      .split(' ')
      .filter(t => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');

    return search ? text.replace(regex, match => `<b>${match}</b>`) : text;
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent?.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

