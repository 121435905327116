import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '../helpers/config.service';

import { environment } from './../../environments/environment';

declare global {
  interface Window {
    portalBialog:any;
    fnVoltarPeloApp: any;
  }
}

@Component({
  selector: 'app-final',
  templateUrl: './final.component.html',
  styleUrls: ['./final.component.css']
})
export class FinalComponent implements OnInit {

  isloading = true
  isApp = false

  constructor(  @Inject(DOCUMENT) private document: any , private router : Router ,private configService : ConfigService ) {
    this.isApp =  this.configService.isAppCall();
    //** Funçao para voltar no botão de voltar APP
    window['fnVoltarPeloApp'] = function(){
      window.location.href = environment.url_fluig + '/portal/1/wgPortalPublico'
    }
  }
  ngOnInit(): void {  }

  goToLink(url: string){
    window.open(url);
    return false;
  }

  routerAcess(router: any){
    this.isloading = false
    this.router.navigate([router]);
  }

  voltaTelaApp(){
    //window.portalBialog.funcaoAppMinimizaAplicativo();
    this.document.location.href = environment.url_fluig + '/portal/1/wgPortalPublico'
  }

}
