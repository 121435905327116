<html lang="pt-br">
  <head>

    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-156363140-2"></script>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-156363140-2');
    </script>
  </head>
  <body>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
<div fxLayout="row" fxLayoutAlign="center center">
  <h1 class="titleHome" fxLayoutAlign="center center">Bem-vindo, embarcador!</h1>
</div>
<div fxLayout="row" fxLayoutAlign="center center">
  <h1  class="subtitle" fxLayoutAlign="center center">Faça aqui o seu cadastro.</h1>
</div>

<div fxLayout="row" fxLayoutAlign="center center">
  <h4  class="subtitle" fxLayoutAlign="center center">Os campos marcados com * são obrigatórios</h4>
</div>

<div
class="container" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="2%" fxLayoutAlign="center" ngClass.xs="mobileContainer"
>

  <div fxFlex="100%" fxFlex.gt-sm="50%" style="text-align: center;">



    <mat-vertical-stepper linear #stepper (selectionChange)="alteraEtapa($event)" >

      <mat-step [stepControl]="dadosComerciais_FormGroup" [completed]="this.dadosComerciais_FormGroup?.valid"  >

        <form [formGroup]="dadosComerciais_FormGroup" >
          <ng-template matStepLabel> <mat-icon style="vertical-align: bottom;" >apartment</mat-icon> - Dados Comerciais</ng-template>

          <h3 style="margin-bottom: 0px; font-weight: 500;">Dados Comerciais</h3>
          <mat-divider style="border-top-width: 3px; border-top-style: solid; background-color: #ffae1f; border-top-color: rgb(255 255 255 / 12%);"></mat-divider>
          <p>
          <p>


          <div class="input-row" >

            <mat-form-field appearance="legacy" fxFlex="50%" >
              <mat-label>CNPJ</mat-label>
              <input matInput   placeholder="Digite seu Cnpj" formControlName="cnpj_DadosComerciais"   mask="00.000.000/0000-00" required (change)="signInWithEmail('','')"  inputmode="numeric"  >
              <mat-error >
                CNPJ é <strong>Obrigatório</strong>
              </mat-error>
              <div [hidden]="!loadingPesquisa" >
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate"
                  value="50"
                  diameter="20">
                </mat-progress-spinner>
              </div>
            </mat-form-field>

            <mat-form-field fxFlex="50%" >
              <mat-label>Inscrição Estadual</mat-label>
              <input matInput placeholder="Inscrição Estadual" formControlName="ie_DadosComerciais" required oninput="this.value = this.value.toUpperCase()"  maxlength="18" >
              <mat-error >
                Inscrição Estadual é <strong>Obrigatório</strong>
              </mat-error>
            </mat-form-field>

          </div>

          <div class="input-row">
            <mat-form-field fxFlex>
              <mat-label>Razão Social</mat-label>
              <input matInput placeholder="Razão Social" formControlName="nome_RazaoSocial_DadosComerciais"  oninput="this.value = this.value.toUpperCase()" (change)="onChangeNomeEmp()" required  maxlength="40" >
              <mat-error >
                Razão Social é <strong>Obrigatório</strong>
              </mat-error>
              <div [hidden]="!loadingPesquisa" >
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate"
                  value="50"
                  diameter="20">
                </mat-progress-spinner>
              </div>
            </mat-form-field>
          </div>

          <div class="input-row">
            <mat-form-field fxFlex>
              <mat-label>Nome Fantasia</mat-label>
              <input matInput placeholder="Nome Fantasia" formControlName="nome_NomeFantasia_DadosComerciais"  oninput="this.value = this.value.toUpperCase()" (change)="onChangeNomeEmp()" required  maxlength="20" >
              <mat-error >
                Nome Fantasia é <strong>Obrigatório</strong>
              </mat-error>
              <div [hidden]="!loadingPesquisa" >
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate"
                  value="50"
                  diameter="20">
                </mat-progress-spinner>
              </div>
            </mat-form-field>
          </div>

          <div class="input-row">
            <mat-form-field fxFlex>
              <mat-label>CEP</mat-label>
              <input matInput placeholder="CEP" formControlName="cep_DadosComerciais" (change)="onChangeCEP()" mask="00000-000" required inputmode="numeric" >
              <div [hidden]="!loadingBuscaCEP" >
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate"
                  value="50"
                  diameter="20">
                </mat-progress-spinner>
              </div>
              <mat-error >
                CEP é <strong>Obrigatório</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="input-row">
            <mat-form-field fxFlex>
              <mat-label>Endereço</mat-label>
              <input matInput placeholder="Endereço" formControlName="endereco_DadosComerciais" required   oninput="this.value = this.value.toUpperCase()" maxlength="30" >
              <mat-error >
                Endereço é <strong>Obrigatório</strong>
              </mat-error>
              <div [hidden]="!loadingPesquisa" >
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate"
                  value="50"
                  diameter="20">
                </mat-progress-spinner>
              </div>
            </mat-form-field>
          </div>

          <div class="input-row">
            <mat-form-field fxFlex>
              <mat-label>Bairro</mat-label>
              <input matInput placeholder="Bairro" formControlName="bairro_DadosComerciais"  oninput="this.value = this.value.toUpperCase()" required maxlength="20" >
              <mat-error >
                Bairro é <strong>Obrigatório</strong>
              </mat-error>
              <div [hidden]="!loadingPesquisa" >
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate"
                  value="50"
                  diameter="20">
                </mat-progress-spinner>
              </div>
            </mat-form-field>
          </div>


          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

            <mat-form-field fxFlex="50%">

              <mat-label>Estado</mat-label>
              <mat-select  formControlName="UF_DadosComerciais"  (selectionChange)="onChangeEstado( undefined )" required>
                <mat-option>--</mat-option>
                <mat-option *ngFor="let estados of ufEstados" [value]="estados.sigla">
                  {{estados.nome}}
                </mat-option>
              </mat-select>
              <mat-error >
                Estado é <strong>Obrigatório</strong>
              </mat-error>

            </mat-form-field>

            <mat-form-field fxFlex="50%">

              <input type="text" placeholder="Cidade" aria-label="Cidade" matInput  formControlName="cidade_DadosComerciais" [matAutocomplete]="autoCidades" oninput="this.value = this.value.toUpperCase()" required>
              <mat-autocomplete #autoCidades="matAutocomplete">
                <mat-option *ngFor="let cidades of filteredCidades | async" [value]="cidades">
                  <span [innerHTML]="cidades"></span>
                  <span></span>

                </mat-option>
              </mat-autocomplete>
              <div [hidden]="!loadingCidades" >
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate"
                  value="50"
                  diameter="20">
                </mat-progress-spinner>
              </div>
              <mat-error >
                Valor <strong>'{{dadosComerciais_FormGroup?.controls?.cidade_DadosComerciais?.value}}'</strong> não esta correto.
              </mat-error>
              <mat-error >
                Cidade é <strong>Obrigatório</strong>
              </mat-error>

            </mat-form-field>

          </div>

          <br>
          <h3 style="margin-bottom: 0px; font-weight: 500;">Contatos</h3>
          <mat-divider style="border-top-width: 3px; border-top-style: solid; background-color: #ffae1f; border-top-color: rgb(255 255 255 / 12%);"></mat-divider>
          <br>

          <div class="input-row">
            <mat-form-field fxFlex>
              <mat-label>E-mail</mat-label>
              <input matInput  placeholder="E-mail" formControlName="email_DadosComerciais" required >
              <mat-error >
                E-mail é <strong>Obrigatório</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

            <mat-form-field fxFlex="80%">

              <mat-label>Pais</mat-label>
              <mat-select  formControlName="pais_DadosComerciais"  (selectionChange)="onChangePais('dadosComerciais_FormGroup'  , 'pais_DadosComerciais' , 'DDI_DadosComerciais')" required>
                <mat-option>--</mat-option>
                <mat-option *ngFor="let pais of paises" [value]="pais.DDI">
                  {{pais.name}}
                </mat-option>
              </mat-select>
              <mat-error >
                Pais é <strong>Obrigatório</strong>
              </mat-error>

            </mat-form-field>

            <mat-form-field fxFlex="20%" >
              <mat-label>DDI</mat-label>
              <input matInput placeholder="DDI" formControlName="DDI_DadosComerciais" required inputmode="numeric"  mask="999" >
              <mat-error >
                DDI é <strong>Obrigatório</strong>
              </mat-error>
            </mat-form-field>

          </div>

          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

            <mat-form-field fxFlex="20%" >
              <mat-label>DDD</mat-label>
              <input matInput placeholder="DDD" formControlName="DDD_DadosComerciais" required inputmode="numeric"  mask="999" >
              <mat-error >
                DDD é <strong>Obrigatório</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="80%" >
              <mat-label>Telefone</mat-label>
              <input matInput placeholder="Telefone" formControlName="telefone_DadosComerciais"  mask="99999-9999" required>
              <mat-error >
                Telefone é <strong>Obrigatório</strong>
              </mat-error>
            </mat-form-field>

          </div>

          <br>
          <br>
          <br>
          <br>

          <div class="input-row" >
            <button class="buttonForms" mat-button matStepperNext >Próximo<mat-icon>keyboard_arrow_right</mat-icon></button>
          </div>

        </form>
      </mat-step>

      <mat-step [stepControl]="dadosAcesso_FormGroup"   [completed]="this.dadosAcesso_FormGroup?.valid" > <!--  [completed]="this.dadosAcesso_FormGroup.valid"-->
        <form [formGroup]="dadosAcesso_FormGroup"  >

          <ng-template matStepLabel> <mat-icon style="vertical-align: bottom;" >login</mat-icon> - Dados de Acesso</ng-template>

          <br>
          <h3 style="margin-bottom: 0px; font-weight: 500;">Crie sua Senha </h3>
          <mat-divider style="border-top-width: 3px; border-top-style: solid; background-color: #ffae1f; border-top-color: rgb(255 255 255 / 12%);"></mat-divider>
          <br>

          <br>
          <h3 style="font-weight: 500;">Nesta etapa vamos criar um login e senha para você acessar a plataforma.</h3>

          <br>
          <br>

          <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
            <mat-form-field  fxFlex="50%" >
              <mat-label>Digite uma Senha</mat-label>
              <input matInput [type]="hidePss ? 'password' : 'text'" formControlName="passoword_DadosAcesso"  required >
              <button mat-icon-button matSuffix (click)="hidePss = !hidePss" [attr.aria-label]="'Esconder'" [attr.aria-pressed]="hidePss"  >
              <mat-icon>{{hidePss ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error  *ngIf="dadosAcesso_FormGroup?.hasError('required', 'password' )" >
                Senha é <strong>Obrigatório</strong>
                e deve ser iguais!
              </mat-error>
            </mat-form-field>
          </div>

          <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
            <mat-form-field  fxFlex="50%" >
              <mat-label>Digite novamente a Senha</mat-label>
              <input matInput [type]="hidePss ? 'password' : 'text'" formControlName="confirm_passoword_DadosAcesso" [errorStateMatcher]="matcher" >
              <button mat-icon-button matSuffix (click)="hidePss = !hidePss" [attr.aria-label]="'Esconder'" [attr.aria-pressed]="hidePss"  >
              <mat-icon>{{hidePss ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="dadosAcesso_FormGroup?.hasError('notSame')" >
                Senhas devem<strong> ser iguais!</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="input-row" >
            <br>
            <br>
            <button  class="buttonForms" mat-button matStepperPrevious><mat-icon>keyboard_arrow_left</mat-icon>Voltar</button>
            <button  class="buttonForms" mat-button matStepperNext >Próximo<mat-icon>keyboard_arrow_right</mat-icon></button>
          </div>

        </form>
      </mat-step>
      <mat-step [stepControl]="aceitePoliticaUso_FormGroup"   [completed]="this.aceitePoliticaUso_FormGroup?.valid" > <!--  [completed]="this.dadosAcesso_FormGroup.valid"-->
        <form [formGroup]="aceitePoliticaUso_FormGroup"  >

          <ng-template matStepLabel> <mat-icon style="vertical-align: bottom;" >check</mat-icon> - Finalizar Cadastro</ng-template>
          <br>
          <mat-card >

            <h3 style="font-weight: 600;">Cadastro preenchido corretamente!</h3>
            <h3 style="font-weight: 500;">Antes de finalizar, conta pra gente como conheceu a Bialog.</h3>
            <br>
            <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
              <mat-form-field fxFlex="70%">
                <mat-label>Selecione uma opção:</mat-label>
                <mat-select  formControlName="origemCadastro"  required>
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let origs of origemCads" [value]="origs.nome">
                    {{origs.nome}}
                  </mat-option>
                </mat-select>
                <mat-error >
                  Estado é <strong>Obrigatório</strong>
                </mat-error>

              </mat-form-field>
            </div>
            <br>
            <h3 style="font-weight: 500;">Para confirmar o cadastro, aceite termos e clique em Finalizar.</h3>

            <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
              <button (click)="abrirTermos()"  style="text-decoration: underline; color: #2b74e2;" mat-button>Clique aqui para acessar os termos e política de uso.</button>
            </div>
            <br>
            <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
                <mat-checkbox fxFlex color="primary"  formControlName="aceitePoliticaUso"  (change)="marcaPoliticaUso($event )" style="font-weight: bold;"  >Declaro que li e aceito os termos e política de uso.</mat-checkbox>
            </div>
            <br>
            <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
                <mat-checkbox fxFlex color="primary"  formControlName="aceiteNotificacaoMensagem" (change)="marcaAceiteNotificacao($event )"  style="font-weight: bold;"  >Aceito receber notificações via mensagem.</mat-checkbox>
            </div>
          </mat-card>
          <br>
          <br>
          <div class="input-row" >

            <button  class="buttonForms" mat-button matStepperPrevious><mat-icon>keyboard_arrow_left</mat-icon>Voltar</button>
            <!-- <button  fxLayoutGap.lt-md="0px"  fxFlex.lt-md="50%"  fxFlex.lt-md class="buttonForms" mat-button matStepperNext (click)="stepper.reset()" >Resetar Cadastro<mat-icon>clear</mat-icon></button> -->
            <button  class="buttonForms buttonDisabled" mat-button  (click)="finalizarCadastro()"  [disabled]="aceitePoliticaUso_FormGroup?.controls?.aceitePoliticaUso?.value !== true || aceitePoliticaUso_FormGroup?.controls?.aceiteNotificacaoMensagem?.value !== true "  >Finalizar<mat-icon>check</mat-icon></button>

          </div>
        </form>
      </mat-step>
    </mat-vertical-stepper>



  </div>

</div>


<div class="loading-indicator" [hidden]='isloading'>
  <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
</div>

</body>
</html>

