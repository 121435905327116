import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../helpers/config.service';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  private headers = { };

  constructor( private http : HttpClient , private configService : ConfigService ) {}


  public getMunicipios( Uf: any ) {

    const bodyDados = {
      "URL_WSDL": this.configService.URL_WSDL_FLUIG  ,
      "body" : {
        "companyId": "1",
        "name": "getMunicipio_CC2",
        "fields": "",
        "constraints": {
          "item" : [
            {
              "contraintType" : "MUST",
              "fieldName" : "CC2_EST",
              "initialValue" : Uf,
              "finalValue" :  Uf ,
              "likeSearch" : ""
            }

          ]
        },
        "order": ""
      }
    }

    return this.http.post( this.configService.URL_API_NODE_GOOGLEAPP +`/getdataset/` ,  bodyDados ,  { headers: this.headers, params: {} });

  }

  public getPaises() {

    const bodyDados = {
      "URL_WSDL": this.configService.URL_WSDL_FLUIG  ,
      "body" : {
        "companyId": "1",
        "name": "getPaises",
        "fields": "",
        "constraints": {
          "item" : []
        },
        "order": ""
      }
    }

    return this.http.post( this.configService.URL_API_NODE_GOOGLEAPP +`/getdataset/` ,  bodyDados ,  { headers: this.headers, params: {} });

  }

  public getConsultCPFCNPJ( cfpCnjp: any ) {

    const bodyDados = {
      "URL_WSDL": this.configService.URL_WSDL_FLUIG  ,
      "body" : {
        "companyId": "1",
        "name": "getCliente_SA1",
        "fields": "",
        "constraints": {
          "item" : [
            {
              "contraintType" : "MUST",
              "fieldName" : "A1_CGC",
              "initialValue" : cfpCnjp ,
              "finalValue" : cfpCnjp ,
              "likeSearch" : ""
            }, {
              "contraintType" : "MUST",
              "fieldName" : "BLOQUEADOS",
              "initialValue" : "SIM" ,
              "finalValue" : "SIM" ,
              "likeSearch" : ""
            }

          ]
        },
        "order": ""
      }
    }

    
    return this.http.post(  this.configService.URL_API_NODE_GOOGLEAPP +`/getdataset/`  ,  bodyDados ,  { headers: this.headers, params: {} });
  }


  public getCpfReceitaWS(cpfCnpj: string, dataNascimento: string , tipoApi: string ){

    let urlRecietaWS : any;

    if( tipoApi === 'RF' ){
      urlRecietaWS = `https://www.sintegraws.com.br/api/v1/execute-api.php?token=`+  this.configService.TOKEN_sintegraws +`&cnpj=` + cpfCnpj  +`&plugin=`+tipoApi+``
    }else if(tipoApi === 'CPF' ){
      urlRecietaWS = `https://www.sintegraws.com.br/api/v1/execute-api.php?token=`+  this.configService.TOKEN_sintegraws +`&cpf=` + cpfCnpj + `&data-nascimento=`+ dataNascimento +`&plugin=`+tipoApi+``
    }

    return this.http.get(  urlRecietaWS  ,  { headers: this.headers, params: {} });

  }

  public getCEP(cep: string){
    cep =  cep.replace(/[^\d]+/g,'')
    let URLcep = `https://viacep.com.br/ws/`+cep+`/json/`
    return this.http.get(  URLcep  ,  { headers: this.headers, params: {} });
  }

  public getAPIProtheus( endPoint: string , metodo: string , body: any ) {

    if( typeof body !== 'string' ){
      body = JSON.stringify(body)
    }

    const bodyDados = {
      "URL_WSDL": this.configService.URL_WSDL_FLUIG  ,
      "body" : {
        "companyId": "1",
        "name": "getAPIProtheusSite",
        "fields": "",
        "constraints": {
          "item" : [  {
                  "contraintType" : "MUST",
                  "fieldName" : "metodoAPI",
                  "initialValue" : metodo ,
                  "finalValue" : metodo ,
                  "likeSearch" : ""
                },{
                  "contraintType" : "MUST",
                  "fieldName" : "endPointAPI",
                  "initialValue" : endPoint ,
                  "finalValue" : endPoint ,
                  "likeSearch" : ""
                },{
                  "contraintType" : "MUST",
                  "fieldName" : "bodyAPI",
                  "initialValue" : body ,
                  "finalValue" :  body ,
                  "likeSearch" : ""
                }]
        },
        "order": ""
      }
    }

    return this.http.post(  this.configService.URL_API_NODE_GOOGLEAPP +`/getdataset/`  ,  bodyDados ,  { headers: this.headers, params: {} });
  }

  public getDSFaltaCreditoSintegraWS() {

    const bodyDados = {
      "URL_WSDL": this.configService.URL_WSDL_FLUIG  ,
      "body" : {
        "companyId": "1",
        "name": "DS_EnviaNotificacoes_FaltaCredito_Sintegraws",
        "fields": "",
        "constraints": {
          "item" : [ ]
        },
        "order": ""
      }
    }

    return this.http.post(  this.configService.URL_API_NODE_GOOGLEAPP +`/getdataset/`  ,  bodyDados ,  { headers: this.headers, params: {} });
  }

  public getEstados() {

    return [{
      "id": "1",
      "sigla": "AC",
      "nome": "Acre"
    },
         {
      "id": "2",
      "sigla": "AL",
      "nome": "Alagoas"
    },
         {
      "id": "3",
      "sigla": "AM",
      "nome": "Amazonas"
    },
         {
      "id": "4",
      "sigla": "AP",
      "nome": "Amapá"
    },
         {
      "id": "5",
      "sigla": "BA",
      "nome": "Bahia"
    },
         {
      "id": "6",
      "sigla": "CE",
      "nome": "Ceará"
    },
         {
      "id": "7",
      "sigla": "DF",
      "nome": "Distrito Federal"
    },
         {
      "id": "8",
      "sigla": "ES",
      "nome": "Espírito Santo"
    },
         {
      "id": "9",
      "sigla": "GO",
      "nome": "Goiás"
    },
         {
      "id": "10",
      "sigla": "MA",
      "nome": "Maranhão"
    },
         {
      "id": "11",
      "sigla": "MG",
      "nome": "Minas Gerais"
    },
         {
      "id": "12",
      "sigla": "MS",
      "nome": "Mato Grosso do Sul"
    },
         {
      "id": "13",
      "sigla": "MT",
      "nome": "Mato Grosso"
    },
         {
      "id": "14",
      "sigla": "PA",
      "nome": "Pará"
    },
         {
      "id": "15",
      "sigla": "PB",
      "nome": "Paraíba"
    },
         {
      "id": "16",
      "sigla": "PE",
      "nome": "Pernambuco"
    },
         {
      "id": "17",
      "sigla": "PI",
      "nome": "Piauí"
    },
         {
      "id": "18",
      "sigla": "PR",
      "nome": "Paraná"
    },
         {
      "id": "19",
      "sigla": "RJ",
      "nome": "Rio de Janeiro"
    },
         {
      "id": "20",
      "sigla": "RN",
      "nome": "Rio Grande do Norte"
    },
         {
      "id": "21",
      "sigla": "RO",
      "nome": "Rondônia"
    },
         {
      "id": "22",
      "sigla": "RR",
      "nome": "Roraima"
    },
         {
      "id": "23",
      "sigla": "RS",
      "nome": "Rio Grande do Sul"
    },
         {
      "id": "24",
      "sigla": "SC",
      "nome": "Santa Catarina"
    },
         {
      "id": "25",
      "sigla": "SE",
      "nome": "Sergipe"
    },
         {
      "id": "26",
      "sigla": "SP",
      "nome": "São Paulo"
    },
         {
      "id": "27",
      "sigla": "TO",
      "nome": "Tocantins"
    }]

  }

  public getOrigemCad() {

    return [{
      "id": "1",
      "nome": "Contato da Bialog"
    },
         {
      "id": "11",
      "nome": "Consultor da Bialog"
    },
         {
      "id": "12",
      "nome": "Indicação de Cliente"
    },
         {
      "id": "13",
      "nome": "Internet"
    },
         {
      "id": "2",
      "nome": "Facebook"
    },
         {
      "id": "3",
      "nome": "Google"
    },
         {
      "id": "4",
      "nome": "Indicação de Amigo"
    },
         {
      "id": "5",
      "nome": "Instagram"
    },
         {
      "id": "6",
      "nome": "Linkedin"
    },
         {
      "id": "7",
      "nome": "Nenhuma das opções"
    },
         {
      "id": "8",
      "nome": "Playstore"
    },
         {
      "id": "9",
      "nome": "SMS"
    },
         {
      "id": "10",
      "nome": "WhatsApp"
    }]

  }

}
