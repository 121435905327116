import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';

import { Inject, Injectable } from '@angular/core';

//import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import temporario from '../models/temporario';


//var portalBialog : any

declare global {
  interface Window {
    portalBialog:any;
  }
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  //private environment ;
  private headers = { };
  //private objRetAmb ;

  public URL_API_NODE_GOOGLEAPP: any;
  public URL_WSDL_FLUIG : any;
  public URL_WS_PROTHEUS : any;
  public TOKEN_sintegraws : any;
  public URL_API_BACKEND : any;

  public isApp

  public dbPath = environment.firebase_realtime;

  nofirebaseRef: AngularFireList<temporario> ;

  constructor( @Inject(DOCUMENT) private document: any  , private firebaseRealtime: AngularFireDatabase, ) {
    console.log("******* Iniciando - Constructor Config Service  ******* ")
    console.log("Environment service: " , environment.firebase_realtime)
    this.forceinitvar()
    this.isApp = this.isAppCall();
    this.nofirebaseRef = firebaseRealtime.list(this.dbPath);
   }

   async forceinitvar(){


    this.TOKEN_sintegraws  = environment.TOKEN_sintegraws
    this.URL_API_NODE_GOOGLEAPP = environment.URL_API_NODE_GOOGLEAPP
    this.URL_WSDL_FLUIG = environment.URL_WSDL_FLUIG
    this.URL_WS_PROTHEUS = environment.URL_WS_PROTHEUS
    this.URL_API_BACKEND = environment.URL_API_BACKEND
   }

   /*
  salveformsfirebase( typeForms: any , cpforcnpj: any  , objForms: any  ){
    let url = `https://bialog-cadastros.firebaseio.com/`+  environment.firebase_realtime + `/` + typeForms + `/` + cpforcnpj + `.json`
    return this.http.put(   url  ,  objForms ,  { headers: this.headers, params: {} });
  }

  getformsfirebase( typeForms: any  , cpforcnpj: any  ){
    let url = `https://bialog-cadastro-seguro.firebaseio.com/`+  environment.firebase_realtime + `/` + typeForms + `/` + cpforcnpj + `.json`
    return this.http.get(   url  ,   { headers: this.headers, params: {} });
  }

  deleteformsfirebase( typeForms: any  , cpforcnpj: any  ){
    let url = `https://bialog-cadastros.firebaseio.com/`+  environment.firebase_realtime + `/` + typeForms + `/` + cpforcnpj + `.json`
    return this.http.delete(   url  ,   { headers: this.headers, params: {} });
  }
  */

  public isAppCall() {
    try {
        window.portalBialog.funcaoVerificaInternet('');
        return true;
    }catch(err) {
      return false;
    }
  }

  //Funcoes nativas do firebase para alteração no realtime
  //Substitui as chamadas via HTTP REQUEST acima.
  getAll(): AngularFireList<temporario> {
    return this.nofirebaseRef;
  }

  getUnico(): any {

    return this.firebaseRealtime.list(this.dbPath)
  }

  //create(tutorial2): any {
  //  return this.nofirebaseRef.push(tutorial2);
  //}

  update(key: string, value: any): Promise<void> {
    return this.nofirebaseRef.update(key, value);
  }

  delete(key: string): Promise<void> {
    return this.nofirebaseRef.remove(key);
  }

  getURL(): any{
    return this.firebaseRealtime.list(environment.firebase_realtime+'/URLS')
  }

}

