import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinalComponent } from './final/final.component';
import { HomeComponent } from './home/home.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { CaminhoneiroComponent } from './caminhoneiro/caminhoneiro.component';

const routes: Routes = [
  { path : "inicio", component : HomeComponent   },
  { path : "empresa", component : EmpresaComponent},
  { path : "caminhoneiro", component : CaminhoneiroComponent},
  { path : "final", component : FinalComponent   },
  { path: '',  redirectTo: 'inicio',  pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

