<html lang="pt-br">
  <head>
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-156363140-2"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-156363140-2');
    </script>
  </head>
  <body>
<div *ngIf="isEnvTeste === true"  class="content"   >
  <mat-card-content >
    <h1 fxLayoutAlign="center center" style="color:red"> Ambiente de Testes </h1>
  </mat-card-content>
</div>


<!-- Cabeçario / Rotas -->
<div *ngIf="isApp === false" >
  <mat-toolbar class="toolbar" >

    <mat-toolbar-row fxHide.gt-sm fxLayoutAlign="center center">
      <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
        <mat-icon>menu</mat-icon>
      </button>
      <span>
        <!--<img class="logo" [src]='"/assets/imagens/logobialog.png"' fxHide.gt-sm>-->
        <a href="#" (click)="goToLink(this.linkInicial)" mat-list-item><mat-icon  style="margin-right: 10px; color: #ffae1f;" >first_page</mat-icon > Fechar</a>
      </span>
    </mat-toolbar-row>

    <mat-toolbar-row class="toolbarExtended" fxHide.lt-md >
      <span>
        <a href="#" (click)="goToHome(this.linkInicial)">
           <img class="logo" [src]='"/assets/imagens/logobialog.png"'   fxHide.lt-md>
        </a>
      </span>
      <!--
      <div fxShow="true" fxHide.lt-md  *ngFor="let menu of itensMenu" >
        <a *ngIf="menu.menuItem !== 'Login'" href="#" (click)="goToLink(menu.menuURL)" mat-button class="buttomenuNormal" >{{ menu.menuItem}}</a>
        <a *ngIf="menu.menuItem === 'Login' && this.isEnvTeste == false" href="#" (click)="goToLink(menu.menuURL)" mat-button class="buttomenuDiff" > {{ menu.menuItem}}<mat-icon style="margin-left: 10px; color: #ffff;" >login</mat-icon> </a>
        <a *ngIf="menu.menuItem === 'Login' && this.isEnvTeste == true" href="#" (click)="goToLink(this.linkLoginTst)" mat-button class="buttomenuDiff" > {{ menu.menuItem}}<mat-icon style="margin-left: 10px; color: #ffff;" >login</mat-icon> </a>
      </div>-->
    </mat-toolbar-row>

  </mat-toolbar>

  <mat-sidenav-container fxFlexFill  class="container" >


    <mat-sidenav #sidenav
    class="sidenav"
    fixedInViewport="true"
    >
      <mat-nav-list >
        <!--<img class="logo" [src]='"/assets/imagens/logobialog.png"' fxHide.gt-sm>-->
        <a (click)="sidenav.toggle()" mat-list-item><mat-icon  style="margin-right: 10px; color: #ffae1f;" >first_page</mat-icon > Fechar</a>

        <!--<a href="#" (click)="goToLink(menu.menuURL)"  mat-list-item *ngFor="let menu of itensMenu" ><mat-icon style="margin-right: 10px; color: #ffae1f;" >{{menu.icon}}</mat-icon> {{ menu.menuItem}}</a> -->

      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content fxFlexFill style="background-color: white;">
      <div class="content" >

        <!-- Rotas : Home / empresa / caminhoeiro / transportadora -->
        <router-outlet></router-outlet>

     </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <!--Footer-->
  <mat-toolbar class="rodape">
    <p>© Bialog. Todos os direitos reservados.</p>
  </mat-toolbar>


</div>


<!-- *** Logica para chamada dentro de APP *** -->
<div *ngIf="isApp === true"  class="content"  >
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 10px; margin-bottom: 30px">
      <a routerLink='/'>
        <img class="logo" [src]='"/assets/imagens/logobialog.png"' >
      </a>
    </div>

    <!-- Rotas : Home / empresa / caminhoeiro / transportadora -->
    <router-outlet></router-outlet>
  </mat-card-content >
</div>

</body>
</html>


