<html lang="pt-br">
  <head>

    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-156363140-2"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-156363140-2');
    </script>
  </head>
  <body>
<br>
<br>
<div fxLayout="row" fxLayoutAlign="center center">
  <h1 class="titleHome" fxLayoutAlign="center center">Você concluiu seu cadastro!</h1>
</div>

<div
  fxLayout="column"
  fxLayout.gt-sm="row"
  fxLayoutAlign="start center"
  fxLayoutAlign.gt-sm="center start"
>
<!--
  <div fxFlex="50%" fxFlex.gt-sm="30%" style="text-align: center;">
    <h1 class="subtitle">Como Empresa</h1>
    <h3>Encontre o motorista ideal para transportar sua carga.</h3>
    <button routerLink="/empresa"  class="butcadastr" mat-raised-button>Cadastre-se <mat-icon class="buticon" >emoji_transportation_outlined</mat-icon></button>
  </div>-->
  <div class="linha-vertical" fxHide.lt-md></div>
  <div class="linha-horizontal" fxHide.gt-sm></div>
  <div fxFlex="50%" fxFlex.gt-sm="100%" style="text-align: center;">
    <h1 class="subtitle">Muito Obrigado !</h1>
    <h2>O seu formulário já foi recebido e encaminhado para um de nossos analistas que</h2>
    <h2>entrará em contato o mais breve possível. </h2>
    <h2>Aguarde ! </h2>
  </div>
  <!--
  <div class="linha-vertical" fxHide.lt-md></div>
  <div class="linha-horizontal" fxHide.gt-sm></div>
  <div fxFlex="50%" fxFlex.gt-sm="30%" style="text-align: center;">
    <h1 class="subtitle">Como Transportadora</h1>
    <h3>Encontre a carga ideal para sua frota.</h3>
    <button routerLink="/transportadora" class="butcadastr"  mat-raised-button>Cadastre-se<mat-icon class="buticon"   >local_shipping_outlined</mat-icon></button>
  </div>-->
</div>

<!--
<div fxLayout="row" fxLayoutAlign="center center"   *ngIf="isApp === false" >
  <button  href="#" (click)="goToLink('https://fruteb.fluig.com/portal/1/wgPortalPublico')" class="butLogin" mat-raised-button>Já tem cadastro? Faça seu Login<mat-icon class="buticonLogin"  >east</mat-icon></button>
</div>

<div fxLayout="row" fxLayoutAlign="center center"  *ngIf="isApp === true" >
  <button  (click)="voltaTelaApp()" class="butLogin" mat-raised-button><mat-icon class="buticonLogin"  >arrow_back</mat-icon> Voltar para tela de Login</button>
</div>
-->
<div class="loading-indicator" [hidden]='isloading'>
  <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
</div>
</body>
</html>
