<html lang="pt-br">
  <head>
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-156363140-2"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-156363140-2');
    </script>
  </head>
  <body>
<div fxLayout="row" fxLayoutAlign="center center">
  <h1 class="titleHome" fxLayoutAlign="center center">Bem-vindo, amigo transportador!</h1>
</div>
<div fxLayout="row" fxLayoutAlign="center center">
  <h1  fxLayoutAlign="center center">Faça aqui o seu cadastro.</h1>
</div>

<div fxLayout="row" fxLayoutAlign="center center">
  <h4  fxLayoutAlign="center center">Os campos marcados com * são obrigatórios</h4>
</div>

<div
class="container" fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="2%" fxLayoutAlign="center" ngClass.xs="mobileContainer"
>

  <div fxFlex="100%" fxFlex.gt-sm="50%" style="text-align: center;">



    <mat-vertical-stepper linear #stepper (selectionChange)="alteraEtapa($event)" >

      <mat-step [stepControl]="cpf_cnpj_FormGroup"  [completed]="isCompleted_step_cpfCnjp && this.cpf_cnpj_FormGroup.valid" >

        <form [formGroup]="cpf_cnpj_FormGroup">
          <ng-template matStepLabel>  <mat-icon style="vertical-align: bottom;" >person_search</mat-icon> - Dados Pessoais e do Veículo  </ng-template>

          <div class="input-row" fxLayout="row" fxLayout.xs="column" fxLayoutGap="100px" fxLayoutGap.lt-md="0px"  mask="999" >

            <mat-form-field fxFlex="30%" >
              <mat-label>CPF / CNPJ</mat-label>
              <input matInput   placeholder="Digite seu Cpf" formControlName="cpfOrCpnj"   mask="CPF_CNPJ"  inputmode="numeric" (change)="signInWithEmail('','')" >
              <mat-error >
                CPF/CNPJ é <strong>Obrigatório</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="70%" >
              <mat-label >Nome Completo</mat-label>
            <input matInput placeholder="Razão Social" formControlName="nome_RazaoSocial"  oninput="this.value = this.value.toUpperCase()"  (change)="onChangeNomeReduzido()" required  maxlength="50" >
            <mat-error >
              Nome Completo é <strong>Obrigatório</strong>
            </mat-error>
            </mat-form-field>

          </div>

            <div class="input-row"  fxLayout="row" fxLayout.xs="column" fxLayoutGap="50px" fxLayoutGap.lt-md="0px"  mask="999" >

              <mat-form-field fxFlex="35%" >
                <mat-label>Tipo Veículo</mat-label>
                <mat-select  formControlName="tipo_DadosVeiculo" (selectionChange)="onChangeTipoVeiculo()" required>
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let tipoVeiculo of tiposVeiculos" [value]="tipoVeiculo.codigo"  >
                    {{tipoVeiculo.name}}
                  </mat-option>
                </mat-select>
                <mat-error >
                Tipo Veículo é <strong>Obrigatório</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="35%" >
                <mat-label >Placa Veiculo</mat-label>
              <input matInput placeholder="Placa Veiculo" formControlName="placa_veiculo"  oninput="this.value = this.value.toUpperCase()"   required  maxlength="7" >
              <mat-error >
                Placa do veículo é <strong>Obrigatório</strong>
              </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="30%" >
                <mat-label >ANTT</mat-label>
              <input matInput placeholder="ANTT Veiculo" formControlName="antt_veiculo"  oninput="this.value = this.value.toUpperCase()"  required  minlength="8" maxlength="14" >
              <mat-error >
                ANTT do veículo é <strong>Obrigatório</strong>
              </mat-error>
              </mat-form-field>

            </div>

            <div class="input-row" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px"  >


                <mat-form-field fxFlex="10%" >
                  <mat-label>DDD</mat-label>
                  <input matInput placeholder="DDD" formControlName="dddcontato" required inputmode="numeric" mask="999" >
                  <mat-error >
                    DDD é <strong>Obrigatório</strong>
                  </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="20%">
                  <mat-label>Telefone</mat-label>
                  <input matInput placeholder="Telefone" formControlName="telefonecontato"  mask="99999-9999" inputmode="numeric" required (change)="changeTelContato()">
                  <mat-error >
                    Telefone é <strong>Obrigatório</strong>
                  </mat-error>
                </mat-form-field>

                  <mat-form-field *ngIf="isTipoReboques1" fxFlex="22%" >
                    <mat-label >Placa do Reboque 1</mat-label>
                  <input matInput placeholder="Placa Reboque 1" formControlName="placa_reboque1"  oninput="this.value = this.value.toUpperCase()"  maxlength="7" >
                  <mat-error >
                    Placa do Reboque 1 é <strong>Obrigatório</strong>
                  </mat-error>
                  </mat-form-field>


                  <mat-form-field *ngIf="isTipoReboques2" fxFlex="22%"  >
                    <mat-label >Placa Reboque 2</mat-label>
                  <input matInput placeholder="Placa Reboque 2" formControlName="placa_reboque2"  oninput="this.value = this.value.toUpperCase()" maxlength="7" >
                  <mat-error >
                    Placa do Reboque 2 é <strong>Obrigatório</strong>
                  </mat-error>
                  </mat-form-field>

                  <mat-form-field *ngIf="isTipoReboques3" fxFlex="23%" >
                    <mat-label >Placa Reboque 3</mat-label>
                  <input matInput placeholder="Placa Reboque 3" formControlName="placa_reboque3"  oninput="this.value = this.value.toUpperCase()" maxlength="7" >
                  <mat-error >
                    Placa do Reboque 3 é <strong>Obrigatório</strong>
                  </mat-error>
                  </mat-form-field>
            </div>


          <div class="input-row">
            <br>
          </div>

          <div class="input-row">
            <br>
          </div>


          <div class="input-row"  >
            <button  class="buttonForms" mat-button matStepperNext >Próximo<mat-icon>keyboard_arrow_right</mat-icon></button>
          </div>

        </form>
      </mat-step>

      <mat-step [stepControl]="dadosAcesso_FormGroup"   [completed]="this.dadosAcesso_FormGroup.valid" > <!--  [completed]="this.dadosAcesso_FormGroup.valid"-->
        <form [formGroup]="dadosAcesso_FormGroup"  >

          <ng-template matStepLabel> <mat-icon style="vertical-align: bottom;" >login</mat-icon> - Dados de Acesso</ng-template>

          <br>
          <h3 style="margin-bottom: 0px; font-weight: 500;">Crie sua Senha </h3>
          <mat-divider style="border-top-width: 3px; border-top-style: solid; background-color: #ffae1f; border-top-color: rgb(255 255 255 / 12%);"></mat-divider>
          <br>

          <br>
          <h3 style="font-weight: 500;">Nesta etapa vamos criar um login e senha para você acessar a plataforma.</h3>
          <div [hidden]="cpf_cnpj_FormGroup.controls.tipo_cadastro.value !== 'proprietario'" >
            <mat-card  >
              <h5 style="font-weight: 600;"> Você marcou a opção "Estou me cadastrando como 'Proprietário' "</h5>
              <h5 style="font-weight: 600;"> A senha a seguir será a sua senha de acesso. </h5>
              <h5 style="font-weight: 600;"> Estamos enviando uma notificação para o Motorista com o login e instruções definições de senha de acesso dele. </h5>
            </mat-card>
          </div>

          <div [hidden]="cpf_cnpj_FormGroup.controls.tipo_cadastro.value !== 'proprietarioEmotorista'"  >
            <mat-card >
              <h5 style="font-weight: 600;"> Você marcou a opção "Estou me cadastrando como 'Motorista Proprietário do Veículo' "</h5>
              <h5 style="font-weight: 600;"> A senha a seguir será a sua senha para acesso ao seu perfil de Motorista Proprietário.  </h5>
              <br>
              <h2 style="font-weight: 600;"> Usuário: {{ cpf_cnpj_FormGroup.controls.cpfOrCpnj.value}} </h2>
            </mat-card>
          </div>

          <div [hidden]="cpf_cnpj_FormGroup.controls.tipo_cadastro.value !== 'motorista'"  >
            <mat-card  >
              <h5 style="font-weight: 600;"> Você marcou a opção "Estou me cadastrando como 'Motorista Não Proprietário do Veículo' "</h5>
              <h5 style="font-weight: 600;"> A senha a seguir será a sua senha para acesso ao seu perfil de Motorista. </h5>
              <!--<h5 style="font-weight: 600;"> Estamos enviando uma notificação para o Proprietário com o login e instruções definições de senha de acesso dele. </h5>-->

              <br>
              <h2 style="font-weight: 600;"> Usuário: {{ cpf_cnpj_FormGroup.controls.cpfOrCpnj.value }} </h2>
            </mat-card>
          </div>

          <br>
          <br>

          <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
            <mat-form-field  fxFlex="50%" >
              <mat-label>Digite uma Senha</mat-label>
              <input matInput [type]="hidePss ? 'password' : 'text'" formControlName="passoword_DadosAcesso"  required >
              <button mat-icon-button matSuffix (click)="hidePss = !hidePss" [attr.aria-label]="'Esconder'" [attr.aria-pressed]="hidePss"  >
              <mat-icon>{{hidePss ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error  *ngIf="dadosAcesso_FormGroup.hasError('required', 'password' )" >
                Senha é <strong>Obrigatório</strong>
                e deve ser iguais!
              </mat-error>
            </mat-form-field>
          </div>

          <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
            <mat-form-field  fxFlex="50%" >
              <mat-label>Digite novamente a Senha</mat-label>
              <input matInput [type]="hidePss ? 'password' : 'text'" formControlName="confirm_passoword_DadosAcesso" [errorStateMatcher]="matcher" >
              <button mat-icon-button matSuffix (click)="hidePss = !hidePss" [attr.aria-label]="'Esconder'" [attr.aria-pressed]="hidePss"  >
              <mat-icon>{{hidePss ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="dadosAcesso_FormGroup.hasError('notSame')" >
                Senhas devem<strong> ser iguais!</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="input-row" >
            <br>
            <br>
            <button  class="buttonForms" mat-button matStepperPrevious><mat-icon>keyboard_arrow_left</mat-icon>Voltar</button>
            <button  class="buttonForms" mat-button matStepperNext >Próximo<mat-icon>keyboard_arrow_right</mat-icon></button>
          </div>

        </form>
      </mat-step>
      <mat-step [stepControl]="aceitePoliticaUso_FormGroup"   [completed]="this.aceitePoliticaUso_FormGroup.valid" > <!--  [completed]="this.dadosAcesso_FormGroup.valid"-->
        <form [formGroup]="aceitePoliticaUso_FormGroup"  >

          <ng-template matStepLabel> <mat-icon style="vertical-align: bottom;" >check</mat-icon> - Finalizar Cadastro</ng-template>
          <br>
          <mat-card >

            <h3 style="font-weight: 600;">Cadastro preenchido corretamente!</h3>
            <h3 style="font-weight: 500;">Antes de finalizar, conta pra gente como conheceu a Bialog.</h3>
            <br>
            <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
              <mat-form-field fxFlex="70%">
                <mat-label>Selecione uma opção:</mat-label>
                <mat-select  formControlName="origemCadastro"  required>
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let origs of origemCads" [value]="origs.nome">
                    {{origs.nome}}
                  </mat-option>
                </mat-select>
                <mat-error >
                  Estado é <strong>Obrigatório</strong>
                </mat-error>

              </mat-form-field>
            </div>
            <br>
            <h3 style="font-weight: 500;">Para confirmar o cadastro, aceite termos e clique em Finalizar.</h3>

            <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
              <button (click)="abrirTermos()"  style="text-decoration: underline; color: #2b74e2;" mat-button>Clique aqui para acessar os termos e política de uso.</button>
            </div>
            <br>
            <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
                <mat-checkbox fxFlex color="primary"  formControlName="aceitePoliticaUso"  (change)="marcaPoliticaUso($event )" style="font-weight: bold;"  >Declaro que li e aceito os termos e política de uso.</mat-checkbox> <!-- [disabled]="this.clickTermosLink !== true"  -->
            </div>
            <br>
            <div class="input-row"  fxLayoutAlign="center"  fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" >
                <mat-checkbox fxFlex color="primary"  formControlName="aceiteNotificacaoMensagem" (change)="marcaAceiteNotificacao($event )"  style="font-weight: bold;"   >Aceito receber notificações via mensagem.</mat-checkbox> <!-- [disabled]="this.clickTermosLink !== true" -->
            </div>
          </mat-card>
          <br>
          <br>
          <div class="input-row" >

            <button  class="buttonForms" mat-button matStepperPrevious><mat-icon>keyboard_arrow_left</mat-icon>Voltar</button>
            <!-- <button  fxLayoutGap.lt-md="0px"  fxFlex.lt-md="50%"  fxFlex.lt-md class="buttonForms" mat-button matStepperNext (click)="stepper.reset()" >Resetar Cadastro<mat-icon>clear</mat-icon></button> -->
            <button  class="buttonForms buttonDisabled" mat-button  (click)="finalizarCadastro()"  [disabled]="aceitePoliticaUso_FormGroup.controls.aceitePoliticaUso.value !== true || aceitePoliticaUso_FormGroup.controls.aceiteNotificacaoMensagem.value !== true "  >Finalizar<mat-icon>check</mat-icon></button>

          </div>
        </form>
      </mat-step>
    </mat-vertical-stepper>



  </div>

</div>


<div class="loading-indicator" [hidden]='isloading'>
  <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
</div>

</body>
</html>
